import request from '../utils/request';

export default {
  //图片生成记录
  task_search: (info) => request('/admin/task/list', {
    method: 'POST',
    data: {
      ...info
    }
  }),
  //任务选项
  task_get_options: (info) => request('/admin/task/get_options'),
}