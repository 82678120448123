import React, {useState} from "react";
import {useSelector,useDispatch} from 'react-redux';
import {Layout, Menu, Icon, Badge} from 'antd';
import {Link, withRouter} from "react-router-dom";
import {setCurrentMenu} from '../../store/actions/login'
import menuConfigs from '../../menu-configs'
import './index.less';

const {Sider} = Layout;
const {SubMenu} = Menu;

const SideMenu = ({collapsed, history}) => {

  const [openKeys, setOpenKeys] = useState(['运营管理']);
  const { userMenusList, menuIdMapBadgeNum } = useSelector(state => ({
    userMenusList: state.getIn(['admin', 'userMenusList'], []),
    menuIdMapBadgeNum: state.getIn(['admin', 'menuIdMapBadgeNum'], [])
  }))
  const rootSubmenuKeys = ['运营管理','财务管理','客户管理', '成员管理', '模特、场景管理', '影镜工厂'];
  const dispatch = useDispatch();


  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onRouteClick = (menuConfig) => {
    dispatch(setCurrentMenu(menuConfig.name))
  };

  const generateMenus = (menus = []) => {
    if (!Array.isArray(menus)) {
      return null;
    }
    return menus?.map(menuItem => {
      const menuConfig = menuConfigs[menuItem.id];

      if (!menuConfig) {
        return
      }

      if (Array.isArray(menuItem.child_menu) && menuItem.child_menu.length > 0) {
        return (
          <SubMenu key={menuConfig.name} title={(
            <span>
              <Icon type={menuConfig.icon}/>
              <span>{menuConfig.name}</span>
            </span>
          )}>
            {generateMenus(menuItem.child_menu)}
          </SubMenu>
        )
      } else {
        return (
          <Menu.Item key={menuConfig.to}>
            <Link to={menuConfig.to} onClick={() => onRouteClick(menuConfig)}>
              <span>
                {menuConfig.name}
                <Badge
                    count={menuIdMapBadgeNum[menuConfig.menuId]}
                    offset={[2, -20]}
                    showZero={false}
                />
              </span>
            </Link>
          </Menu.Item>
        )
      }
    });
  };
  const defaultProps = collapsed ? {} : { openKeys };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      {
        collapsed
          ? <img src={require('./logo.png')} alt="logo" className="min-logo"/>
          : <div className="logo-text">MixModel</div>
      }
      <Menu theme="dark"
            mode="inline"
            defaultSelectedKeys={['/home/operate/statistics']}
            defaultOpenKeys ={openKeys}
            selectedKeys={[history.location.pathname]}
            //openKeys={openKeys}
            onOpenChange={onOpenChange}
            {...defaultProps}
      >
        {
          generateMenus(userMenusList)
        }
      </Menu>
    </Sider>
  )
};

export default withRouter(SideMenu);
