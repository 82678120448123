import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {Tabs } from 'antd';
import './index.less';

import ResetPassword from './resetPassword';
import PersonalCenter from './personalCenter';


const { TabPane } = Tabs;

class Personal extends Component {

  state = {
    activeTab: 'info',
  };

  changeTab = (key) => {
    this.setState({
      activeTab: key
    })
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type');
    this.setState({
      activeTab: type
    })
  }

  render() {
    return <div>
      <Tabs type="card" onChange={this.changeTab} activeKey={this.state.activeTab}>
        <TabPane  tab="个人信息" key="info">
          <PersonalCenter/>
        </TabPane >
        <TabPane  tab="密码修改" key="password">
          <ResetPassword/>
        </TabPane >
      </Tabs>

    </div>
  }
}

export default withRouter(Personal);
