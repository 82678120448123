import React, {Component} from 'react';
import {Table} from "antd";
import API from "../../../../apis";
import {withRouter} from "react-router-dom";
import {formatNum} from '../../../../utils/helpers';

class myRecharge extends Component {
    state = {
        columns: [
            {
                title: '订单号',
                dataIndex: 'order_no',
                key: 'order_no',
            },
            {
                title: '类型',
                dataIndex: 'package_type_name',
                key: 'package_type_name',
            },
            {
                title: '支付金额',
                dataIndex: 'money',
                key: 'money',
                render: text => <div className='red'>￥{text}</div>,
                render: (money, record) => {
                    if (record.package_type == 1) {
                        return <div className='red'>￥{money}</div>
                    }else{
                        return <div>-</div>;
                    }
                  },
            },
            {
                title: '下单时间',
                key: 'add_time',
                dataIndex: 'add_time',
                width: '20%',
                render: text => <div className='time-item'>{text}</div>,
            },
            {
                title: 'M豆套餐',
                dataIndex: 'package_coin',
                key: 'package_coin',
                width: '10%',
            },
            {
                title: '已用M豆',
                dataIndex: 'used_coin',
                key: 'used_coin',
            },
            {
                title: '剩余M豆',
                dataIndex: 'coin',
                key: 'coin',
            },
            {
                title: '到期时间',
                key: 'expire_time',
                dataIndex: 'expire_time',
                width: '20%',
                render: text => <div className='time-item'>{text}</div>,
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (status, record) => {
                    switch (status) {
                      case 0:
                        return <div style={{color: '#AAAAAA'}}>已过期</div>;
                      case 1:
                        return <div style={{color: '#70B603'}}>使用中</div>;
                    }
                  },
            },
        ],
        pagination: {
            total: 0,
            current: 1,
            pageSize: 20,
        },
        loading: false,
        rechargeInfo:{}
    };
    componentDidMount() {
        this.init();
    }
    init = async(page)=> {
        if (this.props.match.params.id) {
            const data = await API.getMyPackage({
                id: this.props.match.params.id,
                page
            });
            if (data.code === 0) {
                this.setState({
                    rechargeInfo:data.data,
                    pagination: {
                        total: data.data.total,
                        current: page
                    },
                })
            }
        }
    }
    // 当选中某一页时回调
    pageChange = (page) => {
        this.init(page.current);
    };
    render() {
        const {columns, rechargeInfo, pagination, loading} = this.state;
        return <div>
            <p> 
                可用M豆 <span className='red'>{rechargeInfo.total_active_coin}</span>，
                已用M豆 <span className='red'>{rechargeInfo.total_used_coin}</span>，
                过期M豆 <span className='red'>{rechargeInfo.total_expire_coin}</span>
            </p>
            <Table
                columns={columns}
                dataSource={rechargeInfo.list}
                rowKey="id"
                pagination={pagination}
                loading={loading}
                onChange={this.pageChange}
                locale={{emptyText: '对不起，没有搜到任何内容'}}
            />
        </div>
    }
}

export default withRouter(myRecharge);
