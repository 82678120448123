import request from '../utils/request';
export default {
// 请求管理员列表
  getAdminList: (param) => request('/admin/user/list',{
    method: 'POST',
    data: {
      param
    }
  }),
  // 添加管理员
  addAdmin: (info) => request('/admin/user/add', {
    method: 'POST',
    data: {
      data: info
    }
  }),
  // 获取管理员详情
  getAdminById: (id) => request('/admin/user/info', {
    method: 'GET',
    params: {
      id
    }
  }),
  // 删除管理员
  deleteAdmin: (id) => request('/admin/user/del', {
    method: 'GET',
    params: {
      id
    }
  }),
  // 编辑管理员
  editAdmin: (info) => request('/admin/user/update', {
    method: 'POST',
    data: {
      data: info
    }
  }),
  // 禁用管理员
  banAdmin: (id) => request('/admin/user/Ban', {
    method: 'GET',
    params: {
      id
    }
  }),
  // 启用管理员
  cancelBanAdmin: (id) => request('/admin/user/cancelBan', {
    method: 'GET',
    params: {
      id
    }
  }),
  // 请求角色
  getRoleList: () => request('/admin/role/list'),
  // 获取角色详情
  getRoleInfo: (id) => request('/admin/role/info', {
    method: 'GET',
    params: {
      id
    }
  }),
  // 添加角色
  addRole: (info) => request('/admin/role/add', {
    method: 'POST',
    data: {
      data: info
    }
  }),
  // 删除角色
  deleteRole: (id) => request('/admin/role/del', {
    method: 'GET',
    params: {
      id
    }
  }),
  // 编辑角色
  editRole: (info) => request('/admin/role/update', {
    method: 'POST',
    data: {
      data: info
    }
  }),
  //请求个人中心信息
  getPersonal: () => request('/admin/user/login-info'),
  // 更新个人中心信息
  updatePersonal: (info) => request('/admin/user/login-update', {
    method: 'POST',
    data: {
      data: info
    }
  }),
  // 更新密码
  resetPassword: (info) => request('/admin/user/reset-password', {
    method: 'POST',
    data: {
      data: info
    }
  }),
  //获取所有菜单
  getAllMenus: () => request('/admin/user/all-menu'),
  //获取当前菜单
  getUserMenus: () => request('/admin/user/menu'),
  // 获取菜单提示数字
  getMenuItemBadge: data => request('/admin/user/menu-badge', { method: 'POST', data }),
  //保存授权信息
  saveAuth: (data) => request('/admin/role/update-auth', {
    method: 'POST',
    data,
  }),
  // 获取权限
  getAuth: (id) => request('/admin/role/auth-menu', {
    method: 'GET',
    params: {
      id
    }
  }),
}
