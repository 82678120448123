import React, {Component} from 'react';
import {Button, Card, Col, DatePicker, Divider, Form, Icon, Input, message, Modal, Popover, Select, Table} from 'antd';
import API from "../../../apis";
import {connect} from "react-redux";
import {setMemberParam} from "../../../store/actions/operate";
import {setCurrentMenu} from "../../../store/actions/login";

import "./index.less"

const {Option} = Select;

/*
 * 模特-列表
 */
class ModelList extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    columns: [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        align: 'left',
        render: text => <a onClick={() => this.onModelDetail(text)}>{text}</a>,
      },
      {
        title: '头像',
        dataIndex: 'avatar',
        key: 'avatar',
        align: 'left',
        render: text => <div>
          <img className="avatar-img" src={ text } />
        </div>,
      },
      {
        title: '性别',
        dataIndex: 'gender',
        key: 'gender',
        align: 'left',
        render: text => <div className='w100-item'>{text === 1 ? '男' : '女'}</div>,
      },
      {
        title: '名字',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        render: text => <div className='w100-item'>{text ? text : '-'}</div>,
      },
      {
        title: '标签',
        dataIndex: 'tags',
        key: 'tags',
        align: 'left',
        render: text => <div className='w100-item'>{text ? text : '-'}</div>,
      },
      {
        title: '修改时间',
        dataIndex: 'update_time',
        key: 'update_time',
        align: 'left',
        render: text => <div className='time-item'>{text}</div>,
      },
      {
        title: '上架状态',
        key: 'status',
        dataIndex: 'status',
        align: 'left',
        render: (status, record) => {
          switch (status) {
            case 0:
              return <div style={{color: 'rgba(0, 0, 0, 0.45)'}}>下架</div>;
            case 1:
              return <div style={{color: '#87d068'}}>上架</div>;
          }
        },
      },
      {
        title: '操作',
        key: 'action',
        width: '10%',
        render: (record) => {
          return <div className='action-box'>
            <a onClick={() => this.onModelDetail(record.id)}>查看</a>
            {
              record.status === 0 && <a style={{color: '#87d068'}} onClick={() => this.changeStatus(record, 1)}>上架</a>
            }
            {
              record.status === 1 && <a style={{color: 'rgba(0, 0, 0, 0.45)'}} onClick={() => this.changeStatus(record, 0)}>下架</a>
            }
          </div>
        },
      }
    ],
    // 过滤条件
    filterConditions: {
      id: undefined,
      gender: undefined,
      name: undefined,
      tag: undefined,
      status: undefined,
    },

    pagination: {
      total: 0,
      current: 1,
      pageSize: 20,
    },
    initList: [],
    loading: false,
  };
  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16
    },
  };

  componentDidMount() {
    this.init();
  }

  init = async (page) => {
    this.setState({loading: true});
    const params = {
      ...this.state.filterConditions,
      page,
      page_size: this.state.pagination.pageSize
    };
    const data = await API.getModelList(params);
    if (data.code === 0) {
      this.setState({
        loading: false,
        initList: data.data.list,
        pagination: {
          ...this.state.pagination,
          total: data.data.total,
          current: page
        },
      })
    }
  }

  feach_keyword = (value, type) => {
    this.setState({
      filterConditions: {
        ...this.state.filterConditions,
        [type]: value.target.value,
      },
    })
  };

  handleChangeStatus = (value, type) => {
    this.setState({
      filterConditions: {
        ...this.state.filterConditions,
        [type]: value,
      },
    })
  };

  onSearch = () => {
    this.init(1);
  };

  onEmpty = () => {
    this.setState({
      filterConditions: {
        id: undefined,
        gender: undefined,
        name: undefined,
        tag: undefined,
        status: undefined,
      },
    }, () => {
      this.init(1);
    });
  };

  // 当选中某一页时回调
  pageChange = (page) => {
    this.init(page.current);
  };

  onModelDetail = (id) => {
    this.props.history.push('/home/model/model-edit/' + id);
  }

  // 上下架
  changeStatus = async (record, status) => {
    const statusName = status === 1 ? '上架' : '下架';

    Modal.confirm({
      title: `${statusName}提示`,
      content: (
        <div>
          <p>确认将模特 “{record.name}” {statusName}？</p>
        </div>
      ),
      okText: statusName,
      cancelText: '取消',
      onOk: async () => {
        const data = await API.editModelStatus({
          model_id: record.id,
          status
        });
        if (data.code === 0) {
          this.init();
          message.success(`${statusName}成功！`);
        } else {
          message.error(`${statusName}失败！`);
        }
      },
    });
  }

  render() {
    const {
      initList,
      filterConditions,
    } = this.state;
    const {getFieldDecorator} = this.props.form;

    return <div className='content-tab model-list-page'>
      <Card title="模特列表">
        <div className='serach-area'>
         <span>
            <label>ID：</label>
            <Input
                placeholder="请输入ID"
                value={filterConditions.id}
                onChange={(value) => this.feach_keyword(value, 'id')}
            />
          </span>
          <span>
            <label>性别：</label>
            <Select
              placeholder="请选择性别"
              value={filterConditions.gender}
              allowClear={true}
              onChange={(value) => this.handleChangeStatus(value, 'gender')}
            >
              <Option key={1} value={1}>男</Option>
              <Option key={2} value={2}>女</Option>
            </Select>
          </span>
          <span>
            <label>名字：</label>
            <Input
              placeholder="请输入昵称"
              value={filterConditions.name}
              onChange={(value) => this.feach_keyword(value, 'name')}
            />
          </span>
        </div>

        <div className='serach-area'>
          <span>
            <label>标签：</label>
            <Input
              placeholder="请输入标签"
              value={filterConditions.tag}
              onChange={(value) => this.feach_keyword(value, 'tag')}
            />
          </span>
          <span>
            <label>上架状态：</label>
            <Select
              placeholder="请选择上架状态"
              value={filterConditions.status}
              allowClear={true}
              onChange={(value) => this.handleChangeStatus(value, 'status')}
            >
              <Option key={1} value={1}>上架</Option>
              <Option key={0} value={0}>下架</Option>
            </Select>
          </span>
          <span className='search-btn'>
            <span>
              <Button type="primary" onClick={this.onSearch}>搜索</Button>
              <Button type="danger" onClick={this.onEmpty}>清空</Button>
            </span>
          </span>
        </div>

        <Table
            columns={this.state.columns}
            rowKey="id"
            dataSource={initList}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.pageChange}
            locale={{emptyText: '对不起，没有搜到任何内容'}}
        />
      </Card>
    </div>
  }
}

const matDispatchToProps = {
  setMemberParam, setCurrentMenu
};

function mapStateToProps(state) {
  return {
    memberParam: state.getIn(['operate', 'memberParam'], 1),
  }
}

const MemberFormWrap = Form.create({name: 'member_form'})(ModelList);
export default connect(mapStateToProps, matDispatchToProps)(MemberFormWrap);
