import request from '../utils/request';

export default {
    // 登录接口
    doLogin: ({ username, password, captcha }) => request('/admin/user/login', {
        method: 'GET',
        params: {
            username,
            password,
            captcha,
        }
    }),
    // 登出接口
    doLogout: () => request('/admin/user/logout'),

    //在线用户
    getOnlineNum: () => request('/admin/member/online'),

}
