import React, {Component} from 'react';
import {setCurrentMenu} from "../../../store/actions/login";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'

class Welcome extends Component {
    componentDidMount () {
        this.props.setCurrentMenu('欢迎页面');
    }
    render() {
        return <div className='welcome'>
           欢迎进入 MixModel 后台管理系统
        </div>
    }
};

// 将action注入到页面组件
const matDispatchToProps = {
    setCurrentMenu
};
// 将store注入到页面组件
function mapStateToProps(state) {
    return {
    }
}
export default withRouter(connect(mapStateToProps, matDispatchToProps)(Welcome));