import React, {Component} from 'react';
import {Input, Button, Row, Col, Form, Checkbox, message, Radio} from 'antd';
import {editRole, getRoleInfo} from "../../../../store/actions/admin";
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";

class roleEdit extends Component {
  handleChange = ({ fileList }) => this.setState({ fileList });
  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16
    },
  };
  onCancel = () => {
    this.props.history.goBack();
  };
  componentDidMount() {
    this.props.getRoleInfo(this.props.match.params.id);
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const payload = {
          ...values,
          id: this.props.match.params.id,
        };
        let res = await editRole(payload);
        if (res.code == 0) {
          message.success(res.msg|| '修改成功', 1, () => {
            this.props.history.replace('/home/account/role-list');
          });
        }
      }
    });
  };
  render() {
    const {getFieldDecorator} = this.props.form;
    const initInfo = this.props.roleInfo || {};
    return <div className="row-permission-setting">
      <Row type="flex" justify="space-around">
        <Col span={22}>
          <Form {...this.formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label='角色名称'>
              {getFieldDecorator('name', {
                initialValue: initInfo.name,
                rules: [{type: 'string', required: true, message: '请输入角色名称'}]
              })(
                <Input placeholder='请输入角色名称'/>
              )}
            </Form.Item>
            <Form.Item label='角色描述'>
              {getFieldDecorator('remark', {
                initialValue: initInfo.remark,
                rules: [{type: 'string'}]
              })(
                <Input placeholder='请输入角色描述'/>
              )}
            </Form.Item>
            <Form.Item label='状态'>
              {getFieldDecorator('status', {
                initialValue: initInfo.status,
              })(
                <Radio.Group>
                  <Radio value={1}>开启</Radio>
                  <Radio value={0}>禁用</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <div className='btn-con-no-flex'>
              <Button type="primary"  htmlType="submit">
                保存
              </Button>

              <Button type="normal" onClick={this.onCancel}>
                取消
              </Button>
            </div>
          </Form>
        </Col>
      </Row>

    </div>
  }
}
// 将action注入到页面组件
const matDispatchToProps = {
  getRoleInfo
};

// 将store注入到页面组件
function mapStateToProps(state) {
  return {
    roleInfo: state.getIn(['admin', 'roleInfo'], {}),
  }
}
const RoleEditFormWrap = Form.create({ name: 'role_edit_form' })(roleEdit);

export default withRouter(connect(mapStateToProps, matDispatchToProps)(RoleEditFormWrap));


