import React, {Component} from 'react';
import {Table, Card, Select, Button, Input, DatePicker} from 'antd';
import API from "../../../apis";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from 'moment';
import {connect} from "react-redux";
import {setOrderParam} from "../../../store/actions/operate";
import {setCurrentMenu} from "../../../store/actions/login";
import {formatNum} from "../../../utils/helpers";


const { RangePicker } = DatePicker;
const {Option} = Select;

class Deposit extends Component {
  constructor(props) {
    super(props);
    if (typeof props.orderParam.toJSON === 'function') {
      this.state.filterConditions = props.orderParam.toJSON();
    } else {
      this.state.filterConditions = props.orderParam;
    }
  }
  state = {
    columns: [
      {
        title: '订单号',
        dataIndex: 'order_no',
        key: 'order_no',
      },
      {
        title: '用户ID',
        dataIndex: 'member_id',
        key: 'member_id',
        width: '10%',
        render: text => <a onClick={this.onMemberDetail.bind(this, text)}>{text}</a>,
      },
      {
        title: '注册手机',
        dataIndex: 'mobile',
        key: 'mobile',
        width: '15%',
        render: text => <div className='clamp2-item'>{text}</div>,
      },
      {
        title: '支付时间',
        dataIndex: 'pay_time',
        key: 'pay_time',
        render: text => <div className='time-item'>{text}</div>,
      },
      {
        title: '支付方式',
        dataIndex: 'pay_type',
        key: 'pay_type',
      },
      {
        title: 'M豆套餐',
        dataIndex: 'package_coin',
        key: 'package_coin',
      },
      {
        title: '支付金额',
        key: 'money',
        dataIndex: 'money',
        render: text => <div className='red'>￥{text}</div>,
      },
      {
        title: 'M豆余额',
        key: 'coin',
        dataIndex: 'coin',
        width: '10%',
        render: text => <span>{formatNum(text)}</span>
      }
    ],
    // 过滤条件
    filterConditions: {
      member_id: '',
      mobile: '',
      order_no: '',
      pay_type: '',
      start_time: '',
      end_time:'',
    },
    pagination: {
      total: 0,
      current: 1,
      pageSize: 20,
    },
    initList:[],
    loading: false,
    payTypeList:[],
  };

  onMemberDetail =(id)=>{
    //this.props.setCurrentMenu('用户详情');
    this.props.history.push('/home/member/member-list/detail/' + id);
  }
  onChange=(date, dateString) =>{
    this.setState({
      filterConditions:{
        ...this.state.filterConditions,
        start_time:dateString[0],
        end_time:dateString[1],
      }
    })
  };
  feach_keyword = (value, type) => {
    this.setState({
      filterConditions: {
        ...this.state.filterConditions,
        [type]: value.target.value,
      },
    })
  };
  handleChange = (value, type) => {
    this.setState({
      filterConditions: {
        ...this.state.filterConditions,
        [type]: value,
      },
    })
  };
  onSearch = () => {
    this.init(1);
  };
  onEmpty = () => {
    this.setState({
      filterConditions: {
        member_id: '',
        mobile: '',
        order_no: '',
        pay_type: '',
        start_time: '',
        end_time:'',
      },
    }, () => {
      this.init(1);
    });
  };
  init=async(page)=> {
    this.setState({loading: true});
    const data = await API.getRecordList(
      {
        ...this.state.filterConditions,
        //优先用参数中的page，如果是初始化列表的话，用store中的page，也就是记录下来的页码
        page: page || this.props.orderParam.page
      }
    );
    if (data.code === 0) {
      this.props.setOrderParam({
        ...this.state.filterConditions,
        page: page|| this.props.orderParam.page
      });
      this.setState({
        initList:data.data.list,
        loading: false,
        total:data.data.total,
        total_order_coin:data.data.total_order_coin,
        total_order_money:data.data.total_order_money,
        pagination: {
          total: data.data.total,
          current: page || this.props.orderParam.page
        },
      })
    }
  }
  // 当选中某一页时回调
  pageChange = (page) => {
    this.init(page.current);
  };
  componentDidMount = async () => {
    this.init();
    const data = await API.getPayType();
    if (data.code === 0) {
      this.setState({
        payTypeList : data.data,
      })
    }
  }

  disabledDate(current) {
    return current && current > moment().endOf('day');
  }
  render() {
    const {initList,filterConditions, payTypeList,total,total_order_coin,total_order_money} = this.state;
    return <div className='content-tab '>
          <Card
            title="全部"
          >
            <div className='serach-area'>
                <span>
                  <label>用户ID：</label>
                  <Input placeholder="请输入用户ID"
                         value={filterConditions.member_id}
                         onChange={(value) => this.feach_keyword(value, 'member_id')}
                  />
                </span>
              <span>
                  <label>注册手机：</label>
                  <Input
                    placeholder="请输入注册手机"
                    value={filterConditions.mobile}
                    onChange={(value) => this.feach_keyword(value, 'mobile')}
                  />
                </span>
              <span>
                  <label>订单号：</label>
                  <Input
                    placeholder="请输入订单号"
                    value={filterConditions.order_no}
                    onChange={(value) => this.feach_keyword(value, 'order_no')}
                  >
                  </Input>
                </span>
            </div>
            <div className='serach-area'>
                <span>
                  <label>支付方式：</label>
                  <Select
                      placeholder="请选择支付状态"
                      value={filterConditions.pay_type}
                      onChange={(value) => this.handleChange(value, 'pay_type')}
                  >
                    <Option value="">全部</Option>
                    {payTypeList?.map(({name,value}) => (
                        <Option key='{name}' value={value}>{name}</Option>))}
                  </Select>
                </span>
                <span>
                  <label>订单日期：</label>
                   <RangePicker
                     locale={locale}
                     value={[
                       filterConditions.start_time?moment(filterConditions.start_time):null,
                       filterConditions.end_time?moment(filterConditions.end_time):null
                     ]}
                     onChange={this.onChange.bind()}
                     disabledDate={this.disabledDate}
                   />
                </span>
                <span>
                  <Button type="primary" onClick={this.onSearch}>搜索</Button>
                  <Button type="danger" onClick={this.onEmpty}>清空</Button>
                </span>
            </div>
            <p>
              订单总数： <span className='red'>{total}</span>，
              订单合计： <span className='red'>￥{total_order_money}</span>，
              共 <span className='red'>{total_order_coin} M豆</span>
            </p>
            <Table
              columns={this.state.columns}
              rowKey="id"
              dataSource={initList}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.pageChange}
              locale={{emptyText: '对不起，没有搜到任何内容'}}
            />
          </Card>
    </div>
  }
};
const matDispatchToProps = {
  setOrderParam,setCurrentMenu
};

function mapStateToProps(state) {
  return {
    orderParam: state.getIn(['operate', 'orderParam'], {}),
  }
}
export default connect(mapStateToProps, matDispatchToProps)(Deposit);