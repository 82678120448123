import React, {Component} from 'react';
import {Input, Form, Icon, message, Button, Table, Popover, Tabs, Modal, Col, Select, Card} from 'antd';
import API from '../../../apis'
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import './index.less';
import {formatNum} from '../../../utils/helpers';
import {setCurrentMenu} from "../../../store/actions/login";
import configs from "../../../configs";
import {Upload} from "antd";
import ConfigOptionsDrawer from "../../../components/config-options-drawer";
import ProgressComponent from '../../../components/progress';
import BatchSelectComponent from "../../../components/batch-select";
import DownloadHDProgressComponent from "../../../components/download-hd-progress";


const { TabPane } = Tabs;
const {Option} = Select;
const { TextArea } = Input;

let uploadImgTotal = 0;
let uploadImgList = [];
class OrderDetail extends Component {
  state = {
    // 款式数量
    STYLE_QUANTITY_LIST: [
      { name: '请选择数量', value: 0},
      { name: '1', value: 1},
      { name: '2', value: 2},
      { name: '3', value: 3},
      { name: '4', value: 4},
      { name: '5', value: 5},
      { name: '6', value: 6},
      { name: '7', value: 7},
      { name: '8', value: 8},
      { name: '9', value: 9},
      { name: '10', value: 10},
      { name: '11', value: 11},
      { name: '12', value: 12},
      { name: '13', value: 13},
      { name: '14', value: 14},
      { name: '15', value: 15},
      { name: '16', value: 16},
      { name: '17', value: 17},
      { name: '18', value: 18},
      { name: '19', value: 19},
      { name: '20', value: 20}
    ],
    columns: [
      {
        title: '款式ID',
        dataIndex: 'style_id',
        key: 'style_id',
        width: '7%',
        align: 'left',
        render: text => <div>{text}</div>,
      },
      {
        title: '款式名称（选填）',
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        align: 'left',
        render: (text, record, index) => <div className='time-item'>
          <Input
            placeholder='限制20个字符'
            defaultValue={text}
            onBlur={(e) => this.onTableCellBlur(record)}
            onChange={(e) => this.onTableCellChange('name', e.target.value, record, index)}
          />
        </div>,
      },
      {
        title: '商家款式 ID（选填）',
        dataIndex: 'sn',
        key: 'sn',
        width: '8%',
        align: 'left',
        render: (text, record, index) => <div className='w100-item'>
          <Input
            placeholder='仅支持英文字母及数字'
            defaultValue={text}
            onBlur={(e) => this.onTableCellBlur(record)}
            onChange={(e) => this.onTableCellChange('sn', e.target.value, record, index)}
          />
        </div>,
      },
      {
        title: '款式件数 *',
        dataIndex: 'quantity',
        key: 'quantity',
        width: '15%',
        align: 'left',
        render: (text, record, index) => <div className='w100-item'>
          <Select
            value={text}
            className='w100-item'
            placeholder="请选择数量"
            allowClear={true}
            onBlur={(e) => this.onTableCellBlur(record)}
            onChange={(val) => this.onTableCellChange('quantity', val, record, index)}
          >
            {
              this.state.STYLE_QUANTITY_LIST?.map(item => <Option key={item.value} value={item.value}>{item.name}</Option>)
            }
          </Select>
        </div>,
      },
      {
        title: '已上传原图',
        dataIndex: 'img_list',
        key: 'img_list',
        width: '460',
        align: 'left',
        render: (imgList, record) => <div className='gallery-img-box' >
          {/*<span onClick={() => this.onStyleDetailClick(record.style_id)}>*/}
            {
              imgList.slice(0, 5).map(url => <img className="gallery-image" src={url} onClick={() => this.onStyleDetailClick(record.style_id)}/>)
            }
            {
              imgList && imgList.length > 0 && <div className="more-btn-box">
                <span className="more-btn" onClick={() => this.onStyleDetailClick(record.style_id)}>共 {imgList.length}</span>
                <Upload
                  name="image"
                  listType="text"
                  className="img-uploader"
                  showUploadList={false}
                  multiple={true}
                  accept="image/png, image/jpeg, image/jpg"
                  action={configs.baseUrl + "/admin/bdorder/style-img-upload"}
                  headers={{
                    token: localStorage.getItem('token') || null,
                  }}
                  data={{style_id: record.style_id}}
                  beforeUpload={this.beforeUpload}
                  onChange={this.handleUploadChange}
                >
                  <button className="ant-btn user-type user-type6 add-more-btn">补充原图</button>
                </Upload>
              </div>
            }
          {/*</span>*/}
          {
            imgList.length === 0 && record.quantity === 0 && <button className="ant-btn user-type user-type4" onClick={() => message.warn('请先填写款式件数')}>上传原图</button>
          }
          {
            imgList.length === 0 && record.quantity > 0 && <Upload
              name="image"
              listType="text"
              className="img-uploader"
              showUploadList={false}
              multiple={true}
              accept="image/png, image/jpeg, image/jpg"
              action={configs.baseUrl + "/admin/bdorder/style-img-upload"}
              headers={{
                token: localStorage.getItem('token') || null,
              }}
              data={{style_id: record.style_id}}
              beforeUpload={this.beforeUpload}
              onChange={this.handleUploadChange}
            >
              <button className="ant-btn user-type user-type4">上传原图</button>
            </Upload>
          }
        </div>,
      },
      {
        title: '生成情况',
        dataIndex: 'sn',
        key: 'sn',
        align: 'left',
        width: '100px',
        render: (text, record, index) => <div>
          {
            record.style_id && record.img_list.length>0 ? <div>
              <div>
                <span>待生成 </span>
                <span>{record?.build_status.wait_total}</span>
              </div>
              <div>
                <span>已生成 </span>
                <span>{record?.build_status.finish_total}</span>
              </div>
            </div> : <div>-</div>
          }
        </div>,
      },
      {
        title: '操作',
        key: 'action',
        width: '10%',
        align: 'left',
        render: (text, record, index) => {
          return <div className='option-col'>
            {
              record.style_id && record.img_list.length>0 && <div>
                <a onClick={() => this.onStyleDetailClick(record.style_id)}>查看详情</a>
              </div>
            }

            {
              record.style_id && record.img_list.length>0 && <div>
                <a onClick={() => this.onBatchBuildClick(record)}>AI 生成</a>
              </div>
            }

            <div>
              <a className="del-btn" onClick={() => this.delStyleClick(record, index)}>删除款式</a>
            </div>
          </div>
        },
      }
    ],
    initInfo: {},
    showInfo: {},
    pagination: {
      total: 0,
      current: 1,
      pageSize: 20,
    },
    markVisible:false,
    loading: false,
    // 生成配置选项
    buildOptions: null,
    // 要生成图片的款式ID
    buildStyleId: null,
    // 显示选项抽屉
    openConfigDrawer: false,
    // 显示选项抽屉-选中的图片信息
    openConfigStyleImgData: null,
    // 浏览器高度
    screenHeight: 0,
    uploadTotal: 0,
    uploadList: [],
    // 表格修改的单项数据
    tableChangeFieldData: null,
    // 批量AI生成选择框
    batchSelectStyleId: null,
    // 显示下载全部最佳高清弹窗
    showHDDownload: false,
    showUploadModal: false
  };
  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 18
    },
  };
  formItemLayout1 = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 12
    },
  };
  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.init(id);
    }
  }

  init = async(id) => {
    id = id || this.props.match.params.id;
    const params = {
      order_id: id
    };
    const res = await API.getOrderDetail(params);
    if (res.code === 0) {
      this.setState({
        // 深度复制
        initInfo: JSON.parse(JSON.stringify(res.data)),
        showInfo: JSON.parse(JSON.stringify(res.data)),
      })
    }
  }

  deleteStyleImg = async(record) => {
    Modal.confirm({
      title: '删除提示！',
      content: (
        <div>
          <p>是否确定删除款式 {record.name}</p>
        </div>
      ),
      okText: '删除',
      cancelText: '取消',
      onOk: async () => {
        const params = {
          style_id: record.style_id
        };
        const res = await API.deleteStyleImg(params);
        if (res.code === 0) {
          this.init();
        }
      }
    });
  }


  onCancel = () => {
    this.props.history.goBack();
    //this.props.history.replace('/home/member/member-list');
  };

  // 添加订单款式
  addOrderStyle = async () => {
    const params = {
      order_id: this.state.showInfo.order_id
    };
    const data = await API.addOrderStyle(params);
    if (data.code === 0) {
      this.init();
    }
  }

  // 删除订单款式
  deleteOrderStyle = async (styleId) => {
    const params = {
      order_id: this.state.showInfo.order_id,
      style_id: styleId
    };
    const data = await API.delOrderStyle(params);
    if (data.code === 0) {
      this.init();
    }
  }

  // 编辑订单款式
  editOrderStyle = async (fieldData) => {
    const params = {
      order_id: this.state.showInfo.order_id,
      style_id: fieldData.style_id,
      field_name: fieldData.key,
      field_value: fieldData.value
    };
    const data = await API.editOrderStyle(params);
    if (data.code === 0) {
      this.setState({
        tableChangeRowData: null
      })
      this.init();
    }
  }

  // 修改订单款式
  updateOrderStyleList = async () => {
    const params = {
      order_id: this.state.showInfo.order_id,
      style_list: this.state.showInfo.style_list
    };
    const data = await API.updateOrderStyleList(params);
    if (data.code === 0) {
      this.init();
      message.success('修改成功');
    }
  }

  //用户备注
  showMark = () => {
    this.setState({
      markVisible: true,
      markText:this.state.initInfo.mark,
    });
  };
  markCancel = e => {
    this.setState({
      markVisible: false,
      markText:'',
    });
  };
  onMark = async () => {
    const fieldsValue = this.props.form.getFieldsValue();
    const params = {
      ...fieldsValue,
      order_id: this.props.match.params.id,
    }
    let data = await API.updateOrderMark(params);
    if (data.code === 0) {
      this.props.form.resetFields();
      this.init();
      message.success('备注已保存');
      this.setState({
        markVisible: false,
        markText:'',
      })
    }
  };

  // 当选中某一页时回调
  pageChange = (page) => {
    // this.init(page.current);
  };

  // 订单编辑
  orderEditClick = () => {
    this.props.history.push('/home/order/order-edit/' + this.state.initInfo.order_id);
  }

  // 订单详情
  onStyleDetailClick = (styleId) => {
    this.props.history.push('/home/order/style-detail/' + styleId);
  }

  // 订单删除
  orderDeleteClick = async () => {
    Modal.confirm({
      title: '删除提示',
      content: (
        <div>
          <p>删除订单后，订单内的款式信息，款式原图及其生成记录都将移除，是否确认删除？</p>
        </div>
      ),
      okText: '删除',
      cancelText: '取消',
      onOk: async () => {
        const params = {
          order_id: this.props.match.params.id,
        }
        let data = await API.delOrder(params);
        if (data.code === 0) {
          message.success('删除成功');
          this.onCancel();
        }
      },
    });
  }

  // 下载全部最佳图
  onDownloadAllClick = async (isHD) => {
    //best_image_status 款式原图是否已标记最佳: 0暂时没有标记最佳图片,-1存在未标记最佳的图片,1已全部标记最佳
    if (this.state.showInfo.best_image_status === -1) {
      Modal.confirm({
        title: '提示',
        content: (
          <div>
            {
              !isHD && <p>还有 {this.state.showInfo.img_total - this.state.showInfo.best_img_total} 张原图未选择最佳，是否继续下载已选择的最近图片？</p>
            }
            {
              isHD && <p>还有 {this.state.showInfo.img_total - this.state.showInfo.best_img_total} 张原图未选择最佳，是否继续下载已选择的高清最佳图片？</p>
            }
          </div>
        ),
        okText: '下载',
        cancelText: '取消',
        onOk: async () => {
          if (!isHD) {
            this.downloadBestImgs();
          } else {
            this.setState({showHDDownload: true})
          }
        }
      });

      return;
    }

    if (this.state.showInfo.best_image_status === 0) {
      message.warn('暂时没有标记最佳图片')
      return;
    }

    this.downloadBestImgs();
  }

  downloadBestImgs = () => {
    const params = {
      token: localStorage.getItem('token'),
      style_id: 0,
      order_id: this.state.showInfo.order_id
    };

    const url = `/admin/bdorder/download-batch-best-img?style_id=${params.style_id}&order_id=${params.order_id}&token=${params.token}`;
    const link = document.createElement('a');
    link.href = url;
    // link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  }

  // 添加款式
  addStyleClick = () => {
    this.addOrderStyle();
  }

  // 删除款式
  delStyleClick = (record, index) => {
    if (record.img_total > 0) {
      Modal.confirm({
        title: '删除提示',
        content: (
          <div>
            <p>删除后该款式的上传原图、生成的记录将直接移除，是否确认删除？</p>
          </div>
        ),
        okText: '删除',
        cancelText: '取消',
        onOk: async () => {
          this.deleteOrderStyle(record.style_id);
        }
      });

      return;
    }
    this.deleteOrderStyle(record.style_id);
  }

  // 保存修改
  onSaveClick = () => {
    this.updateOrderStyleList();
  }

  // 取消修改
  onCancelClick = () => {
    // 深度复制
    const data = JSON.parse(JSON.stringify(this.state.initInfo));
    this.setState({
      showInfo: data
    });
  }

  // 表格数据修改
  onTableCellChange = (key, value, record, index) => {
    console.log(key, value, record, index);
    const newData = {...record, [key]: value};
    this.state.showInfo.style_list[index] = newData;
    this.setState({
      showInfo: this.state.showInfo,
      tableChangeFieldData: {
        style_id: record.style_id,
        key,
        value
      }
    });
  }

  // 表格失去焦点
  onTableCellBlur = () => {
    // 表格有修改，则请求编辑接口
    if (this.state.tableChangeFieldData) {
      this.editOrderStyle(this.state.tableChangeFieldData);
    }
  }

  beforeUpload = (data) => {
    let _URL = window.URL || window.webkitURL;
    let img = new Image();
    img.src = _URL.createObjectURL(data);

    uploadImgTotal = uploadImgTotal + 1;
    this.setState({uploadTotal: uploadImgTotal, showUploadModal: true});

    uploadImgList.push({uid: data.uid, imgUrl: img.src});
    this.setState({uploadList: uploadImgList});
  }

  handleUploadChange = (data) => {
    if (data.file.status === undefined || data.file.status === 'error') {
      return;
    }

    const {uid, name, status, size, percent, response  } = data.file;
    // 检查是否已有记录
    let i = null;
    uploadImgList.map((item, index) => {
      if (item.uid === uid) {
        i = index;
      }
    });

    if (i === null) {
      uploadImgList.push(data.file);
    } else {
      uploadImgList[i] = {...uploadImgList[i], ...data.file};
    }

    this.setState({uploadList: uploadImgList});

    // 上传成功数量
    let finishCount = 0;
    uploadImgList.map((file, index) => {
      if (file.status === 'done') {
        finishCount += 1;
      }
    });

    if (finishCount === uploadImgTotal) {
      // this.onAIBuildClick(uploadImgList, false);

      let uploadImgIds = [];
      uploadImgList.map((file, index) => {
        if (file.status === 'done') {
          uploadImgIds.push(file.response.data.style_img_id);
        }
      });

      setTimeout(() => {
        this.getBuildOptions(response.data.style_id, uploadImgIds);
        uploadImgTotal = 0;
        uploadImgList = [];
        this.setState({uploadTotal: 0, uploadList: [], showUploadModal: false});
      }, 200);

      this.init();
    }
  }

  // 取消上传
  onUploadCancel = () => {
    uploadImgTotal = 0;
    uploadImgList = [];
    this.setState({
      uploadTotal: 0, 
      uploadList: [],
      showUploadModal: false
    });
    this.init();
  }

  // 选择模特
  onModalSelectModel = (styleImgData) => {
    this.setState({
      openConfigDrawer: true,
      openConfigStyleImgData: styleImgData
    })
  }

  // 生成弹窗关闭
  onBuildModalClose = () => {
    this.setState({
      currentStyleImgIds: null,
      buildOptions: null,
      buildStyleId: null
    })
  }

  // 获取生成图片配置
  getBuildOptions = async (styleId, styleImgIds) => {
    const height = document.documentElement.clientHeight;
    this.setState({
      screenHeight: height
    });

    const params = {
      style_id: styleId,
      style_img_ids: styleImgIds || []
    };
    const res = await API.getBuildOptions(params);
    if (res.code === 0) {
      this.setState({
        currentStyleImgIds: styleImgIds,
        buildOptions: res.data,
        buildStyleId: styleId
      })
    }
  }

  // AI生成
  onBatchBuildClick = (record) => {

    // this.getBuildOptions(record.style_id, []);
    this.setState({
      batchSelectStyleId: record.style_id
    });
  }

  // 生成图片参数修改
  onOptionValuesChange = (data) => {
    if (!this.state.buildOptions) {
      return;
    }

    let imgList = [...this.state.buildOptions?.style_imgs];
    imgList.map(item => {
      if (item.style_img_id === this.state.openConfigStyleImgData.style_img_id) {
        item.model_id = data.modelId;
        item.background_id = data.bgId;
      }
    })

    let options = JSON.parse(JSON.stringify(this.state.buildOptions));
    options.style_imgs = imgList;
    this.setState({
      buildOptions: options
    });
  }

  // 款式数据是否修改
  checkStyleDataChanged = () => {
    if (!this.state.initInfo || !this.state.showInfo) {
      return true;
    }
    // 款式数据有修改
    if (JSON.stringify(this.state.initInfo.style_list) !== JSON.stringify(this.state.showInfo.style_list)) {
      return true;
    }

    return false;
  }

  // 获取配置模特信息
  getModelOptions = (value) => {
    if (!value || !this.state.buildOptions) {
      return null;
    }
    let gender = this.state.buildOptions.build_options.gender;
    let maleList = gender.data[0].data;
    let female = gender.data[1].data;

    let res = null;
    (maleList || []).map(item => {
      if (item.value === value) {
        res = item;
      }
    })
    if (!res) {
      (female || []).map(item => {
        if (item.value === value) {
          res = item;
        }
      })
    }
    return res;
  }

  // 获取配置背景信息
  getBgOptions = (value) => {
    if (!value || !this.state.buildOptions) {
      return null;
    }
    let backgrounds = this.state.buildOptions.build_options.background.data;
    let res = null;
    (backgrounds || []).map(items => {
      (items.data || []).map(bgData => {
        if (bgData.value === value) {
          res = items.name +'-'+bgData.name;
        }
      })

    })
    return res;
  }

  // 选项应用至全部
  optionUseAll = (key, value) => {
    const newBuildOptions = JSON.parse(JSON.stringify(this.state.buildOptions));
    newBuildOptions.style_imgs.map(item => {
      item[key] = value
      return item
    });

    this.setState({
      buildOptions: newBuildOptions
    });
  }

  // 生成按钮点击
  onBuildBtnClick = async (imgType) => {
    this.handleAIBuild(2, this.state.buildOptions.style_imgs, imgType);
  }

  // AI 生成
  handleAIBuild = async (buildType, taskArr, imgType) => {
    const params = {
      style_id: this.state.buildStyleId,
      build_type: buildType,
      img_task_arr: taskArr,
      image_type: imgType
    };
    const res = await API.buildTask(params);
    if (res.code === 0) {
      this.init();
      this.onBuildModalClose();
    }
  }

  render() {
    const {showInfo, buildOptions, markVisible, openConfigDrawer, screenHeight, openConfigStyleImgData} = this.state;
    const {getFieldDecorator} = this.props.form;
    return <div className="order-detail-page">
      <div className='header'>
        <Button type="link" onClick={this.onCancel}><Icon type="left" className='back-btn'/>返回</Button>
      </div>
      <div className='content content-tab'>
        <Card title="订单信息" className="card-box">
          <div className='user-items'>
            {
              showInfo?.img_total === 0 && <Button className='user-type user-type5' onClick={this.orderDeleteClick}>删除订单</Button>
            }
            <Button className='user-type user-type4' onClick={this.orderEditClick}>编辑订单</Button>
          </div>
          <div className='info-items'>
            <span><label>订单ID：</label>{showInfo?.order_id}</span>
            <span><label>创建时间：</label>{showInfo?.create_time}</span>
            <span><label>客户昵称：</label>{showInfo?.nickname}</span>
            <span><label>客户手机：</label>{showInfo?.mobile}</span>
            <span><label>关联BD：</label>{showInfo?.bd_name}</span>
            <span><label>款式数量：</label>{formatNum(showInfo?.style_quantity || 0)}</span>
            <span><label>总件数：</label>{formatNum(showInfo?.total_quantity || 0)}</span>
            <span><label>金额(￥)：</label>{formatNum(showInfo?.money || 0)}</span>
          </div>
          <div className='info-items mark-item'>
            <label>备注：</label>
            <div >{showInfo.mark}{showInfo.mark &&<span style={{color: 'rgba(0, 0, 0, 0.45)'}}>（{showInfo.mark_time} 更新）</span>}<Icon type="form"  style={{color:'#40a9ff'}} onClick={this.showMark.bind()}/></div>
          </div>
        </Card>

        <Card title="款式列表" className="card-box">
          <div className='user-items'>
            <Button className='user-type user-type6' onClick={() => this.onDownloadAllClick(true)}>下载全部高清最佳图</Button>
            <Button className='user-type user-type6' onClick={() => this.onDownloadAllClick(false)}>下载全部最佳图</Button>
            <Button className='user-type user-type4' onClick={this.addStyleClick}>添加款式</Button>
          </div>
          <Table
            columns={this.state.columns}
            rowKey={(record, index) => {return `${record.style_id}_${index}`}}
            dataSource={showInfo.style_list}
            pagination={false}
            // pagination={this.state.pagination}
            loading={this.state.loading}
            // onChange={this.pageChange}
            locale={{emptyText: '对不起，没有搜到任何内容'}}
          />
          {/*<div className='btns'>*/}
          {/*  <Button className='user-type user-type5' onClick={this.onCancelClick}>取消修改</Button>*/}
          {/*  <Button disabled={!this.checkStyleDataChanged()} className={`user-type user-type4 ${!this.checkStyleDataChanged() && "btn-disabled"}`} onClick={this.onSaveClick}>保存</Button>*/}
          {/*</div>*/}
        </Card>
      </div>
      <Modal
        title='备注'
        className='order-madal'
        visible={markVisible}
        onCancel={this.markCancel}
        width={600}
        onOk={this.onMark}
        okText="保存"
        cancelText="取消"
      >
        <Form>
          <Form.Item>
            {getFieldDecorator('mark', {
              initialValue: this.state.markText || '',
              rules: [{
                required: false,
                message: '请输入备注，限100字',
              }]
            })(
                <TextArea rows={4} maxLength={100} defaultValue={this.state.markText}/>
            )}
          </Form.Item>
        </Form>
      </Modal>

      {/*批量生成弹窗*/}
      <Modal
        className='build-img-modal'
        title={'AI 生成'}
        centered={true}
        visible={buildOptions}
        width={652}
        // height={500}
        // footer={false}
        okText={'人台生成'}
        cancelText={'取消'}
        onOk={() => this.onBuildBtnClick(2)}
        onCancel={() => this.onBuildModalClose()}
      >
        <Button className='ant-btn-primary build-btn' onClick={() => this.onBuildBtnClick(1)}>真人生成</Button>
        <div className="ai-build-modal" style={{maxHeight: `${screenHeight - 260}px`}}>
          {
            buildOptions?.style_imgs.map((item) => <div key={item.style_img_id} className="item-box">
              <span className="img-box">
                <div className="img-id">原图 ID: {item.style_img_id}</div>
                <img className="gallery-img" src={item?.image} />
              </span>
              <span className="config-info-box">
                <div className="config-item">
                  <span className="config-type">模特</span>
                  <span className={`select-option ${item.style_img_id === this.state.openConfigStyleImgData?.style_img_id && "active"}`} onClick={() => this.onModalSelectModel(item)}>{item.model_id ? this.getModelOptions(item.model_id)?.name : '请选择模特 >'}</span>
                  {
                    buildOptions.style_imgs.length > 1 && <span className="all-btn" onClick={() => this.optionUseAll('model_id', item.model_id)}>模特应用至全部</span>
                  }
                </div>
                <div className="config-item">
                  <span className="config-type">背景风格</span>
                  <span className={`select-option ${item.style_img_id === this.state.openConfigStyleImgData?.style_img_id && "active"}`} onClick={() => this.onModalSelectModel(item)}>{item.background_id ? this.getBgOptions(item.background_id) : '请选择背景 >'}</span>
                  {
                    buildOptions.style_imgs.length > 1 && <span className="all-btn" onClick={() => this.optionUseAll('background_id', item.background_id)}>背景应用至全部</span>
                  }
                </div>
              </span>
            </div>)
          }
        </div>
      </Modal>

      {/*选择配置*/}
      <ConfigOptionsDrawer
        show={openConfigDrawer}
        buildOptions={buildOptions?.build_options}
        imgData={openConfigStyleImgData}
        onClose={() => this.setState({
          openConfigDrawer: false,
          openConfigStyleImgData: null
        })}
        onChange={(changeData) => this.onOptionValuesChange(changeData)}
      />

      {/*上传进度*/}
      {
        this.state.showUploadModal && <ProgressComponent data={{total: this.state.uploadTotal, imgList: this.state.uploadList}} onCancel={() => this.onUploadCancel()} />
      }

      {
        this.state.batchSelectStyleId && <BatchSelectComponent
          orderId={this.state.initInfo.order_id}
          onOk={(imgList) => {
            this.getBuildOptions(this.state.batchSelectStyleId, imgList);
            this.setState({batchSelectStyleId: null});
          }}
          onClose={() => {this.setState({batchSelectStyleId: null})}}
        />
      }

      {
        this.state.showHDDownload && <DownloadHDProgressComponent
          orderId={this.state.initInfo.order_id}
          styleId={null}
          onClose={() => {this.setState({showHDDownload: false})}}
        />
      }
    </div>
  }
};

const MemberFormWrap = Form.create({name: 'member_form'})(OrderDetail);
export default withRouter((MemberFormWrap));