import React, {Component} from 'react';
import {Input, Form, Icon, message, Button, Table, Card, Row, Col} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import API from '../../../apis'
import {withRouter} from 'react-router-dom'
import './index.less';

const { TextArea } = Input;

class ModelEdit extends Component {
  state = {
    info: null,
    historyList: null,
    columns: [
      {
        title: '修改时间',
        dataIndex: 'update_time',
        key: 'update_time',
        align: 'left',
        width: '110px',
        render: text => <div>{text}</div>,
      },
      {
        title: '正向提示词',
        dataIndex: 'prompt',
        key: 'prompt',
        align: 'left',
        width: '40%',
        render: (text) => <div>
          {text || '-'}
        </div>,
      },
      {
        title: '反向提示词',
        dataIndex: 'negative_prompt',
        key: 'negative_prompt',
        align: 'left',
        width: '40%',
        render: (text) => <div>
          {text || '-'}
        </div>,
      },
      {
        title: '备注',
        dataIndex: 'mark',
        key: 'mark',
        align: 'left',
        width: '15%',
        render: (text) => <div>
          {text || '-'}
        </div>,
      }
    ],
    loading: false,
    // 是否展开
    isExpand: false
  };

  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20
    },
  };

  componentDidMount() {
    // 置顶
    const doms = document.getElementsByClassName('ant-layout');
    doms[1].scrollTop = 0;

    const id = this.props.match.params.id;
    if (id) {
      this.init();
    }
  }

  init = async() => {
    const params = {
      model_id: this.props.match.params.id
    };
    const res = await API.modelDetail(params);
    if (res.code === 0) {
      this.setState({
        info: res.data.info,
        historyList: res.data.history,
      })
    }
    setTimeout(() => {

      // const doms = document.getElementsByClassName('ant-layout');
      // console.log('111111111', doms, doms.scrollY)
      // doms[0].scrollIntoView();

      // history.listen(() => {
      //   window.scrollTo(0, 0);
      // });
    }, 1000)
  }

  onCancel = () => {
    this.props.history.goBack();
  };

  handleSubmit = (status) => {
    // e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
          model_id: this.state.info.id,
          status
        };
        let res = await API.editModelDetail(params);
        if (res.code == 0) {
          message.success('修改成功', 1, () => {
            this.init();
          });
        }
      }
    });
  };

  // 展开历史数据
  onExpandClick = () => {
    this.setState({
      isExpand: true,
    });
  }

  render() {
    const {info, historyList, showHistory, isExpand} = this.state;
    const {getFieldDecorator} = this.props.form;

    return <div className="model-edit-page" id="model_edit_page">
      <div className='header-top'>
        <Button type="link" onClick={this.onCancel}><Icon type="left" className='back-btn'/>返回</Button>
        <span className="name">{info?.set_name}</span>
      </div>
      <div className='content content-tab' id="content_tab">
        <Card title="提示词" className="prompt-box">
          <Row type="flex" justify="space-around">
            <Col span={24}>
              <Form {...this.formItemLayout}>
                <Form.Item label='正向提示词'>
                  {getFieldDecorator('prompt', {
                    initialValue: info?.prompt,
                    rules: [{type: 'string'}]
                  })(
                    <TextArea rows={10} placeholder="请输入正向提示词" />
                  )}
                </Form.Item>
                <Form.Item label='反向提示词'>
                  {getFieldDecorator('negative_prompt', {
                    initialValue: info?.negative_prompt,
                    rules: [{type: 'string'}]
                  })(
                    <TextArea rows={10} placeholder="请输入反向提示词" />
                  )}
                </Form.Item>
                <Form.Item label='备注'>
                  {getFieldDecorator('mark', {
                    initialValue: info?.mark,
                    rules: [{type: 'string'}]
                  })(
                    <TextArea rows={4} placeholder="请输入备注" />
                  )}
                </Form.Item>
                <div className='btn-con-no-flex'>
                  <Button type="normal" onClick={this.onCancel}>取消</Button>
                  {/*<Button type="primary" onClick={() => this.handleSubmit(1)}>保存并上架</Button>*/}
                  <Button type="primary" onClick={() => this.handleSubmit(0)}>保存</Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Card>

        <Card title="修改记录" className="history-box">
          <Table
            columns={this.state.columns}
            rowKey={(record, index) => {return `${record.update_time}_${index}`}}
            dataSource={historyList && (isExpand ? historyList : historyList.slice(0, 1))}
            pagination={false}
            loading={this.state.loading}
            // onChange={this.pageChange}
            locale={{emptyText: '对不起，没有搜到任何内容'}}
          />
          {
            (!isExpand && historyList?.length>1) && <Button className="expand-history-btn" type="primary" ghost onClick={() => this.onExpandClick()}>
              展开全部<DownOutlined />
            </Button>
          }
        </Card>
      </div>
    </div>
  }
};

const MemberFormWrap = Form.create({name: 'member_form'})(ModelEdit);
export default withRouter((MemberFormWrap));