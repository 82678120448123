import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import {Drawer, Button} from 'antd';

import API from "../../apis";
import './index.less';

const ConfigOptionsDrawer = ({show, buildOptions, imgData, onClose, onChange}) => {

  // 性别
  const [gender, setGender] = useState(2);
  // 当前选择模特ID
  const [genderSelectId, setGenderSelectId] = useState(null);
  // tab类型
  const [bgType, setBgType] = useState(4);
  // 背景id
  const [bgSelectId, setBgSelectId] = useState(null);

  useEffect(() => {
    // getBuildOptions();
  },[])

  useEffect(() => {
    if (imgData) {
      setGenderSelectId(imgData.model_id);
      setBgSelectId(imgData.background_id);
    }
  },[imgData])

  useEffect(() => {
    if (onChange) {
      // onChange({
      //   modelId: genderSelectId,
      //   bgId: bgSelectId
      // });
    }
  },[genderSelectId, bgSelectId])

  // 获取生成图片配置
  // const getBuildOptions = async () => {
  //   const res = await API.getBuildOptions(null);
  //   if (res.code === 0) {
  //     this.setState({
  //       buildOptions: res.data
  //     })
  //   }
  // }

  const onDrawerCloseClick = () => {
    if (onClose) {
      onClose();
    }
  }


  const onGenderChange = (tab) => {
    document.getElementById('gender_item_box').scrollTo(0, 0);

    setGender(tab);
    setGenderSelectId(getGenderTypeData(tab).data[0].value);
  }

  const getGenderTypeData = (type) => {
    const data = buildOptions?.gender.data.find(item => item.value===type);
    return data;
  }

  const onGenderIdChange = (id) => {
    setGenderSelectId(id);
  }

  const onTabChange = (tab) => {
    document.getElementById('background_item_box').scrollTo(0, 0);

    setBgType(tab);
    setBgSelectId(getBackgroundList(tab)?.data[0].value);
  }

  // 获取指定类型背景数据
  const getBackgroundList = (tab) => {
    const data = (buildOptions?.background.data || []).find(item => {
      return (item.value === tab);
    })
    return data || null;
  }

  const onBgIndexChange = (id) => {
    setBgSelectId(id);
  }

  const onCancelClick = () => {
    onDrawerCloseClick();
  }

  const onOKClick = () => {
    if (onChange) {
      onChange({
        modelId: genderSelectId,
        bgId: bgSelectId
      });
    }
    onDrawerCloseClick();
  }

  return (<div className={`config-options-drawer`}>
    <Drawer
      className="config-options-drawer-container"
      // title="Basic Drawer"
      placement="right"
      width={350}
      onClose={() => onDrawerCloseClick()}
      visible={show}>

      <span className="config-options-container">
        <div className="gender-box">
          <div className="title">选择模特性别</div>
          <div className="gender-info">
            <div className="tab-box">
              <span className={`tab-item ${gender === 2 && "selected"}`} onClick={() => onGenderChange(2)}>女模特</span>
              <span className={`tab-item ${gender === 1 && "selected"}`} onClick={() => onGenderChange(1)}>男模特</span>
            </div>
            <div className="gender-item-container">
              <div id="gender_item_box" className={`gender-item-box`}>
                {
                  buildOptions && getGenderTypeData(gender).data.map((item) =>
                    <span
                      key={item.value}
                      id={`gender_item_${item.value}`}
                      className={`gender-item ${genderSelectId===item.value && "selected"}`}
                      onClick={() => onGenderIdChange(item.value)}
                    >
                      <span className="img-box">
                        <img className="img" src={item.image} />
                      </span>
                      <span className="name">{item.name}</span>
                  </span>)
                }
              </div>
            </div>
          </div>
        </div>

        <div className="background-box">
          <div className="title">选择背景风格</div>
          <div className="background-info">
            <div className="tab-box">
              {
                buildOptions?.background.data.map(item => <span
                  key={item.value}
                  className={`tab-item ${bgType === item.value && "selected"}`}
                  onClick={() => onTabChange(item.value)}>
                  { item?.name }
                </span>)
              }
            </div>
            <div className="background-item-container">
              <div id="background_item_box" className={`background-item-box bg-type-${bgType}`}>
                {
                  getBackgroundList(bgType)?.data.map((item, index) => <span className={`background-item ${[1, 4].includes(bgType) && "background-item-pure-color"} ${bgType===2 && "background-item-indoor"}  ${bgType===3 && "background-item-outdoor"}`} onClick={() => onBgIndexChange(item.value)}>
                    {
                      // 纯色背景 渐变色背景
                      [1, 4].includes(bgType) && <span className={`item-pure-color ${bgSelectId===item.value && "selected"}`}>
                        <span className={`color-box ${bgSelectId===item.value && "selected"}`} style={{background: item.color}}></span>
                        {item.name}
                      </span>
                    }
                    {
                      // 室内
                      bgType === 2 && <span className={`item-room ${bgSelectId===item.value && "selected"}`}>
                        <span className="img-box">
                          <img className="img" src={item.image} />
                        </span>
                        <span className="name">{item.name}</span>
                      </span>
                    }
                    {
                      // 室外
                      bgType === 3 && <span className={`item-room ${bgSelectId===item.value && "selected"}`}>
                        <span className="img-box">
                          <img className="img" src={item.image} />
                        </span>
                        <span className="name">{item.name}</span>
                      </span>
                    }
                  </span>)
                }
              </div>
            </div>
          </div>
        </div>
      </span>

      <div className="btn-box">
        <Button className='ant-btn-normal cancel-btn' onClick={() => onCancelClick()}>取消</Button>
        <Button className='ant-btn-primary ok-btn' onClick={() => onOKClick()}>确定</Button>
      </div>
    </Drawer>
  </div>)
};

export default withRouter(ConfigOptionsDrawer);
