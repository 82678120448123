import React, {Component} from 'react';
import {Tabs,Button } from 'antd';
import './index.less';

import RoleList from './roleList';


const { TabPane } = Tabs;

class Role extends Component {

  callback(key) {
  }
  onAdd = () => {
    this.props.history.push('/home/account/role-list/add');
  };
  render() {
    const operations = <Button type="primary" icon="plus" onClick={this.onAdd}>添加角色</Button>;
    return <div>
      <Tabs onChange={this.callback} type="card" tabBarExtraContent={operations}>
        <TabPane  tab="角色管理" key="1">
          <RoleList/>
        </TabPane >
      </Tabs>

    </div>
  }
}

export default Role;
