import React, {Component} from 'react';
import {Input, Button, Row, Col, Form, Radio,message} from 'antd/lib/index';
import { addRole} from "../../../store/actions/admin";
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import {Divider, Icon, Popover, Select} from "antd";
import API from "../../../apis";
const {Option} = Select;
const { TextArea } = Input;

class MemberEdit extends Component {

  state = {
    bdData: null,
    memberInfo: null
  };

  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16
    },
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.getBDList();
    this.getMember(id);
  }

  // 获取BD列表
  getBDList = async () => {
    let res = await API.getBDUserOption(null);
    if (res.code === 0) {
      this.setState({
        bdData: res.data
      })
    }
  };

  // 添加修改用户
  getMember = async (id) => {
    let res = await API.getMemberEditInfo(id);
    if (res.code === 0) {
      if (res.code == 0) {
        // this.props.form = res.data;
        this.setState({
          memberInfo: res.data
        })
      }
    }
  };

  onCancel = () => {
    this.props.history.goBack();
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let params = {
          ...this.state.memberInfo,
          ...values
        }
        let res = await API.memberUpdate(params);
        if (res.code == 0) {
          message.success(res.msg|| '添加成功', 1, () => {
            // this.props.history.replace('/home/member/member-list');
            this.props.history.goBack();
          });
        }
      }
    });
  };
  render() {
    const {bdData, memberInfo} = this.state;
    const {getFieldDecorator} = this.props.form;

    return <div className="row-permission-setting">
      <Row type="flex" justify="space-around">
        <Col span={23}>
          <Form {...this.formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label='手机号码'>
              {getFieldDecorator('mobile', {
                initialValue: memberInfo?.mobile,
                rules: [{type: 'string', required: true, message: '请输入手机号码'}]
              })(
                <Input placeholder='请输入手机号码' disabled={!memberInfo?.mobile_auth}/>
              )}
            </Form.Item>
            <Form.Item label='昵称'>
              {getFieldDecorator('nickname', {
                initialValue: memberInfo?.nickname,
                rules: [{type: 'string', required: true, message: '请输入昵称'}]
              })(
                <Input placeholder='请输入昵称' disabled={!memberInfo?.nickname_auth}/>
              )}
            </Form.Item>
            <Form.Item label='关联BD'>
              {getFieldDecorator('bd_id', {
                initialValue: memberInfo?.bd_id,
                rules: [{type: 'number', required: false, message: '请选择关联BD'}]
              })(
                <Select
                  placeholder="请选择关联BD"
                  // value={filterConditions.bd_id}
                  // onChange={(value) => this.handleChangeStatus(value, 'bd_id')}
                  allowClear={true}
                  disabled={!memberInfo?.bd_auth}
                >
                  {
                    bdData?.list.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)
                  }
                </Select>
              )}
            </Form.Item>
            <Form.Item label='备注'>
              {getFieldDecorator('mark', {
                initialValue: memberInfo?.mark,
                rules: [{type: 'string'}]
              })(
                <TextArea rows={4} placeholder="请输入备注" maxLength={200} />
              )}
            </Form.Item>
            <div className='btn-con-no-flex'>
              <Button type="primary" htmlType="submit">保存</Button>
              <Button type="normal" onClick={this.onCancel}>取消</Button>
            </div>
          </Form>
        </Col>
      </Row>

    </div>
  }
}
// 将action注入到页面组件
const matDispatchToProps = {
  addRole
};
// 将store注入到页面组件
function mapStateToProps(state) {
  return {
  }
}
const RoleFormWrap = Form.create({ name: 'role_form' })(MemberEdit);

export default withRouter(connect(mapStateToProps, matDispatchToProps)(RoleFormWrap));
