import React, {Component} from 'react';
import {Button, Card, Checkbox, Col, Form, Input, message, Modal, Row, Select, Switch} from 'antd';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import './index.less';
import API from '../../../apis'
import {floatAdd, floatSub, floatMul, floatDiv} from "../../../utils/helpers";

const {Option} = Select;

class Config extends Component {
  state = {
    initInfo: {},
    fundValues: {
      totalMoney: 0,
      rate: 0
    }
  };
  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16
    },
  };
  formItemLayout1 = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 8
    },
  };
  formItemLayout2 = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 12
    },
  };

  componentDidMount() {
    this.init();
  };

  init = async () => {
    const data = await API.getOperateDetail();
    if (data.code === 0) {
      this.setState({
        initInfo: data.data
      })
    }
  };
  onSubmit = async () => {
    const fieldsValue = this.props.form.getFieldsValue();

    let params = {
      ...fieldsValue,
    };

    console.log(params);

    this.props.form.validateFields(async (error, values) => {
      if (!error) {
        let res = await API.configOperate(params);
        if (res.code === 0) {
          message.success('更新成功')
          // window.location.reload();
          await this.init()
          this.forceUpdate()
        }
      } else {
        message.error('请完善表单');
      }
    })
  };
  onCancel = () => {
    this.props.history.replace('/home/Welcome');
  };
  checkPrice = (rule, value, callback, tipText) => {
    if (isNaN(Number(value))) {
      callback();
      return;
    }
    if (Number(value) > -0.01 || value === '') {
      callback();
      return;
    }
    callback(tipText + "要大于等于0");
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    const {initInfo, fundValues} = this.state;
    return <div className='content'>
      <Row type="flex" justify="space-around">
        <Col span={24}>
          <Card title="图片生成消耗" className='tab_card'>
            <Form {...this.formItemLayout} >

              <div className='tab_title'>真人图</div>

              <Form.Item>
                <Col span={4}>快速生成<span className='red_star'>*</span></Col>
                <Col span={12}>
                <Form.Item className='hide_star' label='消耗M豆' {...this.formItemLayout1}>
                  {getFieldDecorator('build_cost.quick_build_cost', {
                    initialValue: initInfo.build_cost?.quick_build_cost,
                      rules: [{
                        required: true,
                        message: '仅允许输入正整数',
                        type: 'number',
                        transform: (value) => {
                          return value ? Number(value) : '';
                        }
                      },
                      ]
                    })(
                      <Input placeholder='仅允许输入正整数' suffix="个"/>
                    )}
                  </Form.Item>
                </Col>
              </Form.Item>
              <Form.Item>
                <Col span={4}>预约生成<span className='red_star'>*</span></Col>
                <Col span={12}>
                <Form.Item className='hide_star' label='消耗M豆' {...this.formItemLayout1}>
                  {getFieldDecorator('build_cost.order_build_cost', {
                    initialValue: initInfo.build_cost?.order_build_cost,
                      rules: [{
                        required: true,
                        message: '仅允许输入正整数',
                        type: 'number',
                        transform: (value) => {
                          return value ? Number(value) : '';
                        }
                      },
                      ]
                    })(
                      <Input placeholder='仅允许输入正整数' suffix="个"/>
                    )}
                  </Form.Item>
                </Col>
              </Form.Item>
              
              <div className='tab_title'>人台图</div>

              <Form.Item>
                <Col span={4}>快速生成<span className='red_star'>*</span></Col>
                <Col span={12}>
                <Form.Item className='hide_star' label='消耗M豆' {...this.formItemLayout1}>
                  {getFieldDecorator('build_cost.dummy_quick_build_cost', {
                    initialValue: initInfo.build_cost?.dummy_quick_build_cost,
                      rules: [{
                        required: true,
                        message: '仅允许输入正整数',
                        type: 'number',
                        transform: (value) => {
                          return value ? Number(value) : '';
                        }
                      },
                      ]
                    })(
                      <Input placeholder='仅允许输入正整数' suffix="个"/>
                    )}
                  </Form.Item>
                </Col>
              </Form.Item>
              <Form.Item>
                <Col span={4}>预约生成<span className='red_star'>*</span></Col>
                <Col span={12}>
                <Form.Item className='hide_star' label='消耗M豆' {...this.formItemLayout1}>
                  {getFieldDecorator('build_cost.dummy_order_build_cost', {
                    initialValue: initInfo.build_cost?.dummy_order_build_cost,
                      rules: [{
                        required: true,
                        message: '仅允许输入正整数',
                        type: 'number',
                        transform: (value) => {
                          return value ? Number(value) : '';
                        }
                      },
                      ]
                    })(
                      <Input placeholder='仅允许输入正整数' suffix="个"/>
                    )}
                  </Form.Item>
                </Col>
              </Form.Item>

            </Form>
          </Card>

          <Card title="套餐配置">
            <Form {...this.formItemLayout} >              
              {
                initInfo.coin_package?.map((item, index) => (
                  <Form.Item>
                    <Col span={4}>
                      {item.name}<span className='red_star'>*</span>
                      <Form.Item style={{display: "none"}}>
                        {getFieldDecorator('coin_package['+index+'][id]', {
                          initialValue: item.id
                          })(
                            <Input />
                          )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item className='hide_star' label='M豆数量' {...this.formItemLayout2}>
                      {getFieldDecorator('coin_package['+index+'][coin]', {
                        initialValue: item.coin,
                        rules: [{
                          required: true,
                          pattern: new RegExp(/^[0-9]+$/, "g"),
                          message: '仅允许输入0和正整数',
                        }]
                        })(
                          <Input placeholder='仅允许输入0和正整数' suffix="个"/>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item className='hide_star' label='套餐价格' {...this.formItemLayout2}>
                      {getFieldDecorator('coin_package['+index+'][money]', {
                        initialValue: item.money,
                        rules: [{
                          required: true,
                          pattern: new RegExp(/^[0-9]+([.]{1}[0-9]{1,2}){0,1}$/, "g"),
                          message: '仅允许输入数字和小数点',
                        }]
                        })(
                          <Input placeholder='仅允许输入数字和小数点' suffix="￥"/>
                        )}
                      </Form.Item>
                    </Col>
                  </Form.Item>
                ))
              }
              <Form.Item>
                    <Col span={4}></Col>
                    <Col span={10}>
                      <Form.Item label=''  extra="注：M豆数量设置为0时，对应套餐下架">
                      </Form.Item>
                    </Col>
              </Form.Item>
            </Form>
          </Card>
          <div className='btn-con'>
            <Button type="primary" onClick={() => this.onSubmit()}>
              保存
            </Button>
            <Button type="normal" onClick={this.onCancel}>
              取消
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  }
};
// 将action注入到页面组件
const matDispatchToProps = {};

// 将store注入到页面组件
function mapStateToProps(state) {
  return {}
}

const ConfigFormWrap = Form.create({name: 'config_form'})(Config);
export default withRouter(connect(mapStateToProps, matDispatchToProps)(ConfigFormWrap));
