import React, {Component} from 'react';
import {Button, Card, Col, DatePicker, Divider, Form, Icon, Input, message, Modal, Popover, Select, Table} from 'antd';
import API from "../../../apis";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from 'moment';
import {connect} from "react-redux";
import {setMemberParam} from "../../../store/actions/operate";
import {setCurrentMenu} from "../../../store/actions/login";

const {Option} = Select;
const {RangePicker} = DatePicker;
const {TextArea} = Input;
const InputGroup = Input.Group;

class Member extends Component {
  constructor(props) {
    super(props);
    if (typeof props.memberParam.toJSON === 'function') {
      this.state.filterConditions = props.memberParam.toJSON();
    } else {
      this.state.filterConditions = props.memberParam;
    }
  }

  state = {
    columns: [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: text => <a onClick={this.onMemberDetail.bind(this, text)}>{text}</a>,
      },
      {
        title: '昵称',
        dataIndex: 'nickname',
        key: 'nickname',
        width: '8%',
        render: text => <div className='w100-item'>{text ? text : '-'}</div>,
      },
      {
        title: '注册手机',
        dataIndex: 'mobile',
        key: 'mobile',
        width: '8%',
        render: text => <div className='w100-item'>{text}</div>,
      },
      {
        title: '注册时间',
        dataIndex: 'create_time',
        key: 'create_time',
        width: '7%',
        render: text => <div className='time-item'>{text}</div>,
      },
      {
        title: '关联BD',
        dataIndex: 'bd_name',
        key: 'bd_name',
        render: text => <div className='w100-item'>{text ? text : '-'}</div>,
      },
      {
        title: '图片生成次数',
        dataIndex: 'build_times',
        key: 'build_times',
      },
      {
        title: '消耗M豆',
        dataIndex: 'cost_coins',
        key: 'cost_coins',
      },
      {
        title: '下单次数',
        dataIndex: 'total_orders',
        key: 'total_orders',
      },
      {
        title: '订单总计(￥)',
        dataIndex: 'order_moneys',
        key: 'order_moneys',
      },
      {
        title: 'M豆余额',
        dataIndex: 'coin',
        key: 'coin',
        render: text => <div className='red'>{text}</div>,
      },
      {
        title: '当前状态',
        key: 'status',
        dataIndex: 'status',
        render: (status, record) => {
          switch (status) {
            case 0:
              return <div style={{color: 'rgba(0, 0, 0, 0.45)'}}>已禁用</div>;
            case 1:
              return <div style={{color: '#87d068'}}>启用</div>;
          }
        },
      },
      {
        title: '备注',
        dataIndex: 'mark',
        key: 'mark',
        render: (text, record) => <>
          {text
              ? <Popover
                  placement="topRight"
                  content={<div className="mark-popover">{record.mark}</div>}
                  title={<div className="mark-popover-title">备注（{record.mark_time} 更新）:</div>}
                  trigger="hover"
              >
                <img
                    src={require('./icon-mark.svg')}
                    style={{cursor: 'pointer', width: '16px'}}
                    onClick={this.showMark.bind(this, record.id, record.mark)}
                />
              </Popover>
              : <Icon
                  type="form"
                  onClick={this.showMark.bind(this, record.id, record.mark)}
                  style={{cursor: "pointer"}}
              />
          }</>,
      },
      {
        title: '操作',
        key: 'action',
        width: '10%',
        render: (record) => {
          let publishStatus = null;
          switch (record.status) {
            case 0:
              publishStatus =
                  <a style={{color: '#87d068'}} onClick={this.onCancelBan.bind(this, record.id, 1)}>启用</a>;
              break;
            case 1:
              publishStatus =
                  <a style={{color: 'rgba(0, 0, 0, 0.45)'}}
                     onClick={this.onBan.bind(this, record.id, record.mobile, 1)}>禁用</a>;
              break;
          }
          return <div className='mW80-item'>
            <a onClick={this.onMemberDetail.bind(this, record.id)}>查看</a>
            <Divider type="vertical"/>
            <a href="javascript:;" onClick={this.showModal.bind(this, record.id, record.mobile)}>发放</a>
            <br/>
            {publishStatus}
          </div>
        },
      }
    ],
    // 过滤条件
    filterConditions: {
      mobile: '',
      status: '',
      nickname: '',
      bd_id: null,
      start_time: '',
      end_time: '',
      id: '',
      pay_min_money: '',
      pay_max_money: '',
      mark: '',
    },
    bdData: null,
    pagination: {
      total: 0,
      current: 1,
      pageSize: 20,
    },
    initList: [],
    loading: false,
    visible: false,
    accessoriesLis: '',
    memberId: '',
    markVisible: false,
    markID: '',
  };
  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16
    },
  };
  formItemLayout1 = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 12
    },
  };

  componentDidMount() {
    this.getBDList();
    this.init();
  }

  // 获取BD列表
  getBDList = async () => {
    let res = await API.getBDUserOption(null);
    if (res.code === 0) {
      this.setState({
        bdData: res.data
      })
    }
  };

  onMark = async () => {
    const fieldsValue = this.props.form.getFieldsValue();
    const params = {
      ...fieldsValue,
      user_id: this.state.markID,
    }
    let data = await API.onMemberMark(params);
    if (data.code === 0) {
      this.props.form.resetFields();
      this.init();
      message.success('备注已保存');
      this.setState({
        markVisible: false,
        markText: '',
      })
    }
  };

  //用户备注
  showMark = (id, mark) => {
    this.setState({
      markText: mark,
      markID: id,
      markVisible: true,
    });
  };
  markCancel = e => {
    this.setState({
      markVisible: false,
      markText: '',
    });
  };

  onChange = (date, dateString) => {
    this.setState({
      filterConditions: {
        ...this.state.filterConditions,
        start_time: dateString[0],
        end_time: dateString[1],
      }
    })
  }
  feach_keyword = (value, type) => {
    this.setState({
      filterConditions: {
        ...this.state.filterConditions,
        [type]: value.target.value,
      },
    })
  };
  onSearch = () => {
    this.init(1);
  };
  onEmpty = () => {
    this.setState({
      filterConditions: {
        id: '',
        mobile: '',
        status: '',
        start_time: '',
        end_time: '',
        nickname: '',
        bd_id: null,
        pay_min_money: '',
        pay_max_money: '',
        mark: '',
      },
    }, () => {
      this.init(1);
    });
  };
  init = async (page) => {
    this.setState({loading: true});
    const data = await API.getMemberList({
      ...this.state.filterConditions,
      //优先用参数中的page，如果是初始化列表的话，用store中的page，也就是记录下来的页码
      page: page || this.props.memberParam.page
    });
    if (data.code === 0) {
      this.props.setMemberParam({
        ...this.state.filterConditions,
        page: page || this.props.memberParam.page
      });
      this.setState({
        initList: data.data.list,
        total: data.data.total,
        total_pay_coin: data.data.total_pay_coin,
        total_pay_member: data.data.total_pay_member,
        total_pay_money: data.data.total_pay_money,
        total_reg_member: data.data.total_reg_member,
        loading: false,
        pagination: {
          total: data.data.total,
          current: page || this.props.memberParam.page
        },
      })
    }
  }
  // 当选中某一页时回调
  pageChange = (page) => {
    this.init(page.current);
  };

  onBan = async (id, mobile, type) => {
    Modal.confirm({
      title: '禁用提示！',
      content: (
          <div>
            <p>是否禁用 <span style={{color: '#f5222d'}}>{mobile}</span> 该用户？</p>
          </div>
      ),
      okText: '禁用',
      cancelText: '取消',
      onOk: async () => {
        const data = await API.operaMember({
          id: id,
          type: type
        });
        if (data.code === 0) {
          this.init();
          message.success('禁用成功');
        }
      },
    });
  };
  onCancelBan = async (id, type) => {
    const data = await API.operaMember({
      id: id,
      type: type
    });
    if (data.code === 0) {
      this.init();
      message.success('启用成功');
    }
  };

  onMemberDetail = (id) => {
    this.props.history.push('/home/member/member-list/detail/' + id);
  }

  showModal = (id, mobile) => {
    this.setState({
      visible: true,
      memberId: id,
      mobile: mobile,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
    this.props.form.setFieldsValue({
      "type": null
    })
  };

  handleSearch = async (value) => {
    const data = await API.getAccessoriesList({keyword: value});
    if (data.code === 0) {
      this.setState({
        accessoriesList: data.data.list,
      })
    }
  };
  handleChangeStatus = (value, type) => {
    this.setState({
      filterConditions: {
        ...this.state.filterConditions,
        [type]: value,
      },
    })
  };
  onGrant = async () => {
    const fieldsValue = this.props.form.getFieldsValue();
    const params = {
      ...fieldsValue,
      id: this.state.memberId,
    }
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let res = await API.grant(params);
        if (res.code === 0) {
          message.success('发放成功');
          this.setState({
            visible: false,
          });
        }
      } else {
        message.error('请完善表单');
      }
    });
  };

  render() {
    const {
      initList,
      markVisible,
      filterConditions,
      bdData,
      memberId,
      mobile,
      total_pay_coin,
      total_pay_member,
      total_pay_money,
      total_reg_member,
      pagination
    } = this.state;
    const {getFieldDecorator} = this.props.form;

    return <div className='content-tab '>
      <Card title="全部">
        <div className='serach-area'>
         <span>
            <label>ID：</label>
            <Input
                placeholder="请输入ID"
                value={filterConditions.id}
                onChange={(value) => this.feach_keyword(value, 'id')}
            />
          </span>
          <span>
            <label>昵称：</label>
            <Input
              placeholder="请输入昵称"
              value={filterConditions.nickname}
              onChange={(value) => this.feach_keyword(value, 'nickname')}
            />
          </span>
          <span>
            <label>注册手机：</label>
            <Input
                placeholder="请输入注册手机"
                value={filterConditions.mobile}
                onChange={(value) => this.feach_keyword(value, 'mobile')}
            />
          </span>
        </div>
        <div className='serach-area'>
          <span>
            <label>注册日期：</label>
           <RangePicker
             locale={locale}
             value={[
               filterConditions.start_time ? moment(filterConditions.start_time) : null,
               filterConditions.end_time ? moment(filterConditions.end_time) : null
             ]}
             onChange={this.onChange.bind()}
           />
          </span>
          <span>
            <label>关联BD：</label>
            <Select
              placeholder="请选择关联BD"
              value={filterConditions.bd_id}
              onChange={(value) => this.handleChangeStatus(value, 'bd_id')}
            >
              {
                bdData?.list.map(item => <Option value={item.id}>{item.name}</Option>)
              }
            </Select>
          </span>
          <span>
            <label>账号状态：</label>
            <Select
              placeholder="请选择账号状态"
              value={filterConditions.status}
              onChange={(value) => this.handleChangeStatus(value, 'status')}
            >
              <Option value="">全部</Option>
              <Option value="1">启用</Option>
              <Option value="0">禁用</Option>
            </Select>
          </span>
        </div>
        <div className='serach-area'>
          <span>
            <label>订单总计：</label>
            <InputGroup compact>
              <Input
                style={{width: 80}}
                placeholder="最低"
                value={filterConditions.pay_min_money}
                onChange={(value) => this.feach_keyword(value, 'pay_min_money')}
              />
              <Input
                style={{
                  width: 40,
                  borderLeft: 0,
                  pointerEvents: 'none',
                  backgroundColor: '#fff',
                }}
                value="~"
                disabled
              />
              <Input
                style={{width: 80, borderLeft: 0}}
                placeholder="最高"
                value={filterConditions.pay_max_money}
                onChange={(value) => this.feach_keyword(value, 'pay_max_money')}
              />
            </InputGroup>
          </span>
          <span>
            <Button type="primary" onClick={this.onSearch}>搜索</Button>
            <Button type="danger" onClick={this.onEmpty}>清空</Button>
          </span>
        </div>
        <p>
          注册用户： <span className='red'>{total_reg_member} 人</span>，
          支付用户：<span className='red'>{total_pay_member} 人</span>，
          合计金额：<span className='red'>￥{total_pay_money}</span>，
          共 <span className='red'>{total_pay_coin} M豆</span>
        </p>
        <Table
            columns={this.state.columns}
            rowKey="id"
            dataSource={initList}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.pageChange}
            locale={{emptyText: '对不起，没有搜到任何内容'}}
        />
      </Card>
      <Modal
          title="发放"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onOk={() => this.onGrant()}
          okText="发放"
          cancelText="取消"
          width={600}
      >
      <Form {...this.formItemLayout} >
        <Form.Item label='用户ID'>
          <Col span={6}>
            <Form.Item>
              {memberId}
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label='注册手机' {...this.formItemLayout1} >
              <span className='ellipsis-items'>{mobile}</span>
            </Form.Item>
          </Col>
        </Form.Item>
        <Form.Item label='M豆数量' extra="注：发放的M豆将作为一个单独的30天套餐发放至用户账户中">
          {getFieldDecorator('coin', {
            rules: [{required: true, message: '仅允许输入数字'}]
          })(
              <Input placeholder='仅允许输入数字'/>
          )}
        </Form.Item>
      </Form>
      </Modal>
      <Modal
          title='备注'
          className='order-madal'
          visible={markVisible}
          onCancel={this.markCancel}
          width={600}
          onOk={this.onMark}
          okText="保存"
          cancelText="取消"
      >
        <Form>
          <Form.Item>
            {getFieldDecorator('mark', {
              initialValue: this.state.markText || '',
              rules: [{
                required: false,
                message: '请输入订单备注，限100字',
              }]
            })(
                <TextArea rows={4} maxLength={100} initialValue={this.state.markText} value={this.state.markText}/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  }
}

const matDispatchToProps = {
  setMemberParam, setCurrentMenu
};

function mapStateToProps(state) {
  return {
    memberParam: state.getIn(['operate', 'memberParam'], 1),
  }
}

const MemberFormWrap = Form.create({name: 'member_form'})(Member);
export default connect(mapStateToProps, matDispatchToProps)(MemberFormWrap);
