import React, {Component} from 'react';
import { Input, Button,  Row, Col, Form, Radio,message} from 'antd';
import {fetchRoleList,getAdminID,editAdmin} from "../../../../store/actions/admin";
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";

class adminEdit extends Component {
  handleChange = ({ fileList }) => this.setState({ fileList });
  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16
    },
  };
  onCancel = () => {
    this.props.history.goBack();
  };
  componentDidMount() {
    this.props.fetchRoleList();
    this.props.getAdminID(this.props.match.params.id);
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const payload = {
          ...values,
          id: this.props.match.params.id,
        };
        let res = await editAdmin(payload);
        if (res.code == 0) {
          message.success(res.msg|| '修改成功', 1, () => {
            this.props.history.replace('/home/account/admin-list');
          });
        }
      }
    });
  };
  render() {
    const {getFieldDecorator} = this.props.form;
    const initInfo = this.props.adminInfoByID || {};
    return <div className="row-permission-setting">
      <Row type="flex" justify="space-around">
        <Col span={22}>
          <Form {...this.formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label='用户名'>
              {getFieldDecorator('user_login', {
                initialValue: initInfo.user_login,
                rules: [{type: 'string', required: true, message: '请输入用户名'}]
              })(
                <Input placeholder='请输入用户名'/>
              )}
            </Form.Item>
            <Form.Item label="密码" hasFeedback>
              {getFieldDecorator('user_pass', {
                rules: [
                  {
                    required: false,
                    message: '请输入密码!',
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                ],
              })(<Input.Password placeholder="******"/>)}
            </Form.Item>
            <Form.Item label="邮箱">
              {getFieldDecorator('user_email', {
                initialValue: initInfo.user_email,
                rules: [
                  {
                    required: true,
                    message: '请输入邮箱',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label='角色'>
              {getFieldDecorator('role_id', {
                initialValue: initInfo.role_id,
                rules: [{ required: true, message: '请选择角色'}]
              })(
                <Radio.Group>
                {this.props.roleList?.map(({id, name}) => (
                    <Radio key={id} value={id}>{name}</Radio>))}
                </Radio.Group>
              )}
            </Form.Item>
            <div className='btn-con-no-flex'>
              <Button type="primary"  htmlType="submit">
                保存
              </Button>

              <Button type="normal" onClick={this.onCancel}>
                取消
              </Button>
            </div>
          </Form>
        </Col>
      </Row>

    </div>
  }
}
// 将action注入到页面组件
const matDispatchToProps = {
  fetchRoleList,getAdminID,editAdmin
};

// 将store注入到页面组件
function mapStateToProps(state) {
  return {
    roleList: state.getIn(['admin', 'roleList'], []),
    adminInfoByID: state.getIn(['admin', 'adminInfoByID'], {}),
  }
}
const AdminEditFormWrap = Form.create({ name: 'admim_edit_form' })(adminEdit);

export default withRouter(connect(mapStateToProps, matDispatchToProps)(AdminEditFormWrap));


