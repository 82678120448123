import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import {
    DO_LOGIN,DO_LOGOUT,
    FETCH_VERFITY_CODE,
    SET_CURRENT_MENU,
} from '../actions/login';

const initialState = fromJS({
    // 登录凭证
    token: '',
    // 登出凭证
    message: '',
    // 用户信息
    user: null,
    // 验证码
    verifyCode: '',
    // 当前菜单信息
    currentMenuConfig: '',
});

export const global = handleActions(
    {
        [DO_LOGIN]: (state, action) => {
            return state.set('token', action.payload.token)
                        .set('user', action.payload.user);
        },
        [FETCH_VERFITY_CODE]: (state, action) => {
            return state.set('verifyCode', action.payload);
        },
        [DO_LOGOUT]: (state, action) => {
          return state.set('message', action.payload.message);
        },
        [SET_CURRENT_MENU]: (state, action) => {
          return state.set('currentMenuConfig', action.payload);
        }
    },
    initialState
);
