import React, {Component} from 'react';
import {Input, Form, Icon, message, Button, Table, Tabs, Modal, Select, Card, Upload, Drawer, } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import API from '../../../apis'
import {withRouter} from 'react-router-dom'
import './index.less';
import configs from "../../../configs";
import ConfigOptionsDrawer from "../../../components/config-options-drawer";
import StyleImgPreviewComponent from "../../../components/style-img-preview";
import ProgressComponent from '../../../components/progress';
import BatchSelectComponent from "../../../components/batch-select";
import DownloadHDProgressComponent from "../../../components/download-hd-progress";

const { TabPane } = Tabs;
const {Option} = Select;
const { TextArea } = Input;

let uploadImgTotal = 0;
let uploadImgList = [];
// 款式详情
class StyleDetail extends Component {
  state = {
    columns: [
      {
        title: '原图ID',
        dataIndex: 'style_img_id',
        key: 'style_img_id',
        width: '7%',
        align: 'left',
        render: (text, record, index) => {
          return {
            children: <div>{text}</div>,
            props: { rowSpan: record.rowSpan}
          }
        },
      },
      {
        title: '原图、终稿',
        dataIndex: 'gallery_image',
        key: 'gallery_image',
        width: '15%',
        align: 'left',
        render: (imgData, record, index) => {
          return {
            children: <div className='gallery-list-box'>
                <span className="gallery-item" onClick={() => this.onPreviewClick(record)}>
                  <img className="gallery-image" src={imgData?.thumbnail} />
                  <span className='gallery-name-box'>原图</span>
                </span>
              <span className="gallery-item">
                { // 精修
                  record.refine_gallery_image
                  && <img className="gallery-image" src={record?.refine_gallery_image.thumbnail} onClick={() => {
                    this.onPreviewClick(record);
                    this.setState({isRefinePreview: true})
                  }}/>
                }
                { // 最佳
                  !record.refine_gallery_image && record.best_gallery_image
                  && <img className="gallery-image" src={record.best_gallery_image.thumbnail} onClick={() => {
                    this.onPreviewClick(record, record.best_gallery_image.image)
                  }}/>
                }
                {
                  !record.refine_gallery_image && !record.best_gallery_image
                  && <span className="empty-img" onClick={() => this.onPreviewClick(record)}>未选择<br />最佳图片</span>
                }
                {
                  record.refine_gallery_image && <span className='refine-name-box'>精修</span>
                }
                {
                  !record.refine_gallery_image && record.best_gallery_image && <span>最佳</span>
                }
                {
                  !record.refine_gallery_image && !record.best_gallery_image && <span className='empty-name-box'>最佳</span>
                }
                </span>
            </div>,
            props: { rowSpan: record.rowSpan}
          }
        },
      },
      {
        title: '生成时间',
        dataIndex: 'create_time',
        key: 'create_time',
        width: '102px',
        align: 'left',
        render: (text, record) => <div className='w100-item'>
          {text}
        </div>,
      },
      {
        title: '生成场景',
        dataIndex: 'image_type',
        key: 'image_type',
        width: '102px',
        align: 'left',
        render: (text, record) => <div className='w100-item'>
          {text === 1 ? '真人图' : '人台图'}
        </div>,
      },
      {
        title: '图片设定',
        dataIndex: 'model_name',
        key: 'model_name',
        width: '15%',
        align: 'left',
        render: (text, record) => <div className='w100-item'>
          {
            record.task_list.length > 0 ? <div>
              <div>{record.gender_name} | {record.model_name}</div>
              <div>背景 | {record.background_name}</div>
            </div> : <span>-</span>
          }
        </div>,
      },
      {
        title: '生成状态',
        dataIndex: 'status',
        key: 'status',
        align: 'left',
        render: (text, record) => <div className='w100-item'>
          {
            record.task_list.length > 0 ? <div style={{color: record.status===-1 ? '#ff5659' : (record.status===3 ? '#04c07d' : '')}}>{this.state.STATUS_MAP[`${record.build_type}-${record.status}`]}</div> : <span>-</span>
          }
        </div>,
      },
      {
        title: '生成图片',
        dataIndex: 'img_list',
        key: 'img_list',
        align: 'left',
        render: (text, record, index) => <div className='build-img-container'>
          {
            record.task_list.length > 0 ? <div className="output-list-box">
              {
                (record.outputs && record.outputs.length > 0) && <div className="output-img-box">
                  {
                    (record.outputs || []).map(imgData => <span className={'output-img-item'} onClick={() => this.onPreviewClick(record, imgData.image)}>
                      <img className="output-img" src={imgData?.thumbnail} />
                      {
                        record.best_gallery_image && record.best_gallery_image.image === imgData.image && <span className="best-tag">最佳</span>
                      }
                    </span>)
                  }
                </div>
              }

              {
                // 排队中
                record.build_type===1 && record.status===1 && <span className="build-status-box">排队中</span>
              }
              {
                // 已预约
                record.build_type===2 && record.status===1 && <span className="build-status-box">预计24小时内完成</span>
              }
              {
                // 生成中
                record.status===2 && <span className="build-status-box">图片生成中...</span>
              }
              {
                // 生成失败
                record.status===-1 && <span className="build-status-box">图片生成失败</span>
              }
            </div> : <span className="build-status-box">未生成</span>
          }
        </div>,
      },
      {
        title: '操作',
        key: 'action',
        width: '10%',
        align: 'left',
        render: (text, record, index) => {
          return {
            children: <div className='mW80-item operate-box'>
              {
                record.status===3 && record.refine_gallery_image && <div className="select-box">
                  <div><ExclamationCircleFilled style={{color: '#AAAAAA', fontSize: '12px'}} />若精修图片不满意，请重新上传</div>
                  <Upload
                    name="image"
                    listType="text"
                    className="img-uploader"
                    showUploadList={false}
                    // multiple={true}
                    accept="image/png, image/jpeg, image/jpg"
                    action={configs.baseUrl + "/admin/bdorder/style-img-upload-refine"}
                    headers={{
                      token: localStorage.getItem('token') || null,
                    }}
                    data={{style_img_id: record?.style_img_id}}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleUploadChange}
                  >
                    <Button className="ant-btn user-type user-type6">重新上传精修图</Button>
                  </Upload>
                </div>
              }
              {
                record.status===3 && !record.refine_gallery_image && record.best_gallery_image && <div className="select-box">
                  <div><ExclamationCircleFilled style={{color: '#AAAAAA', fontSize: '12px'}} />若最佳图有缺陷，请精修后上传</div>
                  <Upload
                    name="image"
                    listType="text"
                    className="img-uploader"
                    showUploadList={false}
                    // multiple={true}
                    accept="image/png, image/jpeg, image/jpg"
                    action={configs.baseUrl + "/admin/bdorder/style-img-upload-refine"}
                    headers={{
                      token: localStorage.getItem('token') || null,
                    }}
                    data={{style_img_id: record?.style_img_id}}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleUploadChange}
                  >
                    <Button className="ant-btn user-type user-type7">上传精修图</Button>
                  </Upload>
                </div>
              }
              {
                record.status===3 && !record.refine_gallery_image && !record.best_gallery_image && <div className="select-box">
                  <div><ExclamationCircleFilled style={{color: '#AAAAAA', fontSize: '12px'}} />请在生成记录中选择最佳的图片</div>
                  <Button className="ant-btn user-type select-btn" onClick={() => this.setState({previewImgData: record})}>选择最佳图片</Button>
                </div>
              }
              {
                record.task_list.length === 0
                  ? <Button className='user-type user-type4' onClick={() => this.onAIBuildClick([record.style_img_id])}>AI 生成</Button>
                  : <Button className='user-type user-type6' onClick={() => this.onAIBuildClick([record.style_img_id], true)}>再次生成</Button>
              }
              <Button className='user-type user-type5' onClick={() => this.onDeleteClick(record.style_img_id)}>删除原图</Button>
            </div>,
            props: { rowSpan: record.rowSpan}
          }
        },
      }
    ],
    // 生成状态
    STATUS_MAP: {
      '1-1': '排队中',
      '1-2': '生成中',
      '1-3': '已完成',
      '1--1': '生成失败',
      '2-1': '已预约',
      '2-2': '生成中',
      '2-3': '已完成',
      '2--1': '生成失败',
    },
    initInfo: {},
    styleTaskList: [],
    pagination: {
      total: 0,
      current: 1,
      pageSize: 20,
    },
    loading: false,
    // 当前选择款式图片ids
    currentStyleImgIds: null,
    // 生成配置选项
    buildOptions: null,
    // 显示选项抽屉
    openConfigDrawer: false,
    // 显示选项抽屉-选中的图片信息
    openConfigStyleImgData: null,
    // 图片预览
    previewImgData: null,
    // 是否精修
    isRefinePreview: false,
    // 是否重新生成
    isRebuild: false,
    // 浏览器高度
    screenHeight: 0,
    // 预览最佳图片地址
    initPreviewId: null,
    uploadTotal: 0,
    uploadList: [],
    // 显示批量上传选择弹窗
    showBatchSelect: false,
    // 显示下载全部最佳高清弹窗
    showHDDownload: false,
    showUploadModal: false
  };
  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 18
    },
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.init(id);
    }
  }

  init = async(id) => {
    id = id || this.props.match.params.id;
    const params = {
      style_id: id
    };
    const res = await API.getStyleDetail(params);
    if (res.code === 0) {
      const newData = JSON.parse(JSON.stringify(res.data));
      this.setState({
        // 深度复制
        initInfo: newData,
      })
      this.handleStyleTaskList(newData.style_imgs.list);
    }
  }

  // 获取生成图片配置
  getBuildOptions = async (styleImgIds) => {

    const height = document.documentElement.clientHeight;
    this.setState({
      screenHeight: height
    });

    const params = {
      style_id: this.props.match.params.id,
      style_img_ids: styleImgIds || []
    };
    const res = await API.getBuildOptions(params);
    if (res.code === 0) {
      this.setState({
        currentStyleImgIds: styleImgIds,
        buildOptions: res.data
      })
    }
  }

  // 获取生成图片任务俩表
  handleStyleTaskList = (list) => {
    let newStyleList = [];
    (list || []).map(item => {
      const taskLen = item.task_list.length;
      Object.assign(item, {rowSpan: taskLen || 1});
      (item.task_list || []).map((task, index) => {
        if (index > 0) {
          item.rowSpan = 0;
        }

        newStyleList.push({
          ...item,
          ...task
        });
      });
      if (!item.task_list || item.task_list.length === 0) {
        newStyleList.push({
          ...item
        });
      }
    });
    this.setState({
      styleTaskList: newStyleList
    });
  }

  // 生成按钮点击
  onBuildBtnClick = async (imgType) => {
    // 生成方式（1快速生成，2预约生成）。批量生成与AI生成选2，重新生成选1
    let buildType = this.state.isRebuild ? 1 : 2;

    this.handleAIBuild(buildType, this.state.buildOptions.style_imgs, imgType);
  }

  // AI 生成
  handleAIBuild = async (buildType, taskArr, imgType) => {
    const params = {
      style_id: this.props.match.params.id,
      build_type: buildType,
      img_task_arr: taskArr,
      image_type: imgType
    };
    const res = await API.buildTask(params);
    if (res.code === 0) {
      this.init();
      this.onBuildModalClose();
    }
  }

  onCancel = () => {
    this.props.history.goBack();
  };

  // 当选中某一页时回调
  pageChange = (page) => {
    // this.init(page.current);
  };

  // 下载全部最佳图
  onDownloadAllClick = async (isHD) => {
    if (this.state.styleTaskList.length === 0) {
      message.warn('请先上传原图');
      return;
    }

    if (this.state.initInfo.style_imgs.best_image_status === -1) {
      Modal.confirm({
        title: '提示',
        content: (
          <div>
            {
              !isHD && <p>还有 {this.state.initInfo.style_imgs.img_total - this.state.initInfo.style_imgs.best_img_total} 张原图未选择最佳，是否继续下载已选择的最近图片？</p>
            }
            {
              isHD && <p>还有 {this.state.initInfo.style_imgs.img_total - this.state.initInfo.style_imgs.best_img_total} 张原图未选择最佳，是否继续下载已选择的高清最佳图片？</p>
            }
          </div>
        ),
        okText: `${isHD ? '继续下载' : '下载'}`,
        cancelText: '取消',
        onOk: async () => {
          if (!isHD) {
            this.downloadBestImgs();
          } else {
            this.setState({showHDDownload: true})
          }
        }
      });

      return;
    }

    if (this.state.initInfo.style_imgs.best_image_status === 0) {
      message.warn('暂时没有标记最佳图片')
      return;
    }

    this.downloadBestImgs();
  }

  downloadBestImgs = () => {
    const params = {
      token: localStorage.getItem('token'),
      style_id: this.props.match.params.id,
      order_id: this.state.initInfo.order_id
    };

    const url = `/admin/bdorder/download-batch-best-img?style_id=${params.style_id}&order_id=${params.order_id}&token=${params.token}`;
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
  }

  // AI批量生成
  onBatchBuildClick = () => {
    if (this.state.styleTaskList.length === 0) {
      message.warn('请先上传原图');
      return;
    }

    this.setState({showBatchSelect: true});
    // this.getBuildOptions([]);
  }

  beforeUpload = (data, fileList) => {
    let _URL = window.URL || window.webkitURL;
    let img = new Image();
    img.src = _URL.createObjectURL(data);
    uploadImgTotal = uploadImgTotal + 1;
    this.setState({uploadTotal: uploadImgTotal, showUploadModal: true});

    uploadImgList.push({uid: data.uid, imgUrl: img.src});
    this.setState({uploadList: uploadImgList});
  }

  handleUploadChange = (data) => {
    if (data.file.status === undefined || data.file.status === 'error') {
      return;
    }

    const {uid, name, status, size, percent, response  } = data.file;
    // 检查是否已有记录
    let i = null;
    uploadImgList.map((item, index) => {
      if (item.uid === uid) {
        i = index;
      }
    });

    if (i === null) {
      uploadImgList.push(data.file);
    } else {
      uploadImgList[i] = {...uploadImgList[i], ...data.file};
    }

    this.setState({uploadList: uploadImgList});

    // 上传成功数量
    let finishCount = 0;
    uploadImgList.map((file, index) => {
      if (file.status === 'done') {
        finishCount += 1;
      }
    });

    if (finishCount === uploadImgTotal) {
      let uploadImgIds = [];
      uploadImgList.map((file, index) => {
        if (file.status === 'done') {
          uploadImgIds.push(file.response.data.style_img_id);
        }
      });

      setTimeout(() => {
        // 非精修图
        if (response && !response.data.is_refine) {
          // 生成图片窗口
          this.onAIBuildClick(uploadImgIds, false);
        }

        uploadImgTotal = 0;
        uploadImgList = [];
        this.setState({uploadTotal: 0, uploadList: [], showUploadModal: false});
      }, 200);
      this.init();
    }
  }

  // 取消上传
  onUploadCancel = () => {
    uploadImgTotal = 0;
    uploadImgList = [];
    this.setState({
      uploadTotal: 0, 
      uploadList: [],
      showUploadModal: false
    });
    this.init();
  }

  onAIBuildClick = (styleImgIds, isRebuild) => {
    this.getBuildOptions(styleImgIds);
    this.setState({
      isRebuild: isRebuild ? true : false
    });
  }

  onDeleteClick = async (styleImgId) => {
    Modal.confirm({
      title: '删除提示！',
      content: (
        <div>
          <p>删除后该原图及其生成的记录将直接移除，是否确认删除？</p>
        </div>
      ),
      okText: '删除',
      cancelText: '取消',
      onOk: async () => {
        const params = {
          style_id: this.props.match.params.id,
          style_img_id: styleImgId
        };
        const res = await API.delStyleImg(params);
        if (res.code === 0) {
          this.init();
        }
      }
    });
  }

  // 选择模特
  onModalSelectModel = (styleImgData) => {
    this.setState({
      openConfigDrawer: true,
      openConfigStyleImgData: styleImgData
    })
  }

  // 生成图片参数修改
  onOptionValuesChange = (data) => {
    if (!this.state.buildOptions) {
      return;
    }

    let imgList = [...this.state.buildOptions?.style_imgs];
    imgList.map(item => {
      if (item.style_img_id === this.state.openConfigStyleImgData.style_img_id) {
        item.model_id = data.modelId;
        item.background_id = data.bgId;
      }
    })

    let options = JSON.parse(JSON.stringify(this.state.buildOptions));
    options.style_imgs = imgList;
    this.setState({
      buildOptions: options
    });
  }

  // 获取配置模特信息
  getModelOptions = (value) => {
    if (!value || !this.state.buildOptions) {
      return null;
    }
    // console.log('this.state.buildOptions.....', this.state.buildOptions);
    let gender = this.state.buildOptions.build_options.gender;
    let maleList = gender.data[0].data;
    let female = gender.data[1].data;

    let res = null;
    (maleList || []).map(item => {
      if (item.value === value) {
        res = item;
      }
    })
    if (!res) {
      (female || []).map(item => {
        if (item.value === value) {
          res = item;
        }
      })
    }
    return res;
  }

  // 获取配置背景信息
  getBgOptions = (value) => {
    if (!value || !this.state.buildOptions) {
      return null;
    }
    let backgrounds = this.state.buildOptions.build_options.background.data;

    let res = null;
    (backgrounds || []).map(items => {
      (items.data || []).map(bgData => {
        if (bgData.value === value) {
          res = items.name +'-'+bgData.name;
        }
      })

    })
    return res;
  }

  // 生成弹窗关闭
  onBuildModalClose = () => {
    this.setState({
      currentStyleImgIds: null,
      buildOptions: null,
      isRebuild: false
    })
  }

  // 选项应用至全部
  optionUseAll = (key, value) => {
    const newBuildOptions = JSON.parse(JSON.stringify(this.state.buildOptions));
    newBuildOptions.style_imgs.map(item => {
      item[key] = value
      return item
    });

    this.setState({
      buildOptions: newBuildOptions
    });
  }

  // 预览图片点击
  onPreviewClick = (record, imgUrl=null) => {
    this.setState({
      previewImgData: record,
      initPreviewId: imgUrl
    });
  }

  render() {
    const {initInfo, buildOptions, openConfigDrawer, currentStyleImgIds, screenHeight, openConfigStyleImgData, previewImgData} = this.state;
    return <div>
      <div className='header'>
        <Button type="link" onClick={this.onCancel}><Icon type="left" className='back-btn'/>返回</Button>
      </div>
      <div className='content content-tab'>
        <Card title="款式信息" className="card-box">
          <div className='info-items'>
            <span style={{width: '20%'}}><label>款式ID：</label>{initInfo?.style_id}</span>
            <span style={{width: '30%'}}><label>款式名称：</label>{initInfo?.name}</span>
            <span style={{width: '20%'}}><label>款式件数：</label>{initInfo?.quantity}</span>
            <span style={{width: '30%'}}><label style={{width: '100px'}}>商家款式 ID：</label>{initInfo?.sn}</span>
          </div>
        </Card>

        <Card title="款式图片" className="card-box">
          <div className="top-box">
            <p>
              原图 <span className='red'>{initInfo?.style_imgs?.img_total} 张</span>，
              待生成 <span className='red'>{initInfo?.style_imgs?.wait_total} 张</span>，
              已生成 <span className='red'>{initInfo?.style_imgs?.finish_total} 张</span>，
              已选最佳/精修 <span className='red'>{initInfo?.style_imgs?.best_or_refine_total} 张</span>
            </p>

            <div className='user-items'>
              <Button className='user-type user-type6' onClick={() => this.onDownloadAllClick(true)}>下载全部高清最佳图</Button>
              <Button className='user-type user-type6' onClick={() => this.onDownloadAllClick(false)}>下载全部最佳图</Button>
              <Button className='user-type user-type6' onClick={() => this.onBatchBuildClick()}>批量AI生成</Button>
              <Upload
                id = 'upload_elm'
                name="image"
                listType="text"
                className="img-uploader"
                showUploadList={false}
                multiple={true}
                accept="image/png, image/jpeg, image/jpg"
                action={configs.baseUrl + "/admin/bdorder/style-img-upload"}
                headers={{
                  token: localStorage.getItem('token') || null,
                }}
                data={{style_id: initInfo?.style_id}}
                // fileList={[]}
                beforeUpload={this.beforeUpload}
                onChange={this.handleUploadChange}
              >
                <Button className='user-type user-type4'>批量上传原图</Button>
              </Upload>

            </div>
          </div>
          <Table
            columns={this.state.columns}
            rowKey={(record, index) => {return `${record.id}_${index}`}}
            dataSource={this.state.styleTaskList}
            pagination={false}
            // pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.pageChange}
            locale={{emptyText: '对不起，没有搜到任何内容'}}
          />
        </Card>
      </div>

      {/*图片预览*/}
      {
        previewImgData && <StyleImgPreviewComponent
          data={previewImgData}
          onClose={() => {
            this.setState({previewImgData: null, isRefinePreview: false, initPreviewId: null});
            this.init();
          }}
          onRebuild = {(imgInfo) => this.onAIBuildClick([imgInfo.style_img_id]) }
          isRefine={this.state.isRefinePreview}
          initPreviewId={this.state.initPreviewId}
          orderId={this.state.initInfo.order_id}
          styleId={this.props.match.params.id}
        />
      }

      {/*批量生成弹窗*/}
      <Modal
        className='build-img-modal'
        title={(currentStyleImgIds && currentStyleImgIds.length>0) ? '批量 AI 生成' : 'AI 生成'}
        centered={true}
        visible={buildOptions}
        width={652}
        // height={500}
        okText={'人台生成'}
        cancelText={'取消'}
        onOk={() => this.onBuildBtnClick(2)}
        onCancel={() => this.onBuildModalClose()}
      >
        <Button className='ant-btn-primary build-btn' onClick={() => this.onBuildBtnClick(1)}>真人生成</Button>
        <div className="ai-build-modal" style={{maxHeight: `${screenHeight - 260}px`}}>
          {
            buildOptions?.style_imgs.map((item) => <div key={item.style_img_id} className="item-box">
              <span className="img-box">
                <div className="img-id">原图 ID: {item.style_img_id}</div>
                <img className="gallery-img" src={item?.image} />
              </span>
              <span className="config-info-box">
                <div className="config-item">
                  <span className="config-type">模特</span>
                  <span className={`select-option ${item.style_img_id === this.state.openConfigStyleImgData?.style_img_id && "active"}`} onClick={() => this.onModalSelectModel(item)}>{item.model_id ? this.getModelOptions(item.model_id)?.name : '请选择模特 >'}</span>
                  {
                    buildOptions.style_imgs.length > 1 && <span className="all-btn" onClick={() => this.optionUseAll('model_id', item.model_id)}>模特应用至全部</span>
                  }
                </div>
                <div className="config-item">
                  <span className="config-type">背景风格</span>
                  <span className={`select-option ${item.style_img_id === this.state.openConfigStyleImgData?.style_img_id && "active"}`} onClick={() => this.onModalSelectModel(item)}>
                    {item.background_id ? this.getBgOptions(item.background_id) : '请选择背景 >'}
                  </span>
                  {
                    buildOptions.style_imgs.length > 1 && <span className="all-btn" onClick={() => this.optionUseAll('background_id', item.background_id)}>背景应用至全部</span>
                  }
                </div>
              </span>
            </div>)
          }
        </div>
      </Modal>

      {/*选择配置*/}
      <ConfigOptionsDrawer
        show={openConfigDrawer}
        buildOptions={buildOptions?.build_options}
        imgData={openConfigStyleImgData}
        onClose={() => {
          this.setState({
            openConfigDrawer: false,
            openConfigStyleImgData: null
          });
        }}
        onChange={(changeData) => this.onOptionValuesChange(changeData)}
      />

      {/*上传进度*/}
      {
        this.state.showUploadModal && <ProgressComponent data={{total: this.state.uploadTotal, imgList: this.state.uploadList}} onCancel={() => this.onUploadCancel()} />
      }

      {
        this.state.showBatchSelect && <BatchSelectComponent
          orderId={this.state.initInfo.order_id}
          styleId={this.props.match.params.id}
          onOk={(imgList) => {
            this.getBuildOptions(imgList);
            this.setState({showBatchSelect: false});
          }}
          onClose={() => {this.setState({showBatchSelect: false})}}
        />
      }

      {
        this.state.showHDDownload && <DownloadHDProgressComponent
          orderId={this.state.initInfo.order_id}
          styleId={this.props.match.params.id}
          onClose={() => {this.setState({showHDDownload: false})}}
        />
      }
    </div>
  }



};

const MemberFormWrap = Form.create({name: 'member_form'})(StyleDetail);
export default withRouter((MemberFormWrap));