import React, {Component} from 'react';
import {Input, Form, Icon, message, Button, Table, Popover, Tabs, Modal, Col, Select} from 'antd';
import API from '../../../apis'
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import MyRecharge from './my-recharge';
import './index.less';
import {formatNum} from '../../../utils/helpers';
import {setCurrentMenu} from "../../../store/actions/login";
import MyPackage from './my-package';
import MyTask from './my-task';



const { TabPane } = Tabs;
const {Option} = Select;
const { TextArea } = Input;


class memberDetail extends Component {
  state = {
    initInfo:{},
    markVisible:false,
    visible: false,
  };
  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 18
    },
  };
  formItemLayout1 = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 12
    },
  };
  componentDidMount() {
    this.init();
  }
  init=async()=> {
    if (this.props.match.params.id) {
      const data = await API.getMemberDetail(this.props.match.params.id);
      if (data.code === 0) {
        this.setState({
          initInfo:data.data,
        })
      }
    }
  }
  onCancel = () => {
    this.props.history.goBack();
    //this.props.history.replace('/home/member/member-list');
  };
  onMemberDetail =(id)=>{
   // this.props.setCurrentMenu('用户详情');
    window.location.href = '/home/member/member-list/detail/' + id;
  }
  onBan = async (type) => {
    Modal.confirm({
      title: '禁用提示！',
      content: (
          <div>
            <p>是否禁用该用户？</p>
          </div>
      ),
      okText: '禁用',
      cancelText: '取消',
      onOk: async () => {
        const data = await API.operaMember({
          id:this.props.match.params.id,
          type:type
        });
        if (data.code === 0) {
          this.init();
          message.success('禁用成功');
        }
      },
    });
  };
  onCancelBan = async (type) => {
    const data = await API.operaMember({
      id:this.props.match.params.id,
      type:type
    });
    if (data.code === 0) {
      this.init();
      message.success('启用成功');
    }
  };
  //用户备注
  showMark = () => {
    this.setState({
      markVisible: true,
      markText:this.state.initInfo.mark,
    });
  };
  markCancel = e => {
    this.setState({
      markVisible: false,
      markText:'',
    });
  };
  onMark = async () => {
    const fieldsValue = this.props.form.getFieldsValue();
    const params = {
      ...fieldsValue,
      user_id:this.props.match.params.id,
    }
    let data = await API.onMemberMark(params);
    if (data.code === 0) {
      this.props.form.resetFields();
      this.init();
      message.success('备注已保存');
      this.setState({
        markVisible: false,
        markText:'',
      })
    }
  };

  // 编辑按钮点击
  memberEditClick = () => {
    this.props.history.push('/home/member/member-edit/' + this.state.initInfo.id);
  }

  showGrantModal = () => {
    this.setState({
      visible: true
    });
  };

  onGrant = async () => {
    const fieldsValue = this.props.form.getFieldsValue();
    const params = {
      ...fieldsValue,
      id: this.state.initInfo.id,
    }
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let res = await API.grant(params);
        if (res.code === 0) {
          message.success('发放成功');
          this.setState({
            visible: false,
          });
          this.props.form.setFieldsValue({
            "coin": null
          })
          this.init();
        }
      } else {
        message.error('请完善表单');
      }
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
    this.props.form.setFieldsValue({
      "coin": null
    })
  };

  render() {
    const {initInfo, markVisible} = this.state;
    const {getFieldDecorator} = this.props.form;
    return <div>
      <div className='header'>
        <Button type="link" onClick={this.onCancel}><Icon type="left" className='back-btn'/>返回</Button>
        <div className='user-items'>
          <Button className='user-type user-type4' onClick={this.showGrantModal}>发放</Button>
          <Button className='user-type user-type4' onClick={this.memberEditClick}>编辑</Button>
          {
            initInfo.status === 0
                ? <Button className='user-type user-type2' onClick={this.onCancelBan.bind(this,1)}>账号禁用</Button>
                : <Button className='user-type user-type3' onClick={this.onBan.bind(this,1)}>账号启用</Button>
          }
        </div>
      </div>
      <div className='content'>
        <div className='info-items'>
          <span><label>ID：</label>{initInfo.id}</span>
          <span><label>昵称：</label>{initInfo.nickname}</span>
          <span><label>注册手机：</label>{initInfo.mobile}</span>
          <span><label>注册时间：</label>{initInfo.create_time}</span>
          <span><label>关联BD：</label>{initInfo.bd_name}</span>
          <span><label>M豆余额：</label><span className='red'>{formatNum(initInfo.coin)}</span></span>
        </div>
        <div className='info-items mark-item'>
          <label>备注：</label>
          <div >{initInfo.mark}{initInfo.mark &&<span style={{color: 'rgba(0, 0, 0, 0.45)'}}>（{initInfo.mark_time} 更新）</span>}<Icon type="form"  style={{color:'#40a9ff'}} onClick={this.showMark.bind()}/></div>
        </div>
        <Tabs defaultActiveKey="1" >
          <TabPane tab="支付记录" key="1">
            <MyRecharge />
          </TabPane>
          <TabPane tab="套餐详情" key="2">
            <MyPackage />
          </TabPane>
          <TabPane tab="图片生成记录" key="3">
            <MyTask />
          </TabPane>
        </Tabs>
      </div>
      <Modal
          title='备注'
          className='order-madal'
          visible={markVisible}
          onCancel={this.markCancel}
          width={600}
          onOk={this.onMark}
          okText="保存"
          cancelText="取消"
      >
        <Form>
          <Form.Item>
            {getFieldDecorator('mark', {
              initialValue: this.state.markText || '',
              rules: [{
                required: false,
                message: '请输入备注，限100字',
              }]
            })(
                <TextArea rows={4} maxLength={100} defaultValue={this.state.markText}/>
            )}
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="发放"
        visible={this.state.visible}
        onCancel={this.handleCancel}
        onOk={() => this.onGrant()}
        okText="发放"
        cancelText="取消"
        width={600}
      >
        <Form {...this.formItemLayout} >
          <Form.Item label='用户ID'>
            <Col span={6}>
              <Form.Item>
                {initInfo.id}
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item label='注册手机' {...this.formItemLayout1} >
                <span className='ellipsis-items'>{initInfo.mobile}</span>
              </Form.Item>
            </Col>
          </Form.Item>
          <Form.Item label='M豆数量' extra="注：发放的M豆将作为一个单独的30天套餐发放至用户账户中">
            {getFieldDecorator('coin', {
              rules: [{required: true, message: '仅允许输入数字'}]
            })(
              <Input placeholder='仅允许输入数字'/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  }
};

const MemberFormWrap = Form.create({name: 'member_form'})(memberDetail);
export default withRouter((MemberFormWrap));