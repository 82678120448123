import request from '../utils/request';

export default {
  //运营参数配置详情
  getOperateDetail: () => request('/admin/config/info'),
  // 运营参数配置
  configOperate: (info) => request('/admin/config/update', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  //数据统计
  getDataDetail: (info) => request('/admin/statistics/index', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  //支付方式列表
  getPayType: () => request('/admin/order/get-pay-type'),

  //推广渠道列表
  getChannelList: (info) => request('/admin/channel/search', {
    method: 'POST',
    data: {
      ...info
    }
  }),
  //推广渠道详情
  getChannelDetail: (id) => request('/admin/channel/info', {
    method: 'POST',
    data: {
      id
    }
  }),
  //渠道保存
  saveChannel: (info) => request('/admin/channel/save', {
    method: 'POST',
    data: {
      ...info
    }
  }),
  //渠道删除
  deleteChannel: (id) => request('/admin/channel/del', {
    method: 'POST',
    data: {
      id
    }
  }),
  //渠道统计数据
  lookChannel: (id, page) => request('/admin/channel/view', {
    method: 'POST',
    data: {
      id, page
    }
  }),
}