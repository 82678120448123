import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import './index.less';
import API from "../../apis";
import {message} from "antd";
import DownloadHDProgressComponent from "../download-hd-progress";


const StyleImgPreviewComponent = ({data, onClose, onRebuild, isRefine, initPreviewId, orderId, styleId}) => {


  const [imgInfo, setImgInfo] = useState(null);
  const [outputList, setOutputList] = useState(null);
  const [imgIndex, setImgIndex] = useState(0);
  const [mainImgSize, setMainImgSize] = useState(null);
  const [bestImgIndex, setBestImgIndex] = useState(null);
  const [currentImgSize, setCurrentImgSize] = useState(null);
  const [downloadHDImgId, setDownloadHDImgId] = useState(null);

  useEffect(() => {
    // console.log('data.......', data);
    const newData = JSON.parse(JSON.stringify(data));
    setImgInfo(newData);
    setImgIndex(0);
    if (data) {
      const newOutputList = handleOutputList(newData.task_list);
      setOutputList(newOutputList);
      getInitImgIndex(newOutputList);
    }
    getImgSize(data.gallery_image.image);
  },[data])

  useEffect(() => {
    if (imgInfo) {
      setBestImgIndex(getBestImgIndex());
    }
  },[imgInfo])

  useEffect(() => {
    if (imgInfo) {
      setCurrentImgSize(getOtherImgSize(imgIndex));
    }
  },[imgIndex, imgInfo])

  const getInitImgIndex = (list) => {
    (list || []).map((item, index) => {
      if (item.image === initPreviewId) {
        setImgIndex(index);
      }
    });
  }

  const handleOutputList = (taskList) => {
    let resList = [];
    (taskList || []).map(item => {
      resList = [...resList, ...item.outputs];
    })
    // console.log('list.......', resList);
    return resList;
  }

  // 弹窗取消按钮点击
  const onCancelClick = () => {
    setImgInfo({
      ...imgInfo,
      show: false
    })

    if (imgInfo.cancelFunc) {
      imgInfo.cancelFunc();
    }
  }

  const getBestImgIndex = () => {
    if (!imgInfo.best_gallery_image) {
      return null;
    }
    let i = null;
    imgInfo.outputs.map((item, index) => {
      if (imgInfo.best_gallery_image.image === item.image) {
        i = index;
      }
    })

    return i;
  }

  const onImgPreClick = (e) => {
    //阻止事件冒泡即可
    e.stopPropagation();
    const index = imgIndex <= 0 ? outputList.length-1 : imgIndex - 1;
    setImgIndex(index);
  }

  const onImgNextClick = (e) => {
    //阻止事件冒泡即可
    e.stopPropagation();
    const index = imgIndex >= outputList.length-1 ? 0 : imgIndex + 1;
    setImgIndex(index);
  }

  const onCloseBtnClick = () => {
    if (onClose) {
      onClose();
    }
  }

  // 下载精修
  const downloadHD = (e, galleryId) => {
    //阻止事件冒泡即可
    e.stopPropagation();
    setDownloadHDImgId(galleryId);
  }

  // 下载图片
  const downFiles = (e, downloadUrl) => {
    //阻止事件冒泡即可
    e.stopPropagation();

    const x = new XMLHttpRequest();
    const resourceUrl = downloadUrl;
    x.open('GET', resourceUrl, true);
    x.responseType = 'blob';
    x.onload = () => {
      const urls = window.URL.createObjectURL(x.response);
      const a = document.createElement('a');
      a.href = urls;
      a.download = 'mix_model_img.jpg';
      a.click();
    };
    x.send();
  }

  // 重新生成
  const onRebuildClick = (e) => {
    //阻止事件冒泡即可
    e.stopPropagation();
    if (onRebuild) {
      onRebuild(imgInfo);
    }
  }

  // 设为最佳
  const onBestClick = async (e, bestOutput) => {
    //阻止事件冒泡即可
    e.stopPropagation();
    const params = {
      gallery_id: outputList[imgIndex].gallery_id,
      style_img_id: imgInfo.style_img_id
    };
    const res = await API.setBest(params);
    if (res.code === 0) {
      let newImgInfo = JSON.parse(JSON.stringify(imgInfo));
      newImgInfo.best_gallery_image = bestOutput;
      setImgInfo(newImgInfo);
      message.success('设置成功');
      // this.init();
    }
  }

  // 取消最佳
  const onCancelBestClick = async (e) => {
    //阻止事件冒泡即可
    e.stopPropagation();
    const params = {
      gallery_id: 0,
      style_img_id: imgInfo.style_img_id
    };
    const res = await API.setBest(params);
    if (res.code === 0) {
      let newImgInfo = JSON.parse(JSON.stringify(imgInfo));
      newImgInfo.best_gallery_image = null;
      setImgInfo(newImgInfo);
      message.success('取消成功');
    }
  }

  // 获取图片尺寸
  const getImgSize = (url) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setMainImgSize({
        width: img.width,
        height: img.height
      });
    }
  }

  // 获取图片尺寸
  const getOtherImgSize = (index) => {
    if (!outputList || outputList.length === 0) {
      return null;
    }
    const url = outputList[index].image;
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setCurrentImgSize({
        width: img.width,
        height: img.height
      });
    }
  }

  return (<div className={`style-img-preview-component`} onClick={onCloseBtnClick}>
    <div className="img-preview-body-container">
      <img className="close-btn" src={ require('../../assets/images/close-btn.svg') } onClick={onCloseBtnClick}/>
      <div className="left-box">
        <div className="sub-title" onClick={(e) => e.stopPropagation()}>原图 ID：{imgInfo?.style_img_id}</div>
        {
          (imgInfo && imgInfo.gallery_image) && <img className="primary-img" src={ imgInfo.gallery_image.image } onClick={(e) => e.stopPropagation()}/>
        }
        <div className="bottom-box">
          <div className="down-btn" onClick={(e) => {downFiles(e, imgInfo.gallery_image.image)}}>下载</div>
        </div>
      </div>

      <div className={`right-box ${(!outputList || outputList.length===0) && 'hide'}`}>
        {
          !isRefine && <div className="top-box">
            <div className="sub-title" onClick={(e) => e.stopPropagation()}>
              已生成 {imgIndex + 1} / {outputList ? outputList.length : 0}
              <span>
              {/*{ imgInfo && imgInfo.best_gallery_image ? '（已设置最佳）' : <span className="status-select-best">（请选择最佳图片）</span> }*/}
            </span>
            </div>

          </div>
        }
        {
          isRefine && <div className="top-box">
            <div className="sub-title" onClick={(e) => e.stopPropagation()}>
              精修图
            </div>
          </div>
        }

        {
          !isRefine && outputList && <span className="right-img-box">
            <img className="img" src={ outputList[imgIndex]?.image } onClick={(e) => e.stopPropagation()} />
            {
              !isRefine && imgInfo && imgInfo.best_gallery_image && imgInfo.best_gallery_image.image === outputList[imgIndex].image &&  <span className="best-tag">最佳</span>
            }
            {
              !isRefine && <span className={`btn left-btn show`} onClick={onImgPreClick}></span>
            }
            {
              !isRefine && <span className={`btn right-btn show`} onClick={onImgNextClick}></span>
            }
            {
              !isRefine && <span className="num-box">{imgIndex + 1}</span>
            }

            <span className="best-btn-box">
            {
              !isRefine && imgInfo && imgInfo.best_gallery_image && imgInfo.best_gallery_image.image === outputList[imgIndex].image
                ? <span className="cancel-best" onClick={(e) => onCancelBestClick(e)}>
                  <div>取消最佳</div>
                  <div className="current-best-box">当前最佳&nbsp;<span className="best-num">{bestImgIndex+1}</span></div>
                </span>
                : ( !isRefine &&
                  <span className="option-btn" onClick={(e) => onBestClick(e, outputList[imgIndex])}>
                    <div>设为最佳</div>
                      {
                        bestImgIndex !== null && <div className="current-best-box">当前最佳&nbsp;<span className="best-num">{bestImgIndex+1}</span></div>
                      }
                  </span>
                  )
            }
            </span>
          </span>
        }

        {
          isRefine && imgInfo?.refine_gallery_image && <span className="right-img-box">
            <img className="img" style={{height: mainImgSize?.height}} src={ imgInfo.refine_gallery_image.image } onClick={(e) => e.stopPropagation()} />
          </span>
        }
        <div className="bottom-box">
          <span className="bottom-left-box">
            <div className="down-btn" onClick={(e) => {downFiles(e, outputList[imgIndex].image)}}>下载</div>
            {
              !isRefine && <div className="down-hd-btn" onClick={(e) => {downloadHD(e, outputList[imgIndex].gallery_id)}}>
                <span>下载高清</span>
                {
                  currentImgSize && <span className="hd-size">{currentImgSize.width*2} × {currentImgSize.height*2}</span>
                }
              </div>
            }
          </span>
          <div className="top-btn-box">
            {/*<span className="rebuild-btn2" onClick={(e) => onRebuildClick(e)}>再次生成</span>*/}
            {
              !isRefine && imgInfo && imgInfo.best_gallery_image && imgInfo.best_gallery_image.image === outputList[imgIndex].image
                ? <span className="cancel-best" onClick={(e) => onCancelBestClick(e)}>
                  <div>取消最佳</div>
                  <div className="current-best-box">当前最佳&nbsp;<span className="best-num">{bestImgIndex+1}</span></div>
                </span>
                : ( !isRefine &&
                  <span className="option-btn" onClick={(e) => onBestClick(e, outputList[imgIndex])}>
                    <div>设为最佳</div>
                      {
                        bestImgIndex !== null && <div className="current-best-box">当前最佳&nbsp;<span className="best-num">{bestImgIndex+1}</span></div>
                      }
                  </span>
                  )
            }
          </div>
        </div>
      </div>
    </div>

    {
      downloadHDImgId && <DownloadHDProgressComponent
        orderId={orderId}
        styleId={styleId}
        galleryId={downloadHDImgId}
        onClose={() => {setDownloadHDImgId(null)}}
      />
    }
  </div>)
};

export default withRouter(StyleImgPreviewComponent);
