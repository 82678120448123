import {fromJS,} from 'immutable';
import {handleActions} from 'redux-actions';
import {
  SET_MEMBER_PARMAM, SET_ORDER_PARMAM, SET_TASK_PARMAM
} from '../actions/operate';

const initialState = fromJS({
  //充值记录参数
  orderParam: {
    member_id: '',
    mobile: '',
    order_no: '',
    pay_type: '',
    start_time: '',
    end_time: '',
  },
  //会员参数
  memberParam: {
    mobile: '',
    status: '',
    start_time: '',
    end_time: '',
    id: '',
    pay_min_money: '',
    pay_max_money: '',
    mark: '',
  },
  //生成参数
  taskParam: {
    member_id: '',
    mobile: '',
    image_type: '',
    status: '',
    start_time: '',
    end_time: '',
    task_id: '',
  },
});

export const operate = handleActions(
    {
      [SET_ORDER_PARMAM]: (state, action) => {
        return state.set('orderParam', action.payload)
      },
      [SET_MEMBER_PARMAM]: (state, action) => {
        return state.set('memberParam', action.payload)
      },
      [SET_TASK_PARMAM]: (state, action) => {
        return state.set('taskParam', action.payload)
      },
    },
    initialState
);

