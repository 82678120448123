import React, {Component} from 'react';
import {Input, Button, Row, Col, Form, Radio, DatePicker, message} from 'antd/lib/index';
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import {getPersonal,updatePersonal} from "../../../../store/actions/admin";
import moment from 'moment';

class PersonalCenter extends Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const payload = {
          ...values,
        };
        let res = await updatePersonal(payload);
        if (res.code === 0) {
          message.success(res.msg|| '保存成功', 1, () => {
            //  成功后要做什么
          });
        } else {
          message.error(res.msg);
        }
      }
    });
  };
  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16
    },
  };

  componentDidMount() {
    this.props.getPersonal();
  }


  render() {
    const {getFieldDecorator} = this.props.form;
    const initInfo = this.props.personalInfo || {};
    return <div>
      <Row type="flex" justify="space-around">
        <Col span={22}>
          <Form {...this.formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label='用户名'>
              {getFieldDecorator('user_login', {
                initialValue: initInfo.user_login,
                rules: [{type: 'string'}]
              })(
                <Input placeholder='请输入用户名'/>
              )}
            </Form.Item>
            <Form.Item label='邮箱'>
              {getFieldDecorator('user_email', {
                initialValue: initInfo.user_email,
                rules: [{type: 'string'}]
              })(
                <Input placeholder='请输入邮箱'/>
              )}
            </Form.Item>
            <Form.Item label='手机'>
              {getFieldDecorator('user_mobile', {
                initialValue: initInfo.user_mobile,
                rules: [{type: 'string'}]
              })(
                <Input placeholder='请输入手机'/>
              )}
            </Form.Item>
            <Form.Item>
              <Row gutter={10}>
                <Col span={2} offset={6}>
                  <Button type="primary"  htmlType="submit">
                    保存
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>

    </div>
  }
}
// 将action注入到页面组件
const matDispatchToProps = {
  getPersonal
};

// 将store注入到页面组件
function mapStateToProps(state) {
  return {
    personalInfo: state.getIn(['admin', 'personalInfo'], {}),
  }
}
const PersonalFormWrap = Form.create({ name: 'personal_form' })(PersonalCenter);

export default withRouter(connect(mapStateToProps, matDispatchToProps)(PersonalFormWrap));


