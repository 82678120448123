import { createAction } from 'redux-actions'
//充值参数
export const SET_ORDER_PARMAM = 'SET_ORDER_PARMAM';

//会员参数
export const SET_MEMBER_PARMAM = 'SET_MEMBER_PARMAM';

//生成记录参数
export const SET_TASK_PARMAM = 'SET_TASK_PARMAM';

//推广渠道参数
export const SET_CHANNEL_PARMAM = 'SET_CHANNEL_PARMAM';

export const setTaskParam = createAction(SET_TASK_PARMAM, params => params);
export const setOrderParam = createAction(SET_ORDER_PARMAM, params => params);
export const setMemberParam = createAction(SET_MEMBER_PARMAM, params => params);
export const setChannelParam = createAction(SET_CHANNEL_PARMAM, params => params);