import React, {Component} from 'react';
import {Table, Tag, Row, Col, Modal} from 'antd';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {fetchRoleList, deleteRole} from "../../../../store/actions/admin";


class roleList extends Component {

  state = {
    // 过滤条件
    filterConditions: {},
  };

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: text => <a href="javascript:;">{text}</a>,
    },
    {
      title: '角色名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '角色描述',
      key: 'remark',
      dataIndex: 'remark',
    },

    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        switch (status) {
          case 0:
            return <Tag color="#f50">已禁用</Tag>;
          case 1:
            return <Tag color="#87d068">已启用</Tag>;
        }
      },
    },
    {
      title: '操作',
      key: 'action',
      render: (text, record) => {
        if (record.id != 1) {
          return <span>
              <Tag color="#108ee9" style={{cursor:'pointer'}} onClick={() => this.openPermissionSetting(record.id)}>权限设置</Tag>
              <Tag color="#108ee9" style={{cursor:'pointer'}} onClick={this.onEdit.bind(this, record.id)}>编辑</Tag>
              <Tag color="red" style={{cursor:'pointer'}} onClick={this.onDelete.bind(this, record.id, record.name)}>删除</Tag>
            </span>
        }
      },
    }
  ];
  onEdit = (id) => {
    this.props.history.push('/home/account/role-list/edit/' + id);
  };
  openPermissionSetting = (id) => {
    this.props.history.push('/home/account/role-list/permission-setting/' + id);
  };
  onDelete = (id, name) => {
    Modal.confirm({
      title: '删除提示！',
      content: (
        <div>
          <p>您是否删除:{name}？</p>
        </div>
      ),
      okText: '删除',
      cancelText: '取消',
      onOk: async () => {
        const res =  await this.props.deleteRole(id);
        if (res.payload.code === 0) {
          this.init();
        } else {

        }
      },
      onCancel: () => {
        this.setState({
          visible: false,
        })
      },
    });
  };

  init() {
    this.props.fetchRoleList();
  }

  componentDidMount() {
    this.init();
  }

  render() {
    return <div>
      <Row type="flex" justify="space-around">
        <Col span={22}>
          <Table columns={this.columns}
                 dataSource={this.props.roleList}
                 rowKey="id"
                 loading={this.props.roleList == null}
                 locale={{emptyText: '对不起，没有搜到任何内容'}}
          />
        </Col>
      </Row>

    </div>
  }
}

// 将action注入到页面组件
const matDispatchToProps = {
  fetchRoleList, deleteRole
};

// 将store注入到页面组件
function mapStateToProps(state) {
  return {
    roleList: state.getIn(['admin', 'roleList'], null),
  }
}

export default withRouter(connect(mapStateToProps, matDispatchToProps)(roleList));
