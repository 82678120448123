import React, {Component} from 'react';
import {Layout, Breadcrumb, Icon, message,Menu} from 'antd';
import { Route, Switch, Redirect} from "react-router-dom";

import SideMenu from './components/side-menu'
import HeaderRight from './components/header-right'

import Welcome from  './pages/public/welcome'
import Role from './pages/userManage/roles'
import RoleEdit from './pages/userManage/roles/roleEdit'
import RoleAdd from './pages/userManage/roles/roleAdd'
import RolePermissions from './pages/userManage/roles/rolePermissions'
import Admin from './pages/userManage/users'
import AdminEdit from './pages/userManage/users/adminEdit'
import AdminAdd from './pages/userManage/users/adminAdd'
import Personal from './pages/userManage/person'
import Statistics from './pages/operateManage/statistics'
import Config from './pages/operateManage/config'
import Member from './pages/memberManage/members'
import MemberDetail from  './pages/memberManage/detail'
import MemberAdd from "./pages/memberManage/memberAdd";
import MemberEdit from "./pages/memberManage/memberEdit";
import Deposit from './pages/depositManage/deposit'
import OrderList from "./pages/orderManage/orderList";
import OrderAdd from "./pages/orderManage/orderAdd";
import OrderEdit from "./pages/orderManage/orderEdit";
import OrderDetail from "./pages/orderManage/orderDetail";
import StyleDetail from "./pages/orderManage/styleDetail";
import Task from './pages/operateManage/task'
import Channel from './pages/operateManage/channel/search'
import ChannelInfo from './pages/operateManage/channel/info'
import ChannelView from './pages/operateManage/channel/view'
import ModelList from "./pages/modelManage/modelList";
import ModelEdit from "./pages/modelManage/modelEdit";
import BackgroundList from "./pages/backgroundManage/backgroundList";
import BackgroundEdit from "./pages/backgroundManage/backgroundEdit";

import './App.less';
import {withRouter} from 'react-router-dom';
import {doLogout} from "./store/actions/login";
import {getUserMenusList} from "./store/actions/admin";

import {connect} from "react-redux";

import API from './apis'

const {Header, Content} = Layout;

class Home extends Component {

  state = {
    collapsed: false,
    openKeys: ['sub1'],
    onlineNumber:''
  };


  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  };
  handleLogout = async () => {
    //调用action
    const res = await doLogout();
    //等待返回，然后判断如果code为0就是后端返回成功
    if (res.code === 0) {
      //接下来弹出提示
      message.success(res.msg || '退出登录成功', 1, () => {
        //最后清除token，跳转到登录页面
        localStorage.removeItem('token');
        window.location.href = '/login';
      })
    }
  };

  // buildBreadcrumb = () => {
  //   const pathname = this.props.location.pathname;
  //   const pathWords = pathname.split('/').filter(i => i);
  //   const result = [];
  //   pathWords.reduce((pre, cur) => {
  //     const p = pre + '/' + cur;
  //     if (this.routeMatchs[p]) {
  //       result.push(
  //         <Breadcrumb.Item key={p}>
  //           {/*<Link to={p}>{this.routeMatchs[p]}</Link>*/}
  //           <span>{this.routeMatchs[p]}</span>
  //         </Breadcrumb.Item>,
  //       );
  //     }

  //     return p;
  //   }, '');

  //   return result;
  // };

  componentDidMount = async()=> {
    this.props.getUserMenusList();

    const data = await API.getOnlineNum();
    if (data.code === 0) {
      this.setState({
        onlineNumber:data.data.online
      })
    }
  }
  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };
  render() {
    const {collapsed} = this.state;
    return (
      <Layout className="App">
        <SideMenu collapsed={collapsed}/>
        <Layout>
          <Header style={{background: '#21989E', padding: 0}}>
            <span className='header-index-left'>
              <span className='memu-item'>{this.props.currentMenu}</span>
              <span className='num-item'>当前在线用户：{this.state.onlineNumber}</span>
            </span>
            <span className='header-index-right'>
                <HeaderRight
                  handleLogout={this.handleLogout}
                />
              </span>
          </Header>
          <Content
            className="main-content"
          >
            <Switch>
              {/*运营管理*/}
              <Route path='/home/operate/statistics' component={Statistics}/>
              <Route path='/home/operate/config' component={Config}/>
              <Route path='/home/operate/channel/list' component={Channel}/>
              <Route path='/home/operate/channel/add' component={ChannelInfo}/>
              <Route path='/home/operate/channel/edit/:id' component={ChannelInfo}/>
              <Route path='/home/operate/channel/look/:id' component={ChannelView}/>
              <Route path='/home/operate/task' component={Task}/>
              <Redirect from='/home/operate' to='/home/operate/statistics' />

              {/*财务管理*/}
              <Route path='/home/deposit/deposit-list' component={Deposit}/>
              <Redirect from='/home/deposit' to='/home/deposit/deposit-list' />

              {/*用户管理*/}
              <Route path='/home/member/member-list/detail/:id' component={MemberDetail}/>
              <Route path='/home/member/member-list' component={Member}/>
              <Route path='/home/member/member-add' component={MemberAdd}/>
              <Route path='/home/member/member-edit/:id' component={MemberEdit}/>
              <Redirect from='/home/member' to='/home/user/member-list' />

              <Route path='/home/model/model-edit/:id' component={ModelEdit}/>
              <Route path='/home/model/model-list' component={ModelList}/>
              <Redirect from='/home/model' to='/home/model/model-list' />

              <Route path='/home/background/background-edit/:id' component={BackgroundEdit}/>
              <Route path='/home/background/background-list' component={BackgroundList}/>
              <Redirect from='/home/background' to='/home/background/background-list' />

              {/*用戶管理*/}
              <Route path='/home/user/personal' component={Personal}/>
              <Redirect from='/home/user/list' to='/home/user/personal' />

              {/*订单管理*/}
              <Route path='/home/order/order-add' component={OrderAdd}/>
              <Route path='/home/order/order-list' component={OrderList}/>
              <Route path='/home/order/order-edit/:id' component={OrderEdit}/>
              <Route path='/home/order/order-detail/:id' component={OrderDetail}/>
              <Route path='/home/order/style-detail/:id' component={StyleDetail}/>
              <Redirect from='/home/order' to='/home/order/order-list' />

              {/*账号管理*/}
              <Route path='/home/account/role-list/permission-setting/:id' component={RolePermissions}/>
              <Route path='/home/account/role-list/edit/:id' component={RoleEdit}/>
              <Route path='/home/account/role-list/add' component={RoleAdd}/>
              <Route path='/home/account/role-list' component={Role}/>

              <Route path='/home/account/admin-list/edit/:id' component={AdminEdit}/>
              <Route path='/home/account/admin-list/add' component={AdminAdd}/>
              <Route path='/home/account/admin-list' component={Admin}/>
              <Redirect from='/home/account' to='/home/account/admin-list' />
              <Redirect from='/home/account/admin-list' to='/home/account/admin-list' />
              <Redirect from='/home/account/role-list' to='/home/account/role-list' />




              {/*'/home'默认跳转*/}
            {/*  <Redirect to='/home/operate/statistics'/>*/}

              {/*欢迎页面*/}
              <Route path='/home/welcome' component={Welcome}/>
              <Redirect  to='/home/Welcome' />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const matDispatchToProps = {
  doLogout, getUserMenusList
};

function mapStateToProps(state) {
  return {
    userMenusList: state.getIn(['admin', 'userMenusList'], []),
    verifyCode: state.getIn(['global', 'message']),
    currentMenu: state.getIn(['global', 'currentMenuConfig'], '-')
  }
}


export default withRouter(connect(mapStateToProps, matDispatchToProps)(Home));

