import React, {Component} from 'react';
import {Card, Col, DatePicker, Descriptions, Row, Spin} from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import API from '../../../apis'
import moment from 'moment';
import {formatNum} from "../../../utils/helpers";

const {RangePicker} = DatePicker;

class Statistics extends Component {
  state = {
    data: {},
    param: {
      start_time: '',
      end_time: '',
    },
    spinning: false
  };

  callback(key) {
  }

  componentDidMount() {
    this.init();
  }

  onChange = (date, dateString) => {
    this.setState({
      spinning: true
    })
    this.setState({
      param: {
        start_time: dateString[0],
        end_time: dateString[1],
      }
    }, () => {
      this.init();
    })
  }
  init = async () => {
    const data = await API.getDataDetail(this.state.param);
    this.setState({
      spinning: false
    })
    if (data.code === 0) {
      this.setState({
        data: data.data,
      })
    }
  }

  disabledDate(current) {
    return current && current > moment().endOf('day');
  }

  render() {
    let {data} = this.state;
    return <div className='content'>
      <Row type="flex" justify="space-around">
        <Col span={22}>
          <div className="mb20">
            <label>统计时间:&nbsp;&nbsp;</label>
            <RangePicker
                locale={locale}
                onChange={this.onChange.bind()}
                defaultValue={[moment(), moment()]}
                disabledDate={this.disabledDate}
            />
          </div>
          <Spin tip="数据加载中..." size="large" spinning={this.state.spinning}>
            {
              Object.keys(data).map((tab_name,tab_index) => (
                  <Card title={data[tab_name].name}>
                    <Descriptions>
                      {
                        Object.keys(data[tab_name].value).map((label_name,label_index) => (
                          <Descriptions.Item 
                            label={data[tab_name].value[label_name].name}>
                            {data[tab_name].value[label_name].prefix}
                            {formatNum(data[tab_name].value[label_name].value)}
                            {data[tab_name].value[label_name].suffix}
                          </Descriptions.Item>
                        ))
                      }
                    </Descriptions>
                  </Card>
              ))
            }
          </Spin>
        </Col>
      </Row>
    </div>
  }
};


export default Statistics;
