import React, {Component} from 'react';
import {Table, Card, Select, Button, Input, DatePicker} from 'antd';
import API from "../../../apis";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from 'moment';
import {connect} from "react-redux";
import {setTaskParam} from "../../../store/actions/operate";
import {setCurrentMenu} from "../../../store/actions/login";
import {formatNum} from "../../../utils/helpers";
import ImgPreview from '../../../components/img-preview';

const { RangePicker } = DatePicker;
const {Option} = Select;

class Task extends Component { 
  constructor(props) {
    super(props);
    if (typeof props.taskParam.toJSON === 'function') {
      this.state.filterConditions = props.taskParam.toJSON();
    } else {
      this.state.filterConditions = props.taskParam;
    }
  }
  state = {
    columns: [
      {
        title: '生成ID',
        dataIndex: 'task_id',
        key: 'task_id',
        width: '8%',
      },
      {
        title: '生成时间',
        dataIndex: 'build_time',
        key: 'build_time',
        width: '8%',
        render: text => <div className='time-item'>{text}</div>,
      },
      {
        title: '用户ID',
        dataIndex: 'member_id',
        key: 'member_id',
        width: '8%',
        render: text => <a onClick={this.onMemberDetail.bind(this, text)}>{text}</a>,
      },
      {
        title: '使用场景',
        dataIndex: 'image_type_name',
        key: 'image_type_name',
        width: '8%',
      },
      {
        title: '上传图片',
        dataIndex: 'upload_image',
        key: 'upload_image',
        width: '8%',
        render: (text, record) => <img src={text} style={{width:'30px', cursor: 'pointer'}} onClick={() => this.onImgClick(record)}/>,
      },
      {
        title: '图片设定',
        dataIndex: 'task_setting',
        key: 'task_setting',
        render: text => <div dangerouslySetInnerHTML={{__html: text}}></div>,
      },
      {
        title: '生成方式',
        dataIndex: 'build_type_name',
        key: 'build_type_name',
        width: '8%',
      },
      {
        title: '消耗M豆',
        key: 'cost',
        dataIndex: 'cost',
        width: '8%',
        render: text => <span>{formatNum(text)}</span>
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        width: '8%',
        render: (status, record) => {
          switch (status) {
            case -1:
              return <span style={{color:"#FF0000"}}>{record.status_name}</span>;
            case 3:
              return <span style={{color:"#70B603"}}>{record.status_name}</span>;
            default:
              return <span>{record.status_name}</span>;
          }
        },
      },
      {
        title: '生成图片',
        key: 'outputs',
        dataIndex: 'outputs',
        width: '15%',
        render: (outputs, record) => {
          if(record.status == 3){
            return <div style={{cursor: 'pointer'}} onClick={() => this.onImgClick(record)}>
                {outputs?.map((name,value) => (
                  <img src={name} style={{marginLeft:'3px',width:'30px'}}/>
                ))}
                </div>;
          }else{
            return '-';
          }
        }
      },
    ],
    // 过滤条件
    filterConditions: {
      member_id: '',
      mobile: '',
      status: '',
      start_time: '',
      end_time: '',
      task_id: '',
    },
    pagination: {
      total: 0,
      current: 1,
      pageSize: 20,
    },
    initList:[],
    loading: false,
    imgPreviewInfo: null,
    statusList: {
      status: [],
      image_type: [],
      model: [],
      background_option: []
    },
  };

  onMemberDetail =(id)=>{
    //this.props.setCurrentMenu('用户详情');
    this.props.history.push('/home/member/member-list/detail/' + id);
  }
  onChange=(date, dateString) =>{
    this.setState({
      filterConditions:{
        ...this.state.filterConditions,
        start_time:dateString[0],
        end_time:dateString[1],
      }
    })
  };
  feach_keyword = (value, type) => {
    this.setState({
      filterConditions: {
        ...this.state.filterConditions,
        [type]: value.target.value,
      },
    })
  };
  handleChange = (value, type) => {
    this.setState({
      filterConditions: {
        ...this.state.filterConditions,
        [type]: value,
      },
    })
  };
  onSearch = () => {
    this.init(1);
  };
  onEmpty = () => {
    this.setState({
      filterConditions: {
        member_id: '',
        mobile: '',
        status: '',
        start_time: '',
        end_time: '',
        task_id: '',
      },
    }, () => {
      this.init(1);
    });
  };
  init=async(page)=> {
    this.setState({loading: true});
    const getOptions = await API.task_get_options();
    if (getOptions.code === 0) {
      this.setState({
        statusList: {
          status: getOptions?.data.status,
          image_type: getOptions?.data.image_type,
          model: getOptions?.data.model,
          background_option: getOptions?.data.background_option
        },
      })
    }

    const data = await API.task_search(
      {
        ...this.state.filterConditions,
        //优先用参数中的page，如果是初始化列表的话，用store中的page，也就是记录下来的页码
        page: page || this.props.taskParam.page
      }
    );
    if (data.code === 0) {
      this.props.setTaskParam({
        ...this.state.filterConditions,
        page: page|| this.props.taskParam.page
      });
      this.setState({
        initList:data.data.list,
        loading: false,
        total:data.data.total,
        total_task:data.data.total_task,
        total_member:data.data.total_member,
        total_build_quick:data.data.total_build_quick,
        total_build_order:data.data.total_build_order,
        total_success:data.data.total_success,
        total_error:data.data.total_error,
        total_cost:data.data.total_cost,
        pagination: {
          total: data.data.total,
          current: page || this.props.taskParam.page
        },
      })
    }
  }
  // 当选中某一页时回调
  pageChange = (page) => {
    this.init(page.current);
  };
  componentDidMount = async () => {
    this.init();
  }
  disabledDate(current) {
    return current && current > moment().endOf('day');
  }

  onImgClick = (record) => {
    const data = {
      show: true,
      primaryImgUrl: record.upload_image,
      imgList: record.outputs
    }

    this.setState({
      imgPreviewInfo: data
    });
  }

  render() {
    const {initList,filterConditions, statusList,total,
      total_task,total_member,total_build_quick,total_build_order,total_success,total_error,total_cost,imgPreviewInfo
    } = this.state;
    return <div className='content-tab '>
          <Card
            title="全部"
          >
            <div className='serach-area'>
              <span>
                  <label>生成ID：</label>
                  <Input
                    placeholder="请输入生成ID"
                    value={filterConditions.task_id}
                    onChange={(value) => this.feach_keyword(value, 'task_id')}
                  >
                  </Input>
                </span>
                <span>
                  <label>生成日期：</label>
                   <RangePicker
                     locale={locale}
                     value={[
                       filterConditions.start_time?moment(filterConditions.start_time):null,
                       filterConditions.end_time?moment(filterConditions.end_time):null
                     ]}
                     onChange={this.onChange.bind()}
                     disabledDate={this.disabledDate}
                   />
                </span>
                <span>
                  <label>用户ID：</label>
                  <Input placeholder="请输入用户ID"
                         value={filterConditions.member_id}
                         onChange={(value) => this.feach_keyword(value, 'member_id')}
                  />
                </span>
            </div>
            <div className='serach-area'>
              <span>
                  <label>注册手机：</label>
                  <Input
                    placeholder="请输入注册手机"
                    value={filterConditions.mobile}
                    onChange={(value) => this.feach_keyword(value, 'mobile')}
                  />
                </span>
                <span>
                  <label>使用场景：</label>
                  <Select
                      placeholder="请选择使用场景"
                      value={filterConditions.image_type}
                      onChange={(value) => this.handleChange(value, 'image_type')}
                  >
                    <Option value="">全部</Option>
                    {
                    statusList.image_type.map(({name, value}) => (
                      <Option key={value} value={value}>{name}</Option>
                      ))
                    }
                  </Select>
                </span>
                <span>
                  <label>模特：</label>
                  <Select
                      placeholder="请选择模特"
                      value={filterConditions.model}
                      onChange={(value) => this.handleChange(value, 'model')}
                  >
                    <Option value="">全部</Option>
                    {
                    statusList.model.map(({name, value}) => (
                      <Option key={value} value={value}>{name}</Option>
                      ))
                    }
                  </Select>
                </span>
            </div>
            <div className='serach-area'>
                <span>
                  <label>背景：</label>
                  <Select
                      placeholder="请选择背景"
                      value={filterConditions.background_option}
                      onChange={(value) => this.handleChange(value, 'background_option')}
                  >
                    <Option value="">全部</Option>
                    {
                    statusList.background_option.map(({name, value}) => (
                      <Option key={value} value={value}>{name}</Option>
                      ))
                    }
                  </Select>
                </span>
                <span>
                  {/* 状态(10已预约，11排队中，2处理中，3已完成，-1失败) */}
                  <label>生成状态：</label>
                  <Select
                      placeholder="请选择生成状态"
                      value={filterConditions.status}
                      onChange={(value) => this.handleChange(value, 'status')}
                  >
                    <Option value="">全部</Option>
                    {
                    statusList.status.map(({name, value}) => (
                      <Option key={value} value={value}>{name}</Option>
                      ))
                    }
                  </Select>
                </span>
                <span>
                  <Button type="primary" onClick={this.onSearch}>搜索</Button>
                  <Button type="danger" onClick={this.onEmpty}>清空</Button>
                </span>
            </div>
            <p>
            生成图片 <span className='red'>{total_task}次</span>，
            生成用户 <span className='red'>{total_member}人</span>，
            快速生成 <span className='red'>{total_build_quick}次</span>，
            预约生成 <span className='red'>{total_build_order}次</span>，
            已完成 <span className='red'>{total_success}次</span>，
            失败 <span className='red'>{total_error}次</span>，
            共消耗M豆 <span className='red'>{total_cost}</span>
            </p>
            <Table
              columns={this.state.columns}
              rowKey="id"
              dataSource={initList}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.pageChange}
              locale={{emptyText: '对不起，没有搜到任何内容'}}
            />
          </Card>
          {
            imgPreviewInfo && <ImgPreview data={imgPreviewInfo} onClose={() => this.setState({imgPreviewInfo: null})}/>
          }
    </div>
  }
};
const matDispatchToProps = {
  setTaskParam,setCurrentMenu
};

function mapStateToProps(state) {
  return {
    taskParam: state.getIn(['operate', 'taskParam'], {}),
  }
}
export default connect(mapStateToProps, matDispatchToProps)(Task);