import React from "react";
import {withRouter} from 'react-router-dom'
import {Avatar, Dropdown, Icon, Menu} from "antd";

const HeaderRight = props => {
  const personalInformation = () => {
    props.history.push('/home/user/personal?type=info')
  };
  const changePassword = () => {
    props.history.push('/home/user/personal?type=password')
  };

  const menu = (
    <Menu>
      <Menu.Item>
          <span onClick={personalInformation}>
            <Icon type="user"/>&nbsp;&nbsp;个人信息
          </span>
      </Menu.Item>
      <Menu.Item>
          <span onClick={changePassword}>
            <Icon type="reload"/>&nbsp;&nbsp;密码修改
          </span>
      </Menu.Item>
      <Menu.Item>
         <span  onClick={props.handleLogout}>
          <Icon type="logout"/>&nbsp;&nbsp;退出登录
         </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Avatar src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"/>
      <Dropdown overlay={menu}>
        <span className="ant-dropdown-link">
          {localStorage.getItem('user_login')}<Icon type="down"/>
        </span>
      </Dropdown>
    </>
  )
};

export default withRouter(HeaderRight);
