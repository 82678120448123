import React, {Component} from 'react';
import {Tabs,Button } from 'antd';
import './index.less';

import AdminList from './adminList';


const { TabPane } = Tabs;

class Admin extends Component {

  callback(key) {
  }
  onAdd = () => {
    this.props.history.push('/home/account/admin-list/add');
  };
  render() {
    const operations = <Button type="primary" icon="plus" onClick={this.onAdd}>添加成员</Button>;
    return <div>
      <Tabs onChange={this.callback} type="card" tabBarExtraContent={operations}>
        <TabPane  tab="成员管理" key="1">
          <AdminList/>

        </TabPane >
      </Tabs>

    </div>
  }
}

export default Admin;
