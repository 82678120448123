import {fromJS,} from 'immutable';
import {handleActions} from 'redux-actions';
import {
  FETCH_ADMIN_LIST,
  FETCH_ROLE_LIST,
  FETCH_PERSONAL_INFO,
  GET_ADMIN_ID,
  FETCH_ROLE_INFO,
  FETCH_ALL_MUNES,
  FETCH_USER_MUNES,
  GET_MENU_ITEM_BADGE
} from '../actions/admin';

const initialState = fromJS({
  //管理员列表
  adminList: null,
  //角色列表
  roleList: null,
  //个人信息表单
  personalInfo: null,
  //获取管理员详情
  adminInfoByID:{},
  //获取角色详情
  roleInfo:{},
  //获取所有菜单
  menusList:[],
  //获取当前菜单
  userMenusList:[],
  // 菜单id映射数量
  menuIdMapBadgeNum: {}
});

export const admin = handleActions(
  {
    [FETCH_ADMIN_LIST]: (state, action) => {
      return state.set('adminList', action.payload)
    },
    [FETCH_ROLE_LIST]: (state, action) => {
      return state.set('roleList', action.payload)
    },
    [FETCH_PERSONAL_INFO]: (state, action) => {
      return state.set('personalInfo', action.payload)
    },
    [GET_ADMIN_ID]: (state, action) => {
      return state.set('adminInfoByID', action.payload)
    },
    [FETCH_ROLE_INFO]: (state, action) => {
      return state.set('roleInfo', action.payload)
    },
    [FETCH_ALL_MUNES]: (state, action) => {
      return state.set('menusList', action.payload)
    },
    [FETCH_USER_MUNES]: (state, action) => {
      return state.set('userMenusList', action.payload)
    },
    [GET_MENU_ITEM_BADGE]: (state, action) => {
        return state.set('menuIdMapBadgeNum', action.payload)
    }
  },
  initialState
);

