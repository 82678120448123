import request from '../utils/request';

export default {
  // 查询模特列表
  getModelList: (info) => request('/admin/ai/search-model', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 模特详情
  modelDetail: (info) => request('/admin/ai/detail-model', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 模特编辑
  editModelDetail: (info) => request('/admin/ai/edit-model', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 模特上下架
  editModelStatus: (info) => request('/admin/ai/edit-model-status', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 查询场景列表
  getBGList: (info) => request('/admin/ai/search-background', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 场景详情
  bgDetail: (info) => request('/admin/ai/detail-background', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 模特场景
  editBGDetail: (info) => request('/admin/ai/edit-background', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 场景上下架
  editBGStatus: (info) => request('/admin/ai/edit-background-status', {
    method: 'POST',
    data: {
      ...info
    }
  }),
}