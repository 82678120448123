import React, {Component} from 'react';
import {Input, Button, Row, Col, Form, Radio,message, InputNumber} from 'antd/lib/index';
import { addRole} from "../../../store/actions/admin";
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import {Divider, Icon, Popover, Select} from "antd";
import API from "../../../apis";
const {Option} = Select;
const { TextArea } = Input;

class OrderEdit extends Component {

  state = {
    memberList: [],
    orderInfo: null,
  };

  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16
    },
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.getOrderInfo(id);
  }

  // 获取客户列表
  getBDMembers = async (value) => {
    const params = {
      search: value || null
    }
    let res = await API.getBDMembers(params);
    if (res.code === 0) {
      // 没有搜索内容情况下把当前订单数据添加到列表第一位
      if (!value) {
        const { member_id, mobile, nickname } = this.state.orderInfo;
        const selectData = {
          id: member_id,
          mobile,
          nickname,
        }
        res.data.unshift(selectData);
      }

      this.setState({
        memberList: res.data
      })
    }
  };

  // 获取订单信息
  getOrderInfo = async (id) => {
    const params = {
      order_id: id
    }
    let res = await API.getOrderInfo(params);
    if (res.code === 0) {
      this.setState({
        orderInfo: res.data
      });
      this.getBDMembers();
    }
  };

  onCancel = () => {
    this.props.history.goBack();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...this.state.orderInfo,
          ...values
        };
        let res = await API.orderUpdate(params);
        if (res.code == 0) {
          message.success(res.msg|| '添加成功', 1, () => {
            this.props.history.replace('/home/order/order-detail/'+ this.state.orderInfo.order_id);
          });
        }
      }
    });
  };

  // 客户搜索
  onMemberSearch = (value) => {
    this.getBDMembers(value);
  }

  render() {
    const {memberList, orderInfo} = this.state;
    const {getFieldDecorator} = this.props.form;
    return <div className="row-permission-setting">
      <Row type="flex" justify="space-around">
        <Col span={23}>
          <Form {...this.formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label='订单ID'>
              <div>{orderInfo?.order_id}</div>
            </Form.Item>

            <Form.Item label='订单创建时间'>
              <div>{orderInfo?.create_time}</div>
            </Form.Item>

            <Form.Item label='客户'>
              {getFieldDecorator('member_id', {
                initialValue: orderInfo?.member_id,
                rules: [{type: 'number', required: true, message: '请选择客户'}]
              })(
                <Select
                showSearch
                placeholder="请选择客户"
                filterOption={false}
                allowClear={true}
                onSearch={this.onMemberSearch}
                >
                  {
                    memberList?.map(item => <Option key={item.id} value={item.id}>{`${item.nickname}（${item.mobile}）`}</Option>)
                  }
                </Select>
              )}
            </Form.Item>
            <Form.Item label='款式数量'>
              {getFieldDecorator('style_quantity', {
                initialValue: orderInfo?.style_quantity,
                rules: [{type: 'number', required: true, message: '请输入款式数量'}]
              })(
                <div>
                  <div>{orderInfo?.style_quantity}</div>
                  <div style={{color: '#AAAAAA'}}>注：款式数量根据订单详情中实际添加款式数量显示</div>
                </div>

              )}
            </Form.Item>
            <Form.Item label='金额'>
              {getFieldDecorator('money', {
                initialValue: orderInfo?.money,
                rules: [{type: 'number', required: true, message: '仅允许输入数字和小数点，最多支持2位小数', min: 0}]
              })(
                <InputNumber
                  formatter={(value) => {
                    let reg = /^(-)*(\d+)\.(\d\d).*$/
                    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '').replace(reg, '$1$2.$3')
                  }}
                  placeholder='仅允许输入数字和小数点，最多支持2位小数'
                  style={{width: '100%'}}
                  min={0}
                />
              )}
            </Form.Item>
            <Form.Item label='备注'>
              {getFieldDecorator('mark', {
                initialValue: orderInfo?.mark,
                rules: [{type: 'string'}]
              })(
                <TextArea rows={4} placeholder="商家期望的场景、模特风格、其他要求等信息" maxLength={200} />
              )}
            </Form.Item>
            <div className='btn-con-no-flex'>
              <Button type="normal" onClick={this.onCancel}>取消</Button>
              <Button type="primary" htmlType="submit">保存</Button>
            </div>
          </Form>
        </Col>
      </Row>

    </div>
  }
}
// 将action注入到页面组件
const matDispatchToProps = {
  // addRole
};
// 将store注入到页面组件
function mapStateToProps(state) {
  return {
  }
}
const RoleFormWrap = Form.create({ name: 'role_form' })(OrderEdit);

export default withRouter(connect(mapStateToProps, matDispatchToProps)(RoleFormWrap));
