import React from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    doLogin
} from "../../../store/actions/login";
import configs from '../../../configs.js';

import './login.less'

class Login extends React.Component {
    state = {
        verifyKey: 0,
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.doLogin({
                    username: values.username,
                    password: values.password,
                    captcha: values.captcha,
                }).then(({ payload }) => {
                    // 登录失败，弹出提示
                    if (payload.error) {
                        this.setState({
                            verifyKey: this.state.verifyKey + 1
                        })                      //message.error(payload.error.msg, 1);
                    }
                    // 登录成功，就切换页面
                    else {
                        window.location.href = '/';
                    }
                })
            }
        });
    };

    handleChangeVerifyCode = e => {
        this.setState({
            verifyKey: this.state.verifyKey + 1
        })
    };
    formItemLayout = {
        labelCol: {
            span: 5,
        },
        wrapperCol: {
            span: 19
        },
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className='login-page'>
                <Form onSubmit={this.handleSubmit} className="login-form" {...this.formItemLayout}>
                    <div className="logo-login"><img src={require('./logo.png')}/></div>
                    <h1 className="logo-text-login">运营管理后台</h1>
                    <Form.Item label="账号">
                        {getFieldDecorator('username', {
                            rules: [{ required: true, message: '请输入用户名' }],
                        })(
                            <Input placeholder="用户名"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label="密码">
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: '请输入密码' }],
                        })(
                            <Input
                                type="password"
                                placeholder="密码"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label="验证码">
                        {getFieldDecorator('captcha', {
                            rules: [{ required: true, message: '输入验证码' }],
                        })(
                            <Row gutter={10}>
                                <Col span={12}>
                                    <Input
                                        type="text"
                                        placeholder="请输入验证码"
                                    />
                                </Col>
                                <Col span={12}>
                                    <img
                                        crossOrigin="true"
                                        key={this.state.verifyKey}
                                        src={configs.baseUrl + "/admin/public/new_captcha?randomKey=" + this.state.verifyKey}
                                        style={{ width: "100%", height: "40px", cursor: "pointer" }}
                                        onClick={ this.handleChangeVerifyCode }
                                    />
                                </Col>
                            </Row>,
                        )}
                    </Form.Item>
                    <Button type="primary" block htmlType="submit" className="login-form-button">
                        登录
                    </Button>
                </Form>
            </div>
        );
    }
}

const matDispatchToProps = {
    doLogin,
};

function mapStateToProps(state) {
    return {
        verifyCode: state.getIn(['global', 'verifyCode'])
    }
}

export default withRouter(connect(mapStateToProps, matDispatchToProps)(Form.create({ name: 'login' })(Login)));
