import React, {Component} from 'react';
import { Input, Button,  Row, Col, Form,message} from 'antd/lib/index';
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import {resetPassword} from "../../../../store/actions/admin";

class ResetPassword extends Component {
  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16
    },
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let res = await resetPassword(values);
        if (res.code === 0) {
          message.success(res.msg|| '修改成功', 1, () => {
            //  成功后要做什么
          });
        } else {
          message.error(res.msg);
        }
      }
    });
  };
  render() {
    const {getFieldDecorator} = this.props.form;
    return <div>
      <Row type="flex" justify="space-around">
        <Col span={22}>
          <Form {...this.formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label="原始密码" hasFeedback>
              {getFieldDecorator('old_password', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                ],
              })(<Input.Password  placeholder='请输入原始密码'/>)}
            </Form.Item>
            <Form.Item label="新密码" hasFeedback>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                ],
              })(<Input.Password  placeholder='请输入新密码'/>)}
            </Form.Item>
            <Form.Item label="重复密码" hasFeedback>
              {getFieldDecorator('re_password', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                ],
              })(<Input.Password  placeholder='请输入重复密码'/>)}
            </Form.Item>
            <Form.Item>
              <Row gutter={10}>
                <Col span={2} offset={6}>
                  <Button type="primary"  htmlType="submit">
                    保存
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>

    </div>
  }
}
// 将action注入到页面组件
const matDispatchToProps = {
};

// 将store注入到页面组件
function mapStateToProps(state) {
  return {

  }
}
const ResetPasswordFormWrap = Form.create({ name: 'ResetPasswordForm' })(ResetPassword);

export default withRouter(connect(mapStateToProps, matDispatchToProps)(ResetPasswordFormWrap));