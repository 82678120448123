import { combineReducers } from 'redux-immutable';

import * as routerReducer from './router-reducer'
import * as globalReducer from './global-reducer'
import * as adminReducer from './admin-reducer'
import * as operateReducer from './operate-reducer'

export default combineReducers({
    ...routerReducer,
    ...globalReducer,
    ...adminReducer,
    ...operateReducer,
});
