import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import './index.less';
import {Button, Modal} from "antd";
import API from '../../apis'

const ProgressComponent = ({data, onCancel}) => {

  const [progressData, setProgressData] = useState({
    // 完成数量
    finishCount: 0,
    // 总共多少张图片
    total: 0,
    // 图片列表进度
    imgList: []
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    setProgressData({
      finishCount: getFinishCount(data.imgList),
      total: data.total,
      imgList: data.imgList
    });
  },[data])

  // 获取当前上传完成数量
  const getFinishCount = (list) => {
    let count = 0;
    list.map(file => {
      if (file.status === 'done') {
        count += 1;
      }
    });
    return count;
  }

  const formatSizeValue = (value) => {
    let valueKB = value / 1024;
    let valueM = valueKB / 1024;
    return valueM > 1 ? valueM.toFixed(2) + ' M' : valueKB.toFixed(2) + ' KB';
  }

  // 取消
  const onCancelClick = async () => {
    Modal.confirm({
      title: '提示',
      content: (
        <div>
          <p>未上传的图片将直接取消，是否取消上传？</p>
        </div>
      ),
      okText: '取消上传',
      cancelText: '继续上传',
      onOk: async () => {
        if (onCancel) {
          onCancel();
        }
      },
    });
  }

  return (<div className={`progress-component`}>
    <div className="progress-body-container">
      {
        progressData.finishCount === progressData.total
        ? <div className="sub-title">成功上传 <span className="sub-title-value">{progressData.total}</span> 张图片</div>
        : <div className="sub-title">正在上传 <span className="sub-title-value">{progressData?.finishCount}/{progressData?.total}</span></div>
      }
      <div className="progress-bar-box">
        <div className="progress-bar">
          {
            progressData && <div className="progress" style={{width: `${(progressData?.finishCount/progressData?.total) * 100}%`}}></div>
          }
        </div>
        <span className="pre-value">{Math.floor((progressData.finishCount / progressData.total) * 100)}%</span>
      </div>

      <div className="img-list-box">
        {
          (progressData?.imgList || []).map((file) => <div className="item-box">
            <span className="left-box">
              <span className="img-box">
                <img className="item-img" src={ file.imgUrl }/>
              </span>
              
              <span className="info-box">
                <div className="file-name">{file.name}</div>
                <div className="progress">{`${formatSizeValue(file.size*file.percent/100)} / ${formatSizeValue(file.size)}`}</div>
              </span>
            </span>
            <span className="right-box">
              <span className="percent">{parseFloat(file.percent).toFixed(2)}%</span>
            </span>
          </div>)
        }
      </div>

      <div className="bottom-box">
        {
          progressData.finishCount !== progressData.total && <Button className="ant-btn user-type user-type6" onClick={() => onCancelClick()}>取消</Button>
        }
        {
          progressData.finishCount === progressData.total && <span>上传成功，正在跳转 AI 生成</span>
        }
      </div>

    </div>
  </div>)
};

export default withRouter(ProgressComponent);
