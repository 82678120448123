import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import {Button, Checkbox} from "antd";
import './index.less';
import API from "../../apis";

const CheckboxGroup = Checkbox.Group;

/**
 * 批量勾选
 */
const BatchSelectComponent = ({orderId, styleId, onOk, onClose}) => {

  const [imgList, setImgList] = useState([]);

  const [checkAll, setCheckAll] = useState(true);
  const [checkList, setCheckList] = useState([]);

  useEffect(() => {
    if (!orderId) {
      return;
    }
    getStyleImgList();
  },[orderId])

  useEffect(() => {
    if (!imgList) {
      return;
    }
    handleCheckAll(true);
  },[imgList])

  useEffect(() => {
    if (!imgList) {
      return;
    }
    setCheckAll(checkList.length === imgList.length);
  },[checkList, imgList])

  // 获取生成图片配置
  const getStyleImgList = async () => {
    const params = {
      order_id: orderId,
      style_id: styleId || null
    };
    const res = await API.getStyleImgList(params);
    if (res.code === 0) {
      setImgList(res.data);
    }
  }

  const onCheckAllChange = (checked) => {
    setCheckAll(checked);
    handleCheckAll(checked);
  }

  const onCheckItemChange = (checked, value) => {
    let index = checkList.indexOf(value);

    // 勾选
    if (checked && index === -1) {
      setCheckList([...checkList, value]);
    }
    // 取消勾选
    if (!checked && index !== -1) {
      const newList = [...checkList];
      newList.splice(index, 1);
      setCheckList(newList);
    }

  }

  const handleCheckAll = (isCheckAll) => {
    // 取消全部
    if (!isCheckAll) {
      setCheckList([]);
      return;
    }

    // 勾选全部
    let list = [];
    (imgList || []).map(item => {
      list.push(item.style_img_id);
    })
    setCheckList(list);
  }

  const onOkClick = () => {
    if (onOk) {
      onOk(checkList);
    }
  }

  const onCancelClick = () => {
    if (onClose) {
      onClose();
    }
  }

  return (<div className={`batch-select-component`}>
    <div className="batch-select-container">
      <div className="top-box" onClick={() => onCheckAllChange(!checkAll)}>
        <Checkbox checked={checkAll}></Checkbox>
        <span className="title">全选 — 选择原图</span>
      </div>

      <div className="img-list-box">
        {
          (imgList || []).map(item => <div className="item-box" key={item.style_img_id} onClick={() => onCheckItemChange(!checkList.includes(item.style_img_id), item.style_img_id)}>
            <span className="img-box">
              <img className="img" src={item?.image} />
            </span>
            <span className="id-box">
              <Checkbox checked={checkList.includes(item.style_img_id)} value={item.style_img_id}>ID: {item.style_img_id}</Checkbox>
            </span>
          </div>)
        }
      </div>

      <div className="bottom-box">
        <span className="left-box">
          已选择 {checkList?.length} / {imgList?.length}
        </span>
        <span className="right-box">
          <Button className="ant-btn user-type user-type6" onClick={() => onCancelClick()}>取消</Button>
          <Button className="ant-btn user-type user-type4" disabled={checkList.length === 0} onClick={() => onOkClick()}>确定</Button>
        </span>
      </div>
    </div>
  </div>)
};

export default withRouter(BatchSelectComponent);
