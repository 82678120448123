import React, {Component} from 'react';
import {Table, Card, Select, Button, Input, DatePicker} from 'antd';
import API from "../../../../apis";
import {withRouter} from 'react-router-dom'
import moment from 'moment';
import {formatNum} from "../../../../utils/helpers";
import ImgPreview from '../../../../components/img-preview';


const { RangePicker } = DatePicker;
const {Option} = Select;

class MyTask extends Component { 
  state = {
    columns: [
      {
        title: '记录ID',
        dataIndex: 'task_id',
        key: 'task_id',
        width: '8%',
      },
      {
        title: '生成时间',
        dataIndex: 'build_time',
        key: 'build_time',
        width: '8%',
        render: text => <div className='time-item'>{text}</div>,
      },
      {
        title: '使用场景',
        dataIndex: 'image_type_name',
        key: 'image_type_name',
        width: '8%',
      },
      {
        title: '上传图片',
        dataIndex: 'upload_image',
        key: 'upload_image',
        width: '8%',
        render: (text, record) => <img src={text} style={{width:'30px', cursor: 'pointer'}} onClick={() => this.onImgClick(record)}/>,
      },
      {
        title: '图片设定',
        dataIndex: 'task_setting',
        key: 'task_setting',
        render: text => <div dangerouslySetInnerHTML={{__html: text}}></div>,
      },
      {
        title: '生成方式',
        dataIndex: 'build_type_name',
        key: 'build_type_name',
        width: '8%',
      },
      {
        title: '消耗M豆',
        key: 'cost',
        dataIndex: 'cost',
        width: '8%',
        render: text => <span>{formatNum(text)}</span>
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        width: '8%',
        render: (status, record) => {
          switch (status) {
            case -1:
              return <span style={{color:"#FF0000"}}>{record.status_name}</span>;
            case 3:
              return <span style={{color:"#70B603"}}>{record.status_name}</span>;
            default:
              return <span>{record.status_name}</span>;
          }
        },
      },
      {
        title: '生成图片',
        key: 'outputs',
        dataIndex: 'outputs',
        width: '15%',
        render: (outputs, record) => {
          if(record.status == 3){
            return <div style={{cursor: 'pointer'}} onClick={() => this.onImgClick(record)}>
                {outputs?.map((name,value) => (
                  <img src={name} style={{marginLeft:'3px',width:'30px'}}/>
                ))}
                </div>;
          }else{
            return '-';
          }
        }
      },
    ],
    pagination: {
      total: 0,
      current: 1,
      pageSize: 20,
    },
    initList:[],
    loading: false,
    imgPreviewInfo: null
  };

  init=async(page)=> {
    if (this.props.match.params.id) {
        const data = await API.getTaskList({
            id: this.props.match.params.id,
            page
        });
        if (data.code === 0) {
            this.setState({
              initList:data.data.list,
              loading: false,
              total:data.data.total,
              total_task:data.data.total_task,
              total_member:data.data.total_member,
              total_build_quick:data.data.total_build_quick,
              total_build_order:data.data.total_build_order,
              total_success:data.data.total_success,
              total_error:data.data.total_error,
              total_cost:data.data.total_cost,
              pagination: {
                total: data.data.total,
                current: page
              },
            })
        }
    }
  }
  // 当选中某一页时回调
  pageChange = (page) => {
    this.init(page.current);
  };
  componentDidMount = async () => {
    this.init();
  }
  disabledDate(current) {
    return current && current > moment().endOf('day');
  }

  onImgClick = (record) => {
    const data = {
      show: true,
      primaryImgUrl: record.upload_image,
      imgList: record.outputs
    }

    this.setState({
      imgPreviewInfo: data
    });
  }
  
  
  render() {
    const {columns, initList,total_task,total_member,total_build_quick,total_build_order,total_success,total_error,total_cost, imgPreviewInfo} = this.state;
    return <div>
        <p>
        生成图片 <span className='red'>{total_task}次</span>，
        快速生成 <span className='red'>{total_build_quick}次</span>，
        预约生成 <span className='red'>{total_build_order}次</span>，
        已完成 <span className='red'>{total_success}次</span>，
        失败 <span className='red'>{total_error}次</span>，
        共消耗M豆 <span className='red'>{total_cost}</span>
        </p>
        <Table
          columns={columns}
          rowKey="id"
          dataSource={initList}
          pagination={this.state.pagination}
          loading={this.state.loading}
          onChange={this.pageChange}
          locale={{emptyText: '对不起，没有搜到任何内容'}}
        />
        {
          imgPreviewInfo && <ImgPreview data={imgPreviewInfo} onClose={() => this.setState({imgPreviewInfo: null})}/>
        }
      
      </div>
  }
};

export default withRouter(MyTask);