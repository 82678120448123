import React, {Component} from 'react';
import {Table, Modal, Button, Row, Col, Tag, Input} from 'antd';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {fetchAdminList, deleteAdmin, cancelBanAdmin, banAdmin} from "../../../../store/actions/admin";

class adminList extends Component {
  state = {
    columns: [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: text => <a href="javascript:;">{text}</a>,
      },
      {
        title: '用户名',
        dataIndex: 'user_login',
        key: 'user_login',
      },
      {
        title: '最后登录IP',
        key: 'last_login_ip',
        dataIndex: 'last_login_ip',
      },
      {
        title: '最后登录时间',
        key: 'last_login_time',
        dataIndex: 'last_login_time',
      },
      {
        title: '邮箱',
        key: 'user_email',
        dataIndex: 'user_email',
        width: 150,
      },
      {
        title: '状态',
        dataIndex: 'user_status',
        key: 'user_status',
        render: user_status => {
          switch (user_status) {
            case 0:
              return <Tag color="#f50">已禁用</Tag>;
            case 1:
              return <Tag color="#87d068">正常</Tag>;
            case 2:
              return <Tag color="orange">未验证</Tag>;
          }
        },
      },
      {
        title: '操作',
        key: 'action',
        render: (text, record) => {
          let publishStatus = null;
          if (record.id != 1) {
            switch (record.user_status) {
              case 0:
                publishStatus = <Tag color="orange" style={{cursor:'pointer'}} onClick={this.onCancelBan.bind(this, record.id)}>启用</Tag>;
                break;
              case 1:
                publishStatus = <Tag color="#f50" style={{cursor:'pointer'}} onClick={this.onBan.bind(this, record.id)}>禁用</Tag>;
                break;
              case 2:
                publishStatus = <Tag color="orange" style={{cursor:'pointer'}} onClick={this.onCancelBan.bind(this, record.id)}>启用</Tag>;
                break;
            }
            return <div className='w150-item'>
            <Tag color="#108ee9" style={{cursor:'pointer'}} onClick={this.onEdit.bind(this, record.id)}>编辑</Tag>
            <Tag color="red" style={{cursor:'pointer'}} onClick={this.onDelete.bind(this, record.id, record.user_login)}>删除</Tag>
              {publishStatus}
            </div>
          }

        },
      }
    ],
    // 过滤条件
    filterConditions: {},
    user_login:'',
    user_email:'',

  };
  onEdit = (id) => {
    this.props.history.push('/home/account/admin-list/edit/' + id);
  };
  onDelete = (id, user_login) => {
    Modal.confirm({
      title: '删除提示！',
      content: (
        <div>
          <p>您是否删除管理员:{user_login}？</p>
        </div>
      ),
      okText: '删除',
      cancelText: '取消',
      onOk: async () => {
        const res =  await this.props.deleteAdmin(id);
        if (res.payload.code === 0) {
          this.init();
        } else {

        }
      },
      onCancel: () => {
        this.setState({
          visible: false,
        })
      },
    });
  };
  onCancelBan = async (id) => {
    const res = await this.props.cancelBanAdmin(id);
    if (res.payload.code === 0) {
      this.init();
    } else {

    }
  };
  onBan = async (id) => {
    const res = await this.props.banAdmin(id);
    if (res.payload.code === 0) {
      this.init();
    } else {

    }
  };
  feach_user_login = e => {
    this.setState({
      user_login: e.target.value
    })
  };
  feach_user_email = e => {
    this.setState({
      user_email: e.target.value
    })

  };
  onSearch = () => {
    this.props.fetchAdminList({user_login: this.state.user_login,user_email: this.state.user_email});
  };
  onEmpty = () => {
    this.setState({
      user_login:'',
      user_email:'',
    }, () => {
      this.props.fetchAdminList();
    });
  }
  init() {
    this.props.fetchAdminList();
    /*    this.props.deleteAdmin();
        this.props.cancelBanAdmin();*/
  }

  componentDidMount() {
    this.init();
  }

  render() {
    return <div>
      <Row type="flex" justify="space-around">
        <Col span={22}>
          <div className='serach-area'>
              <span>
              <label>用户名：</label>
              <Input placeholder="请输入用户名" onChange={this.feach_user_login} value={this.state.user_login}/>
              </span>
            <span>
              <label>邮箱：</label>
              <Input placeholder="请输入邮箱"  onChange={this.feach_user_email} value={this.state.user_email}/>
            </span>
            <span>
              <Button type="primary" onClick={this.onSearch}>搜索</Button>
              <Button type="danger" onClick={this.onEmpty}>清空</Button>
            </span>
          </div>
          <Table columns={this.state.columns}
                 rowKey="id"
                 dataSource={this.props.adminList?.data}
                 loading={this.props.adminList?.data == null}
                 locale={{emptyText: '对不起，没有搜到任何内容'}}
          />
        </Col>
      </Row>
    </div>
  }
}

// 将action注入到页面组件
const matDispatchToProps = {
  fetchAdminList, deleteAdmin, cancelBanAdmin, banAdmin
};

// 将store注入到页面组件
function mapStateToProps(state) {
  return {
    adminList: state.getIn(['admin', 'adminList'], []),
  }
}

export default withRouter(connect(mapStateToProps, matDispatchToProps)(adminList));
