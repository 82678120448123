import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Modal, Table, Card, Select, Button, Divider, Input, DatePicker, message} from 'antd';
import API from "../../../../apis";
import {setChannelParam} from "../../../../store/actions/operate";
import {setCurrentMenu} from '../../../../store/actions/login';



class Channel extends Component {
  constructor(props) {
    super(props);
    if (typeof props.channelParam.toJSON === 'function') {
      this.state.filterConditions = props.channelParam.toJSON();
    } else {
      this.state.filterConditions = props.channelParam;
    }
  }
  state = {
    columns: [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 120,
      },
      {
        title: '渠道名称',
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        render: text => <div className='w100-item'>{text}</div>,
      },
      {
        title: '渠道链接',
        dataIndex: 'url',
        key: 'url',
        width: '15%'
      },
      {
        title: '渠道描述',
        dataIndex: 'remark',
        key: 'remark',
        width: '20%',
        render: text => <div className='w100-item'>{text ?  text : '—'}</div>,
      },
      {
        title: '添加时间',
        key: 'create_time',
        dataIndex: 'create_time',
        render: text => <div className='time-item'>{text}</div>,
      },
      {
        title: '操作',
        key: 'action',
        render: (text, record) => {
          return <div className='action-item'>
            <a href="javascript:;" onClick={this.onLook.bind(this, record.id)}>查看数据</a>
            <Divider type="vertical"/>
            <a href="javascript:;" onClick={this.onEdit.bind(this, record.id)}>编辑</a>
            <Divider type="vertical"/>
            <a href="javascript:;" style={{color: '#f5222d'}}
               onClick={this.onDelete.bind(this, record.id, record.name)}>删除</a>
          </div>
        },
      }
    ],
    // 过滤条件
    filterConditions: {
      name: '',
      channel: '',
      remark: '',
      page: 1
    },
    pagination: {
      total: 0,
      current: 1,
      pageSize: 20,
    },
    initList: [],
    loading: false,
  };
  onAdd = ( ) => {
    this.props.history.push('/home/operate/channel/add');
  };
  onDelete = (id, name) => {
      Modal.confirm({
        title: '删除提示！',
        content: (
            <p>确认删除“{name}”渠道？</p>
      ),
      okText: '删除',
            cancelText: '取消',
            onOk: async () => {
          let res = await API.deleteChannel(id);
          if (res.code === 0) {
            this.init();
            message.success('删除成功');
          }
        },
            onCancel: () => {
          this.setState({
            visible: false,
          })
        },
      });
  };

  onEdit = (id) => {
    this.props.history.push('/home/operate/channel/edit/' + id);
  };
  onLook = (id) => {
    this.props.history.push('/home/operate/channel/look/' + id);
  };
  init = async (page) => {
    this.setState({loading: true});
    const param ={
      name: this.state.filterConditions.name,
      channel: this.state.filterConditions.channel,
      remark: this.state.filterConditions.remark,
      page: page || this.props.channelParam.page
    };
    this.props.setChannelParam(param);
    const data = await API.getChannelList(param);
    if (data.code === 0) {
      this.setState({
        initList: data.data.list,
        loading: false,
        pagination: {
          total: data.data.total,
          current: page || this.props.channelParam.page
        },
      })
    }
  };


  // 当选中某一页时回调
  pageChange = (page) => {
    this.init(page.current);
  };

  componentDidMount() {
    this.init();
  }

  feach_keyword = (key, value) => {
    this.setState({
      filterConditions: {
        ...this.state.filterConditions,
        [key]: value.target.value,
      },
    })
  };
  onSearch = () => {
    this.init(1);
  };
  onEmpty = () => {
    this.setState({
      filterConditions: {
        name: '',
        channel: '',
        remark:'',
        page: 1
      },
    }, () => {
      this.init(1);
    });
  };

  render() {
    const {columns, initList, filterConditions} = this.state;
    return <div className='content-tab'>
      <Card
        title="全部"
        extra={
          <Button type="primary" icon="plus" onClick={this.onAdd}>添加渠道</Button>
        }
      >
        <div className='serach-area'>
          <span>
            <label>渠道名称：</label>
            <Input placeholder="请输入渠道名称"
                   value={filterConditions.name}
                   onChange={(value) => this.feach_keyword('name', value)}/>
          </span>
          <span>
            <label>渠道编码：</label>
            <Input placeholder="请输入渠道编码"
                   value={filterConditions.channel}
                   onChange={(value) => this.feach_keyword('channel', value)}/>
          </span>
          <span>
            <label>渠道描述：</label>
            <Input placeholder="请输入渠道描述"
                   value={filterConditions.remark}
                   onChange={(value) => this.feach_keyword('remark', value)}/>
          </span>
        </div>
        <div className='serach-area'>
          <span>
            <Button type="primary" onClick={this.onSearch}>搜索</Button>
            <Button type="danger" onClick={this.onEmpty}>清空</Button>
          </span>
        </div>
        <Table
            columns={columns}
            rowKey="id"
          dataSource={initList}
          pagination={this.state.pagination}
          loading={this.state.loading}
          onChange={this.pageChange}
          locale={{emptyText: '对不起，没有搜到任何内容'}}
        />
      </Card>
    </div>
  }
};

const matDispatchToProps = {
  setChannelParam,setCurrentMenu
};

function mapStateToProps(state) {
  return {
    channelParam: state.getIn(['operate', 'channelParam'], {}),
  }
}

export default connect(mapStateToProps, matDispatchToProps)(Channel);
