import React, {Component} from 'react';
import {Table} from "antd";
import API from "../../../../apis";
import {withRouter} from "react-router-dom";
import {formatNum} from '../../../../utils/helpers';

class myRecharge extends Component {
    state = {
        columns: [
            {
                title: '订单号',
                dataIndex: 'order_no',
                key: 'order_no',
            },
            {
                title: '支付时间',
                key: 'pay_time',
                dataIndex: 'pay_time',
                width: '20%',
                render: text => <div className='time-item'>{text}</div>,
            },
            {
                title: '支付方式',
                dataIndex: 'pay_type',
                key: 'pay_type',
            },
            {
                title: 'M豆套餐',
                dataIndex: 'package_coin',
                key: 'package_coin',
                width: '10%',
            },
            {
                title: '支付金额',
                dataIndex: 'money',
                key: 'money',
            },
            {
                title: 'M豆余额',
                dataIndex: 'coin',
                key: 'coin',
            },
        ],
        pagination: {
            total: 0,
            current: 1,
            pageSize: 20,
        },
        loading: false,
        rechargeInfo:{}
    };
    componentDidMount() {
        this.init();
    }
    init = async(page)=> {
        if (this.props.match.params.id) {
            const data = await API.getMyOrder({
                id: this.props.match.params.id,
                page
            });
            if (data.code === 0) {
                this.setState({
                    rechargeInfo:data.data,
                    pagination: {
                        total: data.data.total,
                        current: page
                    },
                })
            }
        }
    }
    // 当选中某一页时回调
    pageChange = (page) => {
        this.init(page.current);
    };
    render() {
        const {columns, rechargeInfo, pagination, loading} = this.state;
        return <div>
            <p> 
                累计订单 <span className='red'>{rechargeInfo.total}</span>，
                合计金额 <span className='red'>￥{formatNum(rechargeInfo.total_pay_money)}</span>，
                共 <span className='red'>{formatNum(rechargeInfo.total_pay_coin)} M豆</span>
            </p>
            <Table
                columns={columns}
                dataSource={rechargeInfo.list}
                rowKey="id"
                pagination={pagination}
                loading={loading}
                onChange={this.pageChange}
                locale={{emptyText: '对不起，没有搜到任何内容'}}
            />
        </div>
    }
}

export default withRouter(myRecharge);
