import React, {Component} from 'react';
import {Input, Button, Row, Col, Form, Radio,message} from 'antd/lib/index';
import { addRole} from "../../../store/actions/admin";
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import {Divider, Icon, Spin, Select, InputNumber} from "antd";
import API from "../../../apis";
const {Option} = Select;
const { TextArea } = Input;

class OrderAdd extends Component {

  state = {
    memberList: [],
  };

  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16
    },
  };

  componentDidMount() {
    this.getBDMembers();
  }

  // 获取客户列表
  getBDMembers = async (value) => {
    const params = {
      search: value || null
    }
    let res = await API.getBDMembers(params);
    if (res.code === 0) {
      this.setState({
        memberList: res.data
      })
    }
  };

  onCancel = () => {
    this.props.history.goBack();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let res = await API.orderUpdate(values);
        if (res.code == 0) {
          message.success('创建订单成功', 1, () => {
            this.props.history.replace(`/home/order/order-detail/${res.data.order_id}`);
          });
        }
      }
    });
  };

  handleSave = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let res = await API.orderUpdate(values);
        if (res.code == 0) {
          message.success('创建订单成功', 1, () => {
            this.props.history.replace(`/home/order/order-list`);
          });
        }
      }
    });
  }

  // 客户搜索
  onMemberSearch = (value) => {
    this.getBDMembers(value);
  }

  render() {
    const {memberList} = this.state;
    const {getFieldDecorator} = this.props.form;
    return <div className="row-permission-setting">
      <Row type="flex" justify="space-around">
        <Col span={23}>
          <Form {...this.formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label='客户'>
              {getFieldDecorator('member_id', {
                // initialValue: memberList?.current_user_id,
                rules: [{type: 'number', required: true, message: '请选择客户'}]
              })(
                <Select
                  showSearch
                  placeholder="请选择客户"
                  filterOption={false}
                  allowClear={true}
                  // onChange={(newValue, aa) => {
                  //   console.log('onchange......', newValue, aa.props);
                  // }}
                  onSearch={this.onMemberSearch}
                  // notFoundContent={true ? <Spin size="small" /> : null}
                >
                  {
                    memberList?.map(item => <Option key={item.id} value={item.id}>{`${item.nickname}（${item.mobile}）`}</Option>)
                  }
                </Select>
              )}
            </Form.Item>
            <Form.Item label='款式数量'>
              {getFieldDecorator('style_quantity', {
                rules: [{type: 'string', required: true, message: '请输入款式数量', pattern: new RegExp(/^(([1-9]\d*)|\d)?$/, 'g')}]
              })(
                <Input placeholder='输入订单的款式数量，仅允许输入正整数'/>
              )}
            </Form.Item>
            <Form.Item label='金额'>
              {getFieldDecorator('money', {
                rules: [{type: 'number', required: true, message: '仅允许输入数字和小数点，最多支持2位小数', min: 0}]
              })(
                <InputNumber
                  formatter={(value) => {
                    let reg = /^(-)*(\d+)\.(\d\d).*$/
                    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '').replace(reg, '$1$2.$3')
                  }}
                  placeholder='仅允许输入数字和小数点，最多支持2位小数'
                  style={{width: '100%'}}
                  min={0}
                />
              )}
            </Form.Item>
            <Form.Item label='备注'>
              {getFieldDecorator('mark', {
                rules: [{type: 'string'}]
              })(
                <TextArea rows={4} placeholder="商家期望的场景、模特风格、其他要求等信息" maxLength={200} />
              )}
            </Form.Item>
            <div className='btn-con-no-flex'>
              <Button type="normal" onClick={this.onCancel}>取消</Button>
              <Button type="primary" onClick={this.handleSave}>保存</Button>
              <Button type="primary" htmlType="submit">保存并添加款式</Button>
            </div>
          </Form>
        </Col>
      </Row>

    </div>
  }
}
// 将action注入到页面组件
const matDispatchToProps = {
  // addRole
};
// 将store注入到页面组件
function mapStateToProps(state) {
  return {
  }
}
const RoleFormWrap = Form.create({ name: 'role_form' })(OrderAdd);

export default withRouter(connect(mapStateToProps, matDispatchToProps)(RoleFormWrap));
