import React, {Component} from 'react';
import {Button, Row, Col, Tree, message} from 'antd';
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import {
  getAllMenus,
  saveAuth,
  getAuth,
} from "../../../../store/actions/admin";

import './style.less';

const {TreeNode} = Tree;

class rolePermissions extends Component {
  state = {
    treeData: [],
    checkedKeys: [],
  };
  onCheck = (checkedKeys, info) => {
    this.setState({
      checkedKeys
    })
  };

  onCancel = () => {
    this.props.history.goBack();
  };

  onSubmit = async () => {
    const res = await saveAuth({
      roleId: this.props.match.params.id,
      menuIds: this.state.checkedKeys.join(','),
    });

    if (res.code === 0) {
      message.success(res.msg || '', 1, () => {
        this.props.history.goBack();
      })
    }
  };
  renderTreeNodes = data =>
    data.map(item => {
      if (item.child_menu) {
        return (
          <TreeNode title={item.name} key={String(item.id)} dataRef={item}>
            {this.renderTreeNodes(item.child_menu)}
          </TreeNode>
        );
      }
      return <TreeNode title={item.name} key={String(item.id)} dataRef={item}/>;
    });

  async init() {
    const res = await getAuth(this.props.match.params.id);
    if (res.code === 0) {
      const roleAuth = res.data?.roleAuth?.menu_ids || '';
      this.setState({
        checkedKeys: roleAuth.split(',')
      })
    }
  }

  componentDidMount() {
    this.props.getAllMenus();
    this.init();
  }

  render() {
    return (
      <div className="row-permission-setting">
        <Tree
          checkable
          defaultExpandAll
          checkedKeys={this.state.checkedKeys}
          onCheck={this.onCheck}
        >
          {this.renderTreeNodes(this.props.menusList)}
        </Tree>

        <Row className="footer" gutter={16}>
          <Col span={2}>
            <Button type="primary" onClick={this.onSubmit}>
              保存
            </Button>
          </Col>
          <Col span={2}>
            <Button onClick={this.onCancel}>
              返回
            </Button>
          </Col>
        </Row>
      </div>

    );
  }
}

// 将action注入到页面组件
const matDispatchToProps = {
  getAllMenus
};

// 将store注入到页面组件
function mapStateToProps(state) {
  return {
    menusList: state.getIn(['admin', 'userMenusList'], []),
  }
}

export default withRouter(connect(mapStateToProps, matDispatchToProps)(rolePermissions));
