import './polyfills.js';

import React from 'react';
import ReactDOM from 'react-dom';
import '@csstools/normalize.css';
import './index.less';
import { Provider } from 'react-redux';
import store from './store'
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { ConfigProvider  } from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {setCurrentMenu} from './store/actions/login'
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

import Home from './Home';
import Login from './pages/public/login';
import menuConfigs from './menu-configs.js';

moment.locale('zh-cn');

// 私有路由，如果登录信息不存在，就跳转到登录页
function PrivateRoute({ component: Component, ...rest }) {
    const token = localStorage.getItem('token');
    const dispatch = useDispatch();
    const currentMenuConfig = useSelector(state => {
      return state.getIn(['global', 'currentMenuConfig'], '-')
    });

    const menuConfigMap = {};
    Object.values(menuConfigs).forEach(item => {
        if (item.to) {
            menuConfigMap[item.to] = item;
        }
    })

    return (
        <Route
            {...rest}
            render={props =>{
              let arr = props.location.pathname.split('/');
              while(arr.length > 0) {
                if (menuConfigMap[arr.join('/')]) {
                  //写这里
                  if(currentMenuConfig){
                    break;
                  }else{
                    dispatch(setCurrentMenu(menuConfigMap[arr.join('/')].name));
                    break;
                  }
                } else {
                  arr.pop();
                }
              }

                return token ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
            }}
        />
    );
}

ReactDOM.render(
  <ConfigProvider autoInsertSpaceInButton={false} locale={zh_CN}>
    <Provider store={store}>
      <Router>
        <Route path='/' exact component={Home} />
        <Route path='/login' component={Login} />
        <PrivateRoute path='/home' component={Home} />
      </Router>
    </Provider>
  </ConfigProvider>,
    document.getElementById('root')
);
