export default {
  1: {
    menuId: 1,
    name: '运营管理',
    icon: 'solution'
  },
  2: {
    menuId: 2,
    name: '财务管理',
    icon: 'solution'
  },
  3: {
    menuId: 3,
    name: '客户管理',
    icon: 'user'
  },
  4: {
    menuId: 4,
    name: '成员管理',
    icon: 'user'
  },
  14: {
    menuId: 14,
    name: '影镜工厂',
    icon: 'solution'
  },
  16: {
    menuId: 16,
    name: '模特、场景管理',
    icon: 'solution'
  },
  5: {
    menuId: 5,
    name: '数据统计',
    parentId: 1,
    to: '/home/operate/statistics'
  },
  6: {
    menuId: 6,
    name: '充值记录',
    parentId: 2,
    to: '/home/deposit/deposit-list'
  },
  13: {
    menuId: 13,
    name: '添加客户',
    parentId: 3,
    to: '/home/member/member-add'
  },
  7: {
    menuId: 7,
    name: '客户列表',
    parentId: 3,
    to: '/home/member/member-list'
  },
  8: {
    menuId: 8,
    name: '角色管理',
    parentId: 4,
    to: '/home/account/role-list'
  },
  9: {
    menuId: 9,
    name: '成员列表',
    parentId: 4,
    to: '/home/account/admin-list'
  },
  10: {
    menuId: 10,
    name: '运营参数配置',
    parentId: 1,
    to: '/home/operate/config'
  },
  11: {
    menuId: 11,
    name: '图片生成记录',
    parentId: 1,
    to: '/home/operate/task'
  },
  12: {
    menuId: 12,
    name: '推广渠道',
    parentId: 1,
    to: '/home/operate/channel/list'
  },
  15: {
    menuId: 15,
    name: '订单列表',
    parentId: 14,
    to: '/home/order/order-list'
  },
  17: {
    menuId: 17,
    name: '模特列表',
    parentId: 16,
    to: '/home/model/model-list'
  },
  18: {
    menuId: 17,
    name: '场景列表',
    parentId: 16,
    to: '/home/background/background-list'
  },
}