import { createAsyncAction } from '../../utils/redux-helpers';
import { createAction } from 'redux-actions'
import API from '../../apis'

// 登录验证
export const DO_LOGIN = 'DO_LOGIN';
// 登出验证
export const DO_LOGOUT = 'DO_LOGOUT';
// 获取验证码
export const FETCH_VERFITY_CODE = 'FETCH_VERFITY_CODE';
// 设置当前的菜单配置
export const SET_CURRENT_MENU = 'SET_CURRENT_MENU';

export const doLogin = createAction(DO_LOGIN, async (data) => {
    let result = await API.doLogin(data);
    if (result.code === 0) {
        window.localStorage.setItem('token', result.data.token);
        window.localStorage.setItem('user_nickname', result.data.user.user_nickname);
        window.localStorage.setItem('user_login', result.data.user.user_login);

        // 登录成功后...
        return {
            // 给DO_LOGIN传个token
            token: result.data.token,
            user: result.data.user,
        }
    } else {
        return {
            error: result
        }
    }
});

// 登出
export const doLogout = () => API.doLogout();

// 设置当前的菜单配置
export const setCurrentMenu = createAction(SET_CURRENT_MENU, data =>{
    return data
})

//export const doLogout = createAction(DO_LOGOUT, API.doLogout);
