import login from './login';
import admin from './admin';
import operate from './operate';
import member from "./member";
import task from "./task";
import ai from "./ai";

export default {
    ...login,
    ...admin,
    ...operate,
    ...member,
    ...task,
    ...ai
}
