import React, {Component} from 'react';
import {Input, Button, Row, Col, Form, Radio,message} from 'antd/lib/index';
import { addRole} from "../../../../store/actions/admin";
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";

class roleAdd extends Component {
  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16
    },
  };
  onCancel = () => {
    this.props.history.goBack();
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let res = await addRole(values);
        if (res.code == 0) {
          message.success(res.msg|| '添加成功', 1, () => {
            this.props.history.replace('/home/account/role-list');
          });
        }
      }
    });
  };
  render() {
    const {getFieldDecorator} = this.props.form;
    return <div className="row-permission-setting">
      <Row type="flex" justify="space-around">
        <Col span={22}>
          <Form {...this.formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label='角色名称'>
              {getFieldDecorator('name', {
                rules: [{type: 'string', required: true, message: '请输入角色名称'}]
              })(
                <Input placeholder='请输入角色名称'/>
              )}
            </Form.Item>
            <Form.Item label='角色描述'>
              {getFieldDecorator('remark', {
                rules: [{type: 'string'}]
              })(
                <Input placeholder='请输入角色描述'/>
              )}
            </Form.Item>
            <Form.Item label='状态'>
              {getFieldDecorator('status', {
                rules: [{type: 'number'}]
              })(
                <Radio.Group>
                  <Radio value={1}>开启</Radio>
                  <Radio value={0}>禁用</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <div className='btn-con-no-flex'>
              <Button type="primary"  htmlType="submit">
                保存
              </Button>

              <Button type="normal" onClick={this.onCancel}>
                取消
              </Button>
            </div>
          </Form>
        </Col>
      </Row>

    </div>
  }
}
// 将action注入到页面组件
const matDispatchToProps = {
  addRole
};
// 将store注入到页面组件
function mapStateToProps(state) {
  return {
  }
}
const RoleFormWrap = Form.create({ name: 'role_form' })(roleAdd);

export default withRouter(connect(mapStateToProps, matDispatchToProps)(RoleFormWrap));
