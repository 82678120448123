import React, {Component} from 'react';
import {Input, Form, Icon, message, Button, Card} from 'antd';
import API from '../../../../apis'
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";

const { TextArea } = Input;

class ChannelInfo extends Component {
  state = {
    initInfo:{},
    isExist: false,
    loading: false,
  };
  formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16
    },
  };
  componentDidMount() {
    this.init();
  }
  init=async()=> {
    if (this.props.match.params.id) {
      const data = await API.getChannelDetail(this.props.match.params.id);
      if (data.code === 0) {
        this.setState({
          initInfo:data.data
        })
      }
    }
  }
  onSubmit = async () => {
    this.setState({ loading: true });
    const fieldsValue = this.props.form.getFieldsValue();
    const params = {
      ...fieldsValue,
      id:this.props.match.params.id,
    }
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let res = await API.saveChannel(params);
        if (res.code === 0) {
          this.setState({ loading: false });
          message.success('添加成功');
          this.props.history.replace('/home/operate/channel/list');
        }
        else{
          this.setState({ loading: false });
        }
      }else{
        message.error('请完善表单');
        this.setState({ loading: false });
      }
    });
  }
  onCancel = () => {
    this.props.history.replace('/home/operate/channel/list');
  };
  render() {
    const {getFieldDecorator} = this.props.form;
    const {initInfo,statusList,isExist} = this.state;
    return <div>
      <Button type="link" onClick={this.onCancel}><Icon type="left" />返回</Button>
      <div className='content'>
        <Card title="添加渠道">
            <Form {...this.formItemLayout} >
              <Form.Item label='渠道名称'>
                {getFieldDecorator('name', {
                  initialValue: initInfo.name,
                  rules: [{ required: true, message: '请输入渠道名称'}]
                })(
                  <Input placeholder='请输入渠道名称' maxLength={20}/>
                )}
              </Form.Item>
              {
                initInfo.channel && <Form.Item label='渠道编码'>
                  {getFieldDecorator('channel', {
                initialValue: initInfo.channel,
                    rules: [{ required: true}]
              })(
              <span>{initInfo.channel}</span>
              )}

                </Form.Item>
              }
              {
                !initInfo.channel && <Form.Item label='渠道编码' extra='注：渠道编码不允许重复，一旦确定不能修改！'>
                  {getFieldDecorator('channel', {
                    rules: [
                      {
                        required: true,
                        pattern: new RegExp(/^[a-zA-Z0-9]{1,20}$/, "g"),
                        message: '仅允许输入英文或数字，限20个字符'
                      }
                      ]
                  })(
                    <div className='Id-tips-items'>
                      <Input placeholder='仅允许输入英文或数字，限20个字符' maxLength={20}/>
                    </div>
                  )}
                </Form.Item>
              }
              <Form.Item label='渠道描述'>
                {getFieldDecorator('remark', {
                  initialValue: initInfo.remark,
                  rules: [{
                    required: false,
                    message: '请输入渠道描述',
                  }]
                })(
                  <TextArea rows={4} maxLength={100}/>
                )}
              </Form.Item>
            </Form>
            <div className='btn-con'>
              <Button type="primary" onClick={() => this.onSubmit()} loading={this.state.loading}>
                保存
              </Button>
              <Button type="normal" onClick={this.onCancel}>
                取消
              </Button>
            </div>
        </Card>
      </div>
    </div>
  }
};


// 将action注入到页面组件
const matDispatchToProps = {
};

// 将store注入到页面组件
function mapStateToProps(state) {
  return {
    accessoriesStatus: state.getIn(['operate', 'accessoriesStatus'], {}),
  }
}
const ChannelFormWrap = Form.create({ name: 'channel_form' })(ChannelInfo);
export default withRouter(connect(mapStateToProps, matDispatchToProps)(ChannelFormWrap));
