import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import './index.less';

const ImgPreviewComponent = ({data, onClose}) => {

  const IMG_DATA = {
    // 是否显示
    show: false,
    // 原图
    primaryImgUrl: null,
    // 生成的图片Url
    imgList: []
  }

  const [imgInfo, setImgInfo] = useState(IMG_DATA);
  const [imgIndex, setImgIndex] = useState(0);


  useEffect(() => {
    setImgInfo({
      ...IMG_DATA,
      ...data
    });
    setImgIndex(0);
  },[data])

  // 弹窗取消按钮点击
  const onCancelClick = () => {
    setImgInfo({
      ...imgInfo,
      show: false
    })

    if (imgInfo.cancelFunc) {
      imgInfo.cancelFunc();
    }
  }

  const onImgPreClick = (e) => {
    //阻止事件冒泡即可
    e.stopPropagation();
    const index = imgIndex <= 0 ? imgInfo.imgList.length-1 : imgIndex - 1;
    setImgIndex(index);
  }

  const onImgNextClick = (e) => {
    //阻止事件冒泡即可
    e.stopPropagation();
    const index = imgIndex >= imgInfo.imgList.length-1 ? 0 : imgIndex + 1;
    setImgIndex(index);
  }

  const onCloseBtnClick = () => {
    if (onClose) {
      onClose();
    }
  }

  // 下载图片
  const downFiles = (e, downloadUrl) => {
    //阻止事件冒泡即可
    e.stopPropagation();

    const x = new XMLHttpRequest();
    const resourceUrl = downloadUrl;
    x.open('GET', resourceUrl, true);
    x.responseType = 'blob';
    x.onload = () => {
      const urls = window.URL.createObjectURL(x.response);
      const a = document.createElement('a');
      a.href = urls;
      a.download = 'mix_model_img.jpg';
      a.click();
    };
    x.send();
  }

  return (<div className={`img-preview-component`} onClick={onCloseBtnClick}>
    <div className="img-preview-body-container">
      <img className="close-btn" src={ require('../../assets/images/close-btn.svg') } onClick={onCloseBtnClick}/>
      <div className="left-box">
        <div className="sub-title" onClick={(e) => e.stopPropagation()}>原图</div>
        {
          (imgInfo && imgInfo.primaryImgUrl) && <img className="primary-img" src={ imgInfo.primaryImgUrl } onClick={(e) => e.stopPropagation()}/>
        }
        <div className="bottom-box">
          <div className="down-btn" onClick={(e) => {downFiles(e, imgInfo.primaryImgUrl)}}>下载</div>
        </div>
      </div>

      <div className={`right-box ${(!imgInfo.imgList || imgInfo.imgList.length===0) && 'hide'}`}>
        <div className="sub-title" onClick={(e) => e.stopPropagation()}>生成图片 {imgIndex + 1}</div>
        {
          (imgInfo && imgInfo.imgList) && <img className="img" src={ imgInfo.imgList[imgIndex] } onClick={(e) => e.stopPropagation()}/>
        }
        <div className="bottom-box">
          <span className={`btn left-btn show}`} onClick={onImgPreClick}></span>
          <div className="down-btn" onClick={(e) => {downFiles(e, imgInfo.imgList[imgIndex])}}>下载</div>
          <span className={`btn right-btn show}`} onClick={onImgNextClick}></span>
        </div>
      </div>
    </div>
  </div>)
};

export default withRouter(ImgPreviewComponent);
