import request from '../utils/request';
export default {
  //用户列表
  getMemberList:(info)=> request('/admin/member/list', {
    method: 'POST',
    data: {
      ...info
    }
  }),
  //用户发放
  grant:(info)=> request('/admin/member/grant-coin', {
    method: 'POST',
    data: {
      ...info
    }
  }),
  //用户详情
  getMemberDetail:(id)=> request('/admin/member/info', {
    method: 'POST',
    data: {
      id
    }
  }),
  //用户编辑详情
  getMemberEditInfo:(id)=> request('/admin/member/edit-info', {
    method: 'POST',
    data: {
      id
    }
  }),
  //用户启用或禁用(禁止购物)
  operaMember:(info)=> request('/admin/member/change-status', {
    method: 'POST',
    data: {
      ...info
    }
  }),
  //用户充值记录
  getMyOrder:(info)=> request('/admin/member/orders', {
    method: 'POST',
    data: {
      ...info
    }
  }),
  //用户套餐记录
  getMyPackage:(info)=> request('/admin/member/packages', {
    method: 'POST',
    data: {
      ...info
    }
  }),
  //用户备注
  onMemberMark: (info) => request('/admin/member/mark', {
    method: 'POST',
    data: {
      ...info
    }
  }),
  //bd管理用户的选项列表
  getBDUserOption: (info) => request('/admin/user/bd-user-option', {
    method: 'GET',
    params: {
      ...info
    }
  }),
  //编辑或更新客户
  memberUpdate: (info) => request('/admin/member/update', {
    method: 'POST',
    data: {
      ...info
    }
  }),
  //充值记录
  getRecordList: (info) => request('/admin/order/list', {
    method: 'POST',
    data: {
      ...info
    }
  }),
  //图片生成记录
  getTaskList: (info) => request('/admin/member/tasks', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  //已关联BD的客户列表
  getBDMembers: (info) => request('/admin/member/bd-members', {
    method: 'GET',
    params: {
      ...info
    }
  }),

  //bd订单添加或编辑
  orderUpdate: (info) => request('/admin/bdorder/order-update', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  //BD订单编辑时信息
  getOrderInfo: (info) => request('/admin/bdorder/order-info', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  //BD订单更新备注
  updateOrderMark: (info) => request('/admin/bdorder/order-mark', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // BD订单详情
  getOrderDetail: (info) => request('/admin/bdorder/order-detail', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 订单款式添加或编辑
  updateOrderStyleList: (info) => request('/admin/bdorder/style-update', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 添加订单款式
  addOrderStyle: (info) => request('/admin/bdorder/style-add', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 删除订单款式
  delOrderStyle: (info) => request('/admin/bdorder/style-del', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 编辑订单款式
  editOrderStyle: (info) => request('/admin/bdorder/style-edit', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 订单款式添加或编辑
  delOrder: (info) => request('/admin/bdorder/order-del', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // bd订单列表
  getOrderList: (info) => request('/admin/bdorder/order-search', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // BD订单款式详情
  getStyleDetail: (info) => request('/admin/bdorder/style-detail', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 删除款式原图
  delStyleImg: (info) => request('/admin/bdorder/style-img-del', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 图片生成选项
  getBuildOptions: (info) => request('/admin/bdorder/build-options', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 图片生成选项
  buildTask: (info) => request('/admin/bdorder/build-task', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 获取勾选原图列表
  getStyleImgList: (info) => request('/admin/bdorder/style-img-list', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 获取勾选原图列表
  imgHDDownloadCreate: (info) => request('/admin/bdorder/img-hd-download-create', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 获取下载最佳高清图片任务进度状态
  imgHDDownloadInfo: (info) => request('/admin/bdorder/img-hd-download-info', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 获取下载最佳高清图片任务进度状态
  imgHDDownloadCancel: (info) => request('/admin/bdorder/img-hd-download-cancel', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 批量下载最佳
  downloadBestImages: (info) => request('/admin/bdorder/download-batch-best-img', {
    method: 'GET',
    params: {
      ...info
    }
  }),

  // 批量下载最佳
  setBest: (info) => request('/admin/bdorder/style-img-set-best', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 删除款式
  deleteStyleImg: (info) => request('/admin/bdorder/style-img-del', {
    method: 'POST',
    data: {
      ...info
    }
  }),



}