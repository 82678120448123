import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import './index.less';
import {Button, Modal} from "antd";
import API from "../../apis";

/*
 * 下载高清最佳
 */
const DownloadHDProgressComponent = ({orderId, styleId, galleryId, onClose}) => {

  const STATUS_MAP = {
    '0': '获取中...',
    '1': '成功',
    '-1': '失败'
  };
  const [downloadTaskId, setDownloadTaskId] = useState(null);
  const [downloadTaskInfo, setDownloadTaskInfo] = useState(null);
  const [finishCount, setFinishCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (!orderId) {
      return;
    }

    imgHDDownloadCreate();
  },[orderId])

  useEffect(() => {
    if (!downloadTaskId) {
      return;
    }

    imgHDDownloadInfo();
  },[downloadTaskId])

  // 创建批量高清图片下载任务
  const imgHDDownloadCreate = async () => {
    const params = {
      order_id: orderId,
      style_id: styleId || null,
      gallery_id: galleryId || null
    };
    const res = await API.imgHDDownloadCreate(params);
    if (res.code === 0) {
      setDownloadTaskId(res.data.download_task_id);
    }
  }

  // 获取下载最佳高清图片任务进度状态
  const imgHDDownloadInfo = async () => {
    const params = {
      download_task_id: downloadTaskId,
    };
    const res = await API.imgHDDownloadInfo(params);
    clearTimer();
    if (res.code === 0) {
      setDownloadTaskInfo(res.data);
      getFinishCount(res.data.data);

      // 完成
      if (res.data.status === 1) {
        setTimeout(() => {
          downloadHDImgList();
        }, 1000)
      } else if (res.data.status === 0) {
        const time = setTimeout(() => {
          imgHDDownloadInfo();
        }, 2000);
        setTimer(time);
      }
    }
  }

  const clearTimer = () => {
    if (timer) {
      clearTimer(timer);
      setTimer(null);
    }
  }

  // 取消高清图片下载任务
  const imgHDDownloadCancel = async () => {
    const params = {
      download_task_id: downloadTaskId
    };
    const res = await API.imgHDDownloadCancel(params);
    if (res.code === 0) {
      clearTimer();
      if (onClose) {
        onClose();
      }
    }
  }

  // 下载
  const downloadHDImgList = () => {
    const url = `/admin/bdorder/img-hd-download-zip?download_task_id=${downloadTaskId}`;
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();

    if (onClose) {
      onClose();
    }
  }

  // 获取完成数量
  const getFinishCount = (list) => {
    let finish = 0;
    let error = 0;
    list.map(item => {
      if (item.status === 1) {
        finish += 1;
      } else if (item.status === -1) {
        error += 1;
      }
    });

    setFinishCount(finish);
    setErrorCount(error);
  }

  const onCancelClick = () => {
    Modal.confirm({
      title: '提示',
      content: (
        <div>
          <p>未获取的高清图片将直接取消，是否取消获取？</p>
        </div>
      ),
      okText: '继续上传',
      cancelText: '取消上传',
      onOk: async () => {
        imgHDDownloadCancel();
      }
    });
  }

  const onJumpClick = () => {
    downloadHDImgList();
  }

  const onAgainClick = () => {
    imgHDDownloadCreate();
  }

  return (<div className={`progress-component`}>
    <div className="progress-body-container">
      {
        downloadTaskInfo?.status === 1 && <div className="sub-title">成功获取 <span className="sub-title-value">{downloadTaskInfo?.total}</span> 张高清图片</div>
      }
      {
        downloadTaskInfo?.status === 0 && <div className="sub-title">正在获取高清图片 <span className="sub-title-value">{finishCount}/{downloadTaskInfo?.total}</span></div>
      }
      {
        downloadTaskInfo?.status === -1 && <div className="sub-title">成功获取 <span className="sub-title-value">{finishCount}</span>张高清图片，失败 <span className="error-count">{errorCount}</span> 张</div>
      }
      <div className="progress-bar-box">
        <div className="progress-bar">
          {
            downloadTaskInfo && <div className="progress" style={{width: `${((finishCount + errorCount)/downloadTaskInfo?.total) * 100}%`}}></div>
          }
        </div>
        <span className="pre-value">{Math.floor(((finishCount + errorCount) / downloadTaskInfo?.total) * 100)}%</span>
      </div>

      <div className="img-list-box">
        {
          (downloadTaskInfo?.data || []).map((file) => <div className="item-box">
            <span className="left-box">
              <span className="item-img-box">
                <img className="item-img" src={ file.image }/>
              </span>

              <span className="info-box">
                <div className="file-name">ID: {file.style_img_id }</div>
              </span>
            </span>
            <span className="right-box">
              <span className={`status ${file.status===-1 && "error"}`}>{STATUS_MAP[file.status + '']}</span>
            </span>
          </div>)
        }
      </div>

      <div className="bottom-box">
        {
          downloadTaskInfo?.status === 0 && <Button className="ant-btn user-type user-type6" onClick={() => onCancelClick()}>取消</Button>
        }
        {
          downloadTaskInfo?.status === 1 && <span>获取成功，正在跳转下载</span>
        }
        {
          downloadTaskInfo?.status === -1 && <span>
            <Button className="ant-btn user-type user-type6" onClick={() => onJumpClick()}>跳过</Button>
            <Button className="ant-btn user-type user-type4" onClick={() => onAgainClick()}>再次尝试</Button>
          </span>
        }
      </div>

    </div>
  </div>)
};

export default withRouter(DownloadHDProgressComponent);
