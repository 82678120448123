import { createAction } from 'redux-actions'
import API from '../../apis'
// 请求管理员列表
export const FETCH_ADMIN_LIST = 'FETCH_ADMIN_LIST';
// 获取管理员详情
export const GET_ADMIN_ID = 'GET_ADMIN_ID';
// 删除管理员
export const DELETE_ADMIN_INFO = 'DELETE_ADMIN_INFO';
// 删除管理员
export const DELETE_ROLE_INFO = 'DELETE_ROLE_INFO';
// 禁用管理员
export const BAN_ADMIN = 'BAN_ADMIN';
// 启用管理员
export const CANCEL_BAN_ADMIN = 'CANCEL_BAN_ADMIN';
// 请求角色列表
export const FETCH_ROLE_LIST = 'FETCH_ROLE_LIST';
//请求个人信息
export const FETCH_PERSONAL_INFO = 'FETCH_PERSONAL_INFO';
//更新个人信息
export const UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO';
//获取角色详情
export const FETCH_ROLE_INFO = 'FETCH_ROLE_INFO';
//获取所有菜单
export const FETCH_ALL_MUNES = 'FETCH_ALL_MENES';
//获取当前菜单
export const FETCH_USER_MUNES = 'FETCH_USER_MUNES';
//获取当前角色ID
export const FETCH_AUTH_LIST = 'FETCH_AUTH_LIST';
// 获取小红点数字
export const GET_MENU_ITEM_BADGE = 'GET_MENU_ITEM_BADGE';



// 导出管理员列表
export const fetchAdminList = createAction(FETCH_ADMIN_LIST, async (params) => {
    const data = await API.getAdminList(params);
    if (data.code === 0) {
        return data.data
    }
});
// 查询管理员详情
export const getAdminID = createAction(GET_ADMIN_ID, async (id) => {
    const data =  await API.getAdminById(id);
    if (data.code === 0) {
        return data.data
    }
});
// 添加管理员
export const addAdmin = data => API.addAdmin(data);
// 编辑管理员
export const editAdmin = data => API.editAdmin(data);
// 删除管理员
export const deleteAdmin = createAction(DELETE_ADMIN_INFO, async (id) => {
    return API.deleteAdmin(id);
});
// 禁用管理员
export const banAdmin = createAction(BAN_ADMIN, (id) => {
    return API.banAdmin(id);
});
// 启用管理员
export const cancelBanAdmin = createAction(CANCEL_BAN_ADMIN, async (id) => {
    return API.cancelBanAdmin(id);
});
// 导出角色列表
export const fetchRoleList = createAction(FETCH_ROLE_LIST, async () => {
    const data = await API.getRoleList();
    if (data.code === 0) {
        return data.data
    }
});
// 查询角色详情
export const getRoleInfo = createAction(FETCH_ROLE_INFO, async (id) => {
    const data =  await API.getRoleInfo(id);
    if (data.code === 0) {
        return data.data
    }
});
// 添加角色
export const addRole = data => API.addRole(data);
// 编辑角色
export const editRole = id => API.editRole(id);
// 删除角色
export const deleteRole = createAction(DELETE_ROLE_INFO, async (id) => {
    return API.deleteRole(id);
});
//请求个人中心信息
export const getPersonal = createAction(FETCH_PERSONAL_INFO, async () => {
    const data = await API.getPersonal();
    if (data.code === 0) {
        return data.data
    }
});
// 更新个人中心信息
export const updatePersonal = data => API.updatePersonal(data);
// 更新密码
export const resetPassword = data => API.resetPassword(data);
//获取所有菜单
export const getAllMenus = createAction(FETCH_ALL_MUNES, async () => {
    const data = await API.getAllMenus();
    if (data.code === 0) {
        return data.data
    }
});
//获取当前菜单
export const getUserMenusList = () => async (dispatch) => {
    const data = await API.getUserMenus()
    if (data.code === 0) {
        dispatch(getMenuItemBadge(data.data))
        dispatch({
            type: FETCH_USER_MUNES,
            payload: data.data
        })
    }
};
//保存授权信息
export const saveAuth = data => API.saveAuth(data);
//根据角色ID获取权限列表
export const getAuth = id => API.getAuth(id);
// 获取小红点数字
export const getMenuItemBadge = createAction(
    GET_MENU_ITEM_BADGE,
    async menus => {
        if (!menus || !Array.isArray(menus) || menus.length === 0) {
            return
        }
        // 暂时只考虑只有二级菜单，如果以后出现三级菜单，需要提出去递归一下
        let ids = menus.reduce((pre, cur) => {
            pre.push(...[cur.id, ...cur.child_menu.map(m => m.id)])
            return pre
        }, [])
        const data = await API.getMenuItemBadge({
            id: ids
        })
        if (data.code === 0 && menus && Array.isArray(menus)) {
            let res = {}
            data.data.forEach(item => {
                res[item.id] = item.badge_num
            })
            return res
        }
    }
)
