import React, {Component} from 'react';
import {Input, Form, Icon, message, Button, Table, Popover} from 'antd';
import API from '../../../../apis'
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";


class ChannelView extends Component {
  state = {
    columns: [
      {
        title: '时间',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: '访客数量',
        dataIndex: 'access_count',
        key: 'access_count',
      },
      {
        title: '首页浏览量',
        dataIndex: 'home_page_pv',
        key: 'home_page_pv',
      },
      {
        title: <div>上传<br/>图片人数</div>,
        dataIndex: 'upload_member_count',
        key: 'upload_member_count',
      },
      {
        title: <div>注册浮层<br/>展示次数</div>,
        key: 'reg_page_pv',
        dataIndex: 'reg_page_pv',
      },
      {
        title: <div>注册<br/>成功量</div>,
        dataIndex: 'reg_count',
        key: 'reg_count',
      },
      {
        title: '注册转化率',
        dataIndex: 'reg_ratio',
        key: 'reg_ratio',
        render: text => <span>{text*100}%</span>,
      },
      {
        title: <div>生成图片<br/>人数</div>,
        dataIndex: 'build_member_count',
        key: 'build_member_count',
      },
      {
        title: <div>定价页面<br/>展示次数</div>,
        key: 'pay_page_pv',
        dataIndex: 'pay_page_pv',
      },
      {
        title: '付费用户量',
        dataIndex: 'pay_member_count',
        key: 'pay_member_count',
      },
      {
        title: '付费次数',
        dataIndex: 'pay_count',
        key: 'pay_count',
      },
      {
        title: '付费金额',
        dataIndex: 'pay_money_total',
        key: 'pay_money_total',
        render: text => <span>￥{text}</span>,
      },
      {
        title: '付费转化率',
        dataIndex: 'pay_ratio',
        key: 'pay_ratio',
        render: text => <span>{text*100}%</span>,
      }
    ],
    initInfo:{},
    pagination: {
      total: 0,
      current: 1,
      pageSize: 20,
    },
  }
  componentDidMount() {
    this.init();
  }
  adjustArrowPosition() {
    let helpIcon = document.getElementById("helpIcon");
    let arrowIcon = document.querySelector(".ant-tooltip-arrow");
    arrowIcon.style.top = helpIcon.getBoundingClientRect().top + 'px';
  }
  init=async(page)=> {
    if (this.props.match.params.id) {
      const data = await API.lookChannel(this.props.match.params.id,page);
      if (data.code === 0) {
        this.setState({
          initInfo:data.data,
          pagination: {
            total: data.data.total,
            current: page
          },
        })
      }
    }
  }
  // 当选中某一页时回调
  pageChange = (page) => {
    this.init(page.current);
  };

  onCancel = () => {
    this.props.history.goBack();
  };
  render() {
    const {initInfo} = this.state;
    return <>
      <Button type="link" onClick={this.onCancel}><Icon type="left" style={{marginLeft:'-15px'}}/>返回</Button>
      <div className='content'>
        <div className='channel-items'><label>渠道名称</label><span>{initInfo.info?.name}</span></div>
        <div className='channel-items'>
          <label>渠道链接</label>
          <a target='_blank' href={initInfo.info?.url}>{initInfo.info?.url}</a>
          <Popover
            className='hover-items'
            content={
              <div className="hover-tip">
                <ul>
                  <li style={{fontWeight: 'bold', listStyle: 'none'}}>访客数量</li>
                  <li style={{listStyle: 'circle'}}>访问该渠道的独立 IP数量、浏览器标识区分（UV）</li>
                  <br/>
                  <li style={{fontWeight: 'bold', listStyle: 'none'}}>浏览量</li>
                  <li style={{listStyle: 'circle'}}>各网页被浏览的总次数（PV）</li>
                  <br/>
                  <li style={{fontWeight: 'bold', listStyle: 'none'}}>上传图片数</li>
                  <li style={{listStyle: 'circle'}}>上传图片的的人数（UV）</li>
                  <br/>
                  <li style={{fontWeight: 'bold', listStyle: 'none'}}>注册浮层展示次数</li>
                  <li style={{listStyle: 'circle'}}>弹出注册浮层展示的次数</li>
                  <br/>
                  <li style={{fontWeight: 'bold', listStyle: 'none'}}>注册成功量</li>
                  <li style={{listStyle: 'circle'}}>成功注册的账号数量</li>
                  <br/>
                  <li style={{fontWeight: 'bold', listStyle: 'none'}}>注册转化率</li>
                  <li style={{listStyle: 'circle'}}>注册成功量/访客数量</li>
                  <br/>
                  <li style={{fontWeight: 'bold', listStyle: 'none'}}>生成图片人数</li>
                  <li style={{listStyle: 'circle'}}>生成图片的人数（UV）</li>
                  <br/>
                  <li style={{fontWeight: 'bold', listStyle: 'none'}}>定价页面展示次数</li>
                  <li style={{listStyle: 'circle'}}>访问定价页面的次数</li>
                  <br/>
                  <li style={{fontWeight: 'bold', listStyle: 'none'}}>付费用户量</li>
                  <li style={{listStyle: 'circle'}}>成功充值的账号数量</li>
                  <br/>
                  <li style={{fontWeight: 'bold', listStyle: 'none'}}>付费次数</li>
                  <li style={{listStyle: 'circle'}}>成功充值的次数</li>
                  <br/>
                  <li style={{fontWeight: 'bold', listStyle: 'none'}}>付费金额</li>
                  <li style={{listStyle: 'circle'}}>成功充值的总金额</li>
                  <br/>
                  <li style={{fontWeight: 'bold', listStyle: 'none'}}>付费转化率</li>
                  <li style={{listStyle: 'circle'}}>付费用户量/访客数量</li>
                </ul>
              </div>
            }
            trigger='hover'
            placement ="leftTop"
            getPopupContainer={target => target.parentElement}
          >
            <img src={require('./u1246.png')} id='helpIcon' style={{float: 'right', cursor: 'pointer',width:'20px',top:'0'}}></img>
          </Popover>
        </div>
        <Table
          columns={this.state.columns}
          rowKey="id"
          dataSource={initInfo.list}
          pagination={this.state.pagination}
          onChange={this.pageChange}
          loading={this.state.loading}
          locale={{emptyText: '暂无任何数据'}}
        />
      </div>
    </>
  }
};


// 将action注入到页面组件
const matDispatchToProps = {
};

// 将store注入到页面组件
function mapStateToProps(state) {
  return {
    accessoriesStatus: state.getIn(['operate', 'accessoriesStatus'], {}),
  }
}
export default withRouter(connect(mapStateToProps, matDispatchToProps)(ChannelView));